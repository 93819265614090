import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Paper,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  DialogActions,
  Pagination,
  Snackbar,
  Alert,
  IconButton,
  Tooltip,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { alpha } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Chip from "@mui/material/Chip";
import AssessmentIcon from "@mui/icons-material/Assessment";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as RechartsTooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const Loading = () => (
  <Box sx={{ display: "flex", justifyContent: "center", p: 2 }}>
    <CircularProgress />
  </Box>
);

const Indicators = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedIndicator, setSelectedIndicator] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [partnerName, setPartnerName] = useState("");
  const [outputs, setOutputs] = useState([]);
  const [selectedOutputID, setSelectedOutputID] = useState(null);
  const [formData, setFormData] = useState({
    description: "",
    baseline: "",
    targetAnnual: "",
    targetQ1: "",
    targetQ2: "",
    targetQ3: "",
    targetQ4: "",
  });
  const [error, setError] = useState("");
  const [offset, setOffset] = useState(0);
  const [editDialog, setEditDialog] = useState(false);
  const [editingIndicator, setEditingIndicator] = useState(null);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [deleteIndicatorId, setDeleteIndicatorId] = useState(null);
  const [updateConfirmOpen, setUpdateConfirmOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [performanceForm, setPerformanceForm] = useState({
    quarter: "",
    value: "",
    year: new Date().getFullYear(),
  });
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

  useEffect(() => {
    try {
      const storedUser = localStorage.getItem("user");
      console.log("user", storedUser);

      if (storedUser) {
        try {
          const userData = JSON.parse(storedUser);
          const partner = userData?.Partner || userData?.partner;

          if (partner) {
            setPartnerName(partner);
          } else {
            console.error("No partner found in user data:", userData);
          }
        } catch (error) {
          console.error("Error parsing user data:", error);
        }
      } else {
        console.log("No user data found in localStorage");
      }
    } catch (error) {
      console.error("Error handling user data:", error);
    }
  }, []);

  useEffect(() => {
    if (partnerName) {
      fetchIndicators();
      fetchOutputs();
    }
  }, [partnerName, offset]);

  const fetchIndicators = async () => {
    if (!partnerName) {
      console.log("Skipping fetchIndicators - no partner name available");
      return;
    }

    console.log("Fetching indicators for partner:", partnerName);
    setLoading(true);
    try {
      const url = `/api/indicators/all/${encodeURIComponent(
        partnerName
      )}/${offset}`;
      console.log("Fetching from URL:", url);

      const response = await fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        credentials: "include",
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      console.log("Indicators result:", result);

      if (result.data) {
        const enrichedIndicators = await Promise.all(
          result.data.map(async (indicator) => {
            try {
              const outputResponse = await fetch(
                `/api/outputs/joined/${indicator.Output_ID}`,
                {
                  method: "GET",
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                  },
                  credentials: "include",
                }
              );

              if (!outputResponse.ok) {
                throw new Error(`HTTP error! status: ${outputResponse.status}`);
              }

              const outputData = await outputResponse.json();
              return {
                ...indicator,
                outputInfo: outputData.data[0] || {},
              };
            } catch (error) {
              console.error(
                `Error fetching output info for indicator ${indicator.ID}:`,
                error
              );
              return {
                ...indicator,
                outputInfo: {},
              };
            }
          })
        );

        console.log("Setting enriched indicators:", enrichedIndicators);
        setData({
          data: enrichedIndicators,
          total: result.total,
        });
      } else {
        console.log("No data in result or data is not an array");
        setData({ data: [], total: 0 });
      }
    } catch (error) {
      console.error("Error in fetchIndicators:", error);
      setData({ data: [], total: 0 });
    } finally {
      setLoading(false);
    }
  };

  const fetchOutputs = async () => {
    if (!partnerName) {
      console.log("Skipping fetchOutputs - no partner name available");
      return;
    }

    try {
      const url = `/api/outputs/all/${encodeURIComponent(
        partnerName
      )}/${offset}`;
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        credentials: "include",
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      setOutputs(result.data || []);
    } catch (error) {
      console.error("Error fetching outputs:", error);
      setOutputs([]);
    }
  };

  const getFiscalYearDates = () => {
    const today = new Date();
    const year = today.getFullYear();

    // Fiscal year starts in April
    const fiscalStart = new Date(year, 3, 1); // April 1st
    const fiscalEnd = new Date(year + 1, 2, 31); // March 31st next year

    return {
      start: fiscalStart.toISOString().split("T")[0],
      end: fiscalEnd.toISOString().split("T")[0],
    };
  };

  const fetchIndicatorPerformance = async (indicatorId, year) => {
    try {
      const response = await fetch(
        `/api/performance/single/${indicatorId}/${year}`
      );

      if (!response.ok) {
        throw new Error("Failed to fetch performance data");
      }

      const result = await response.json();
      return result.data;
    } catch (error) {
      console.error("Error fetching performance:", error);
      return [];
    }
  };

  const processPerformanceData = (indicator) => {
    if (!indicator || !indicator.performance) return [];

    const quarters = ["Q1", "Q2", "Q3", "Q4"];
    const quarterlyData = quarters.map((quarter) => ({
      name: quarter,
      Target: Number(indicator[`Target_${quarter}`]) || 0,
      Performance:
        Number(
          indicator.performance.find((p) => p.Quarter === quarter)?.Performance
        ) || 0,
    }));

    // Calculate annual performance total
    const annualPerformance = quarterlyData.reduce(
      (sum, q) => sum + q.Performance,
      0
    );

    return {
      quarterlyData,
      annualTarget: Number(indicator.Target_Annual) || 0,
      annualPerformance,
    };
  };

  const handlePerformanceUpdate = async (indicatorId, quarter, value, year) => {
    try {
      const response = await fetch("/api/performance/create", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          Indicator_ID: indicatorId,
          Quarter: quarter,
          Performance: value,
          Year: year,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to update performance");
      }

      // Refresh the performance data
      const updatedPerformance = await fetchIndicatorPerformance(
        indicatorId,
        year
      );
      setSelectedIndicator((prev) => ({
        ...prev,
        performance: updatedPerformance,
      }));

      setSuccessMessage("Performance updated successfully");
      setPerformanceForm({
        quarter: "",
        value: "",
        year: selectedYear,
      });
    } catch (error) {
      setError("Failed to update performance: " + error.message);
    }
  };

  const handleSubmit = async () => {
    if (!formData.description || !selectedOutputID) {
      setError("Please fill in all required fields");
      return;
    }

    setLoading(true);
    try {
      const response = await fetch("/api/indicators/create", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          Output_ID: selectedOutputID,
          Description: formData.description,
          Baseline: formData.baseline,
          Target_Annual: formData.targetAnnual,
          Target_Q1: formData.targetQ1,
          Target_Q2: formData.targetQ2,
          Target_Q3: formData.targetQ3,
          Target_Q4: formData.targetQ4,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      if (data.success) {
        setOpenDialog(false);
        await fetchIndicators();
        setFormData({
          description: "",
          baseline: "",
          targetAnnual: "",
          targetQ1: "",
          targetQ2: "",
          targetQ3: "",
          targetQ4: "",
        });
        setError("");
        setSuccessMessage("Indicator created successfully");
      } else {
        setError(data.error || "Failed to create indicator");
      }
    } catch (error) {
      console.error("Error creating indicator:", error);
      setError("Failed to create indicator");
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (field) => (event) => {
    setFormData((prev) => ({
      ...prev,
      [field]: event.target.value,
    }));
  };

  const handleIndicatorSelect = async (indicator) => {
    try {
      const performanceData = await fetchIndicatorPerformance(
        indicator.ID,
        selectedYear
      );
      setSelectedIndicator({
        ...indicator,
        performance: performanceData,
      });
    } catch (error) {
      console.error("Error selecting indicator:", error);
      setSelectedIndicator(indicator);
    }
  };

  const handleDelete = async (indicatorId) => {
    setDeleteIndicatorId(indicatorId);
    setDeleteConfirmOpen(true);
  };

  const confirmDelete = async () => {
    setLoading(true);
    try {
      const response = await fetch(`/api/indicators/${deleteIndicatorId}`, {
        method: "DELETE",
        credentials: "include",
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      await fetchIndicators();
      setDeleteConfirmOpen(false);
      setSuccessMessage("Indicator deleted successfully");
    } catch (error) {
      console.error("Error deleting indicator:", error);
      setError("Failed to delete indicator");
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = () => {
    if (!formData.description || !selectedOutputID) {
      setError("Please fill in all required fields");
      return;
    }
    setUpdateConfirmOpen(true);
  };

  const confirmUpdate = async () => {
    setLoading(true);
    try {
      const updateData = {
        Output_ID: selectedOutputID,
        Description: formData.description,
        Baseline: formData.baseline || "0",
        Target_Annual: formData.targetAnnual || "0",
        Target_Q1: formData.targetQ1 || "0",
        Target_Q2: formData.targetQ2 || "0",
        Target_Q3: formData.targetQ3 || "0",
        Target_Q4: formData.targetQ4 || "0",
      };

      console.log("Updating indicator with data:", updateData);

      const response = await fetch(`/api/indicators/${editingIndicator.ID}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        credentials: "include",
        body: JSON.stringify(updateData),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(
          errorData.message || `HTTP error! status: ${response.status}`
        );
      }

      const data = await response.json();
      console.log("Update response:", data);

      if (data.success) {
        setEditDialog(false);
        setEditingIndicator(null);
        setUpdateConfirmOpen(false);
        await fetchIndicators();
        setFormData({
          description: "",
          baseline: "",
          targetAnnual: "",
          targetQ1: "",
          targetQ2: "",
          targetQ3: "",
          targetQ4: "",
        });
        setError("");
        setSuccessMessage("Indicator updated successfully");
      } else {
        throw new Error(data.error || "Failed to update indicator");
      }
    } catch (error) {
      console.error("Error updating indicator:", error);
      setError(error.message || "Failed to update indicator");
    } finally {
      setLoading(false);
    }
  };

  const handleCloseSnackbar = () => {
    setSuccessMessage("");
  };

  // Define a custom color for the hierarchy titles
  const hierarchyTitleStyle = {
    fontWeight: 600,
    fontSize: "1.1rem",
    color: "#424242",
    mb: 1,
    display: "flex",
    alignItems: "center",
    gap: 2,
    "&::after": {
      content: '""',
      flex: 1,
      height: "1px",
      backgroundColor: "#e0e0e0",
    },
  };

  useEffect(() => {
    if (selectedIndicator) {
      fetchIndicatorPerformance(selectedIndicator.ID, selectedYear).then(
        (performanceData) => {
          setSelectedIndicator((prev) => ({
            ...prev,
            performance: performanceData,
          }));
        }
      );
    }
  }, [selectedIndicator?.ID, selectedYear]);

  return (
    <Box sx={{ p: 3, width: "100%", height: "100%", boxSizing: "border-box" }}>
      {/* Header Section */}
      <Card
        sx={{
          mb: 3,
          backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.03),
        }}
      >
        <CardContent
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            <Typography
              variant="h5"
              sx={{ fontWeight: 600, color: "primary.main", mb: 0.5 }}
            >
              {partnerName === "Brooke East Africa"
                ? "All Indicators"
                : `${partnerName} Indicators`}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Manage and track your performance indicators
            </Typography>
          </Box>
          <Button
            variant="contained"
            onClick={() => setOpenDialog(true)}
            startIcon={<AssessmentIcon />}
            sx={{
              borderRadius: 2,
              textTransform: "none",
              boxShadow: 2,
            }}
          >
            New Indicator
          </Button>
        </CardContent>
      </Card>

      {/* Indicators Grid */}
      <Grid container spacing={2}>
        {data?.data?.map((indicator) => (
          <Grid item xs={12} md={6} key={indicator.ID}>
            <Card
              sx={{
                height: "100%",
                transition: "transform 0.2s, box-shadow 0.2s",
                "&:hover": {
                  transform: "translateY(-2px)",
                  boxShadow: 3,
                  cursor: "pointer",
                },
              }}
              onClick={() => handleIndicatorSelect(indicator)}
            >
              <CardContent>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    mb: 2,
                  }}
                >
                  <Box sx={{ flex: 1 }}>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        fontWeight: 600,
                        color: "primary.main",
                        mb: 1,
                        display: "-webkit-box",
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                      }}
                    >
                      {indicator.Description}
                    </Typography>
                    <Chip
                      label={`Target: ${indicator.Target_Annual || "0"}`}
                      size="small"
                      sx={{ mr: 1, mb: 1 }}
                    />
                    <Chip
                      label={`Baseline: ${indicator.Baseline || "0"}`}
                      size="small"
                      variant="outlined"
                      sx={{ mb: 1 }}
                    />
                  </Box>
                  {partnerName === "Brooke East Africa" && (
                    <Box sx={{ display: "flex", gap: 0.5 }}>
                      <Tooltip title="Edit">
                        <IconButton
                          size="small"
                          onClick={(e) => {
                            e.stopPropagation();
                            setEditingIndicator(indicator);
                            setFormData({
                              description: indicator.Description,
                              baseline: indicator.Baseline,
                              targetAnnual: indicator.Target_Annual,
                              targetQ1: indicator.Target_Q1,
                              targetQ2: indicator.Target_Q2,
                              targetQ3: indicator.Target_Q3,
                              targetQ4: indicator.Target_Q4,
                            });
                            setSelectedOutputID(indicator.Output_ID);
                            setEditDialog(true);
                          }}
                          sx={{
                            "&:hover": {
                              color: "primary.main",
                            },
                          }}
                        >
                          <EditIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Delete">
                        <IconButton
                          size="small"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDelete(indicator.ID);
                          }}
                          sx={{
                            "&:hover": {
                              color: "error.main",
                            },
                          }}
                        >
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  )}
                </Box>
                <Box
                  sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}
                >
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ display: "flex", alignItems: "center", gap: 1 }}
                  >
                    <span style={{ fontWeight: 600 }}>Output:</span>
                    {indicator.Output}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ display: "flex", alignItems: "center", gap: 1 }}
                  >
                    <span style={{ fontWeight: 600 }}>Goal:</span>
                    {indicator.outputInfo?.Goal}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* Pagination */}
      {data?.total > 0 && (
        <Box sx={{ mt: 4, display: "flex", justifyContent: "center" }}>
          <Pagination
            count={Math.ceil(data.total / 10)}
            page={offset + 1}
            onChange={(event, newPage) => {
              setOffset(newPage - 1);
              window.scrollTo(0, 0);
            }}
            color="primary"
            showFirstButton
            showLastButton
            sx={{
              "& .MuiPaginationItem-root": {
                borderRadius: 1,
              },
            }}
          />
        </Box>
      )}

      {/* Create Indicator Dialog */}
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        maxWidth="md"
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: 2,
            boxShadow: (theme) => theme.shadows[24],
          },
        }}
      >
        <DialogTitle
          sx={{
            borderBottom: "1px solid",
            borderColor: "divider",
            px: 3,
            py: 2,
          }}
        >
          <Typography variant="h6" component="div" sx={{ fontWeight: 600 }}>
            Create New Indicator
          </Typography>
          <Typography variant="body2" color="text.secondary" sx={{ mt: 0.5 }}>
            Fill in the details below to create a new indicator
          </Typography>
        </DialogTitle>

        <DialogContent sx={{ px: 3, py: 3 }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel>Output</InputLabel>
                <Select
                  value={selectedOutputID || ""}
                  onChange={(e) => setSelectedOutputID(e.target.value)}
                  label="Output"
                  sx={{ "& .MuiSelect-select": { py: 1.5 } }}
                >
                  {outputs.map((output) => (
                    <MenuItem key={output.ID} value={output.ID}>
                      {output.Description}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Indicator Description"
                multiline
                rows={3}
                value={formData.description}
                onChange={handleChange("description")}
                sx={{ "& .MuiOutlinedInput-root": { py: 1 } }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Baseline Value"
                value={formData.baseline}
                onChange={handleChange("baseline")}
                sx={{ "& .MuiOutlinedInput-root": { py: 1 } }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Annual Target"
                value={formData.targetAnnual}
                onChange={handleChange("targetAnnual")}
                sx={{ "& .MuiOutlinedInput-root": { py: 1 } }}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography
                variant="subtitle2"
                color="text.secondary"
                sx={{ mb: 2 }}
              >
                Quarterly Targets
              </Typography>
              <Grid container spacing={2}>
                {[
                  { label: "Q1 Target", field: "targetQ1" },
                  { label: "Q2 Target", field: "targetQ2" },
                  { label: "Q3 Target", field: "targetQ3" },
                  { label: "Q4 Target", field: "targetQ4" },
                ].map((quarter) => (
                  <Grid item xs={12} sm={6} md={3} key={quarter.field}>
                    <TextField
                      fullWidth
                      label={quarter.label}
                      value={formData[quarter.field]}
                      onChange={handleChange(quarter.field)}
                      sx={{ "& .MuiOutlinedInput-root": { py: 1 } }}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>

            {error && (
              <Grid item xs={12}>
                <Alert severity="error" sx={{ mt: 1 }}>
                  {error}
                </Alert>
              </Grid>
            )}
          </Grid>
        </DialogContent>

        <DialogActions
          sx={{
            px: 3,
            py: 2,
            borderTop: "1px solid",
            borderColor: "divider",
            gap: 1,
          }}
        >
          <Button
            onClick={() => setOpenDialog(false)}
            variant="outlined"
            sx={{
              borderRadius: 1,
              textTransform: "none",
              px: 3,
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleSubmit}
            disabled={loading}
            sx={{
              borderRadius: 1,
              textTransform: "none",
              px: 3,
            }}
          >
            Create Indicator
          </Button>
        </DialogActions>
      </Dialog>

      {/* View Indicator Dialog */}
      {selectedIndicator && (
        <Dialog
          open={Boolean(selectedIndicator)}
          onClose={() => setSelectedIndicator(null)}
          maxWidth="md"
          fullWidth
          PaperProps={{
            sx: {
              borderRadius: 2,
              boxShadow: (theme) => theme.shadows[24],
              maxHeight: "90vh",
            },
          }}
        >
          <DialogTitle
            sx={{
              borderBottom: "1px solid",
              borderColor: "divider",
              px: 3,
              py: 2,
            }}
          >
            <Typography variant="subtitle2" color="text.secondary" gutterBottom>
              {selectedIndicator.outputInfo?.Partner}
            </Typography>
            <Typography variant="h6" sx={{ fontWeight: 600 }}>
              Indicator Details
            </Typography>
          </DialogTitle>

          <DialogContent sx={{ px: 3, py: 3 }}>
            <Grid container spacing={3}>
              {/* Description and Output Section */}
              <Grid item xs={12}>
                <Paper
                  sx={{
                    p: 3,
                    bgcolor: (theme) => alpha(theme.palette.primary.main, 0.03),
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    color="text.secondary"
                    gutterBottom
                  >
                    Details
                  </Typography>
                  <Box sx={{ mb: 3 }}>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      gutterBottom
                    >
                      Description
                    </Typography>
                    <Typography variant="body1">
                      {selectedIndicator.Description}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      gutterBottom
                    >
                      Output
                    </Typography>
                    <Typography variant="body1">
                      {selectedIndicator.Output}
                    </Typography>
                  </Box>
                </Paper>
              </Grid>

              {/* Performance Section */}
              <Grid item xs={12}>
                <Paper sx={{ p: 3 }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      mb: 3,
                    }}
                  >
                    <Typography
                      variant="subtitle2"
                      color="text.secondary"
                      gutterBottom
                    >
                      Performance Summary
                    </Typography>

                    <FormControl sx={{ minWidth: 120 }} size="small">
                      <InputLabel>Year</InputLabel>
                      <Select
                        value={selectedYear}
                        onChange={(e) => setSelectedYear(e.target.value)}
                        label="Year"
                      >
                        {[...Array(5)].map((_, i) => {
                          const year = new Date().getFullYear() - i;
                          return (
                            <MenuItem key={year} value={year}>
                              {year}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Box>

                  {/* Chart */}
                  <Box sx={{ width: "100%", height: 300, mt: 2 }}>
                    <ResponsiveContainer>
                      <BarChart
                        data={
                          processPerformanceData(selectedIndicator)
                            .quarterlyData
                        }
                        margin={{
                          top: 5,
                          right: 30,
                          left: 20,
                          bottom: 5,
                        }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <RechartsTooltip
                          contentStyle={{
                            backgroundColor: "white",
                            borderRadius: 8,
                            border: "1px solid #ccc",
                            boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                          }}
                        />
                        <Legend />
                        <Bar
                          dataKey="Target"
                          fill="#8884d8"
                          name="Quarterly Target"
                          radius={[4, 4, 0, 0]}
                        />
                        <Bar
                          dataKey="Performance"
                          fill="#82ca9d"
                          name="Actual Performance"
                          radius={[4, 4, 0, 0]}
                        />
                      </BarChart>
                    </ResponsiveContainer>
                  </Box>

                  {/* Performance Summary */}
                  <Box sx={{ mt: 3 }}>
                    <Typography
                      variant="subtitle2"
                      color="text.secondary"
                      gutterBottom
                    >
                      Performance Breakdown
                    </Typography>
                    <Grid container spacing={2}>
                      {/* Annual Performance Card */}
                      <Grid item xs={6} sm={2.4}>
                        <Paper
                          sx={{
                            p: 1.5,
                            bgcolor: (theme) =>
                              alpha(theme.palette.primary.main, 0.08),
                            border: "1px solid",
                            borderColor: "primary.main",
                            boxShadow: 2,
                          }}
                        >
                          <Box sx={{ textAlign: "center" }}>
                            <Typography
                              variant="body2"
                              sx={{
                                color: "primary.main",
                                fontWeight: 600,
                                mb: 1,
                              }}
                              gutterBottom
                            >
                              Annual
                            </Typography>
                            <Typography
                              variant="body2"
                              sx={{
                                color: "primary.main",
                                mb: 0.5,
                              }}
                            >
                              Target: {selectedIndicator.Target_Annual || "0"}
                            </Typography>
                            <Typography
                              variant="body1"
                              sx={{
                                color:
                                  processPerformanceData(selectedIndicator)
                                    .annualPerformance >=
                                  Number(selectedIndicator.Target_Annual)
                                    ? "success.main"
                                    : "error.main",
                                fontWeight: 600,
                              }}
                            >
                              Actual:{" "}
                              {
                                processPerformanceData(selectedIndicator)
                                  .annualPerformance
                              }
                            </Typography>
                          </Box>
                        </Paper>
                      </Grid>

                      {/* Quarterly Performance Cards */}
                      {["Q1", "Q2", "Q3", "Q4"].map((quarter) => {
                        const performance = selectedIndicator.performance?.find(
                          (p) => p.Quarter === quarter
                        );
                        return (
                          <Grid item xs={6} sm={2.4} key={quarter}>
                            <Paper sx={{ p: 1.5, bgcolor: "grey.50" }}>
                              <Box sx={{ textAlign: "center" }}>
                                <Typography
                                  variant="body2"
                                  color="text.secondary"
                                  gutterBottom
                                >
                                  {quarter}
                                </Typography>
                                <Typography
                                  variant="body2"
                                  color="text.secondary"
                                >
                                  Target:{" "}
                                  {selectedIndicator[`Target_${quarter}`] ||
                                    "0"}
                                </Typography>
                                <Typography
                                  variant="body2"
                                  sx={{
                                    color:
                                      performance?.Performance >=
                                      selectedIndicator[`Target_${quarter}`]
                                        ? "success.main"
                                        : "error.main",
                                    fontWeight: 500,
                                  }}
                                >
                                  Actual: {performance?.Performance || "0"}
                                </Typography>
                              </Box>
                            </Paper>
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Box>
                </Paper>
              </Grid>

              <Box sx={{ mt: 3, p: 2, bgcolor: "grey.50", borderRadius: 1 }}>
                <Typography
                  variant="subtitle2"
                  color="text.secondary"
                  gutterBottom
                >
                  Update Performance
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={4}>
                    <FormControl fullWidth>
                      <InputLabel>Quarter</InputLabel>
                      <Select
                        value={performanceForm.quarter}
                        onChange={(e) =>
                          setPerformanceForm((prev) => ({
                            ...prev,
                            quarter: e.target.value,
                          }))
                        }
                        label="Quarter"
                      >
                        <MenuItem value="Q1">Q1 (Apr-Jun)</MenuItem>
                        <MenuItem value="Q2">Q2 (Jul-Sep)</MenuItem>
                        <MenuItem value="Q3">Q3 (Oct-Dec)</MenuItem>
                        <MenuItem value="Q4">Q4 (Jan-Mar)</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      fullWidth
                      type="number"
                      label="Value"
                      value={performanceForm.value}
                      onChange={(e) =>
                        setPerformanceForm((prev) => ({
                          ...prev,
                          value: e.target.value,
                        }))
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      fullWidth
                      type="number"
                      label="Year"
                      value={performanceForm.year}
                      onChange={(e) =>
                        setPerformanceForm((prev) => ({
                          ...prev,
                          year: e.target.value,
                        }))
                      }
                      defaultValue={selectedYear}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      onClick={() =>
                        handlePerformanceUpdate(
                          selectedIndicator.ID,
                          performanceForm.quarter,
                          performanceForm.value,
                          performanceForm.year
                        )
                      }
                      disabled={
                        !performanceForm.quarter ||
                        !performanceForm.value ||
                        !performanceForm.year
                      }
                      sx={{
                        borderRadius: 1,
                        textTransform: "none",
                      }}
                    >
                      Update Performance
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </DialogContent>

          <DialogActions
            sx={{
              px: 3,
              py: 2,
              borderTop: "1px solid",
              borderColor: "divider",
            }}
          >
            <Button
              onClick={() => setSelectedIndicator(null)}
              variant="contained"
              sx={{
                borderRadius: 1,
                textTransform: "none",
                px: 3,
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {/* Edit Dialog */}
      <Dialog
        open={editDialog}
        onClose={() => {
          setEditDialog(false);
          setEditingIndicator(null);
          setFormData({
            description: "",
            baseline: "",
            targetAnnual: "",
            targetQ1: "",
            targetQ2: "",
            targetQ3: "",
            targetQ4: "",
          });
          setError("");
        }}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Edit Indicator</DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 2, display: "flex", flexDirection: "column", gap: 2 }}>
            <FormControl fullWidth>
              <InputLabel>Output</InputLabel>
              <Select
                value={selectedOutputID || ""}
                onChange={(e) => setSelectedOutputID(e.target.value)}
                label="Output"
              >
                {outputs.map((output) => (
                  <MenuItem key={output.ID} value={output.ID}>
                    {output.Description}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              fullWidth
              label="Indicator Description"
              multiline
              rows={3}
              value={formData.description}
              onChange={handleChange("description")}
            />

            <TextField
              fullWidth
              label="Baseline Value"
              value={formData.baseline}
              onChange={handleChange("baseline")}
            />

            <TextField
              fullWidth
              label="Annual Target"
              value={formData.targetAnnual}
              onChange={handleChange("targetAnnual")}
            />

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Q1 Target"
                  value={formData.targetQ1}
                  onChange={handleChange("targetQ1")}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Q2 Target"
                  value={formData.targetQ2}
                  onChange={handleChange("targetQ2")}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Q3 Target"
                  value={formData.targetQ3}
                  onChange={handleChange("targetQ3")}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Q4 Target"
                  value={formData.targetQ4}
                  onChange={handleChange("targetQ4")}
                />
              </Grid>
            </Grid>

            {error && (
              <Typography color="error" variant="body2">
                {error}
              </Typography>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setEditDialog(false);
              setEditingIndicator(null);
              setFormData({
                description: "",
                baseline: "",
                targetAnnual: "",
                targetQ1: "",
                targetQ2: "",
                targetQ3: "",
                targetQ4: "",
              });
              setError("");
            }}
          >
            Cancel
          </Button>
          <Button variant="contained" onClick={handleEdit} disabled={loading}>
            Save Changes
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={deleteConfirmOpen}
        onClose={() => setDeleteConfirmOpen(false)}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete this indicator? This action cannot
            be undone.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteConfirmOpen(false)}>Cancel</Button>
          <Button
            onClick={confirmDelete}
            color="error"
            variant="contained"
            disabled={loading}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Update Confirmation Dialog */}
      <Dialog
        open={updateConfirmOpen}
        onClose={() => setUpdateConfirmOpen(false)}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle>Confirm Update</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to update this indicator?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setUpdateConfirmOpen(false)}>Cancel</Button>
          <Button
            onClick={confirmUpdate}
            color="primary"
            variant="contained"
            disabled={loading}
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>

      {loading && <Loading />}

      <Snackbar
        open={Boolean(successMessage)}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        sx={{
          position: "fixed",
          left: "50%",
          transform: "translateX(-50%)",
          width: "auto",
          maxWidth: "80%",
        }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="success"
          sx={{
            width: "100%",
            minWidth: "300px",
            display: "flex",
            alignItems: "center",
          }}
        >
          {successMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Indicators;
