import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  IconButton,
  Tooltip,
  Divider,
  Alert,
  Snackbar,
  CircularProgress,
  Stack,
  Card,
  CardContent,
  Avatar,
  Chip,
  useTheme,
  alpha,
  Grid,
  Fade,
  ButtonBase,
  Grow,
} from "@mui/material";
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Add as AddIcon,
  Business as BusinessIcon,
  Search as SearchIcon,
  LocationOn as LocationIcon,
  Phone as PhoneIcon,
  Email as EmailIcon,
  Language as WebsiteIcon,
  Person as ContactIcon,
  ExpandMore as ExpandMoreIcon,
} from "@mui/icons-material";
import InputAdornment from "@mui/material/InputAdornment";

const Partners = () => {
  const [partners, setPartners] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(10);
  const [openDialog, setOpenDialog] = useState(false);
  const [editingPartner, setEditingPartner] = useState(null);
  const [loading, setLoading] = useState(true);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const [formData, setFormData] = useState({
    Name: "",
    Country: "",
    Offices: "",
    AreaOfDuty: "",
    Projects: "",
    Website: "",
    ContactPerson: "",
    Telephone: "",
    Email: "",
    Address: "",
  });
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(true);

  const theme = useTheme();

  const debounce = (func, wait) => {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(this, args), wait);
    };
  };

  const searchPartners = async (query) => {
    if (!query.trim()) {
      setIsSearching(false);
      setSearchResults(partners); // Reset to all partners
      return;
    }

    setIsSearching(true);
    try {
      const filteredResults = partners.filter((partner) =>
        partner.Name.toLowerCase().includes(query.toLowerCase())
      );
      setSearchResults(filteredResults);
    } catch (error) {
      console.error("Error searching partners:", error);
      showSnackbar("Error searching partners", "error");
    } finally {
      setIsSearching(false);
    }
  };

  const debouncedSearch = useCallback(
    debounce((query) => searchPartners(query), 300),
    [partners]
  );

  const handleSearch = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    debouncedSearch(query);
  };

  useEffect(() => {
    fetchPartners();
  }, [page]);

  const fetchPartners = async () => {
    setLoading(true);
    try {
      const response = await fetch(`/api/partners/all/${page * rowsPerPage}`);
      const data = await response.json();
      setPartners(data.data || []);
    } catch (error) {
      console.error("Error fetching partners:", error);
      showSnackbar("Failed to load partners", "error");
    } finally {
      setLoading(false);
    }
  };

  const showSnackbar = (message, severity = "success") => {
    setSnackbar({ open: true, message, severity });
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const handleOpenDialog = (partner = null) => {
    if (partner) {
      setEditingPartner(partner);
      setFormData(partner);
    } else {
      setEditingPartner(null);
      setFormData({
        Name: "",
        Country: "",
        Offices: "",
        AreaOfDuty: "",
        Projects: "",
        Website: "",
        ContactPerson: "",
        Telephone: "",
        Email: "",
        Address: "",
      });
    }
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setEditingPartner(null);
  };

  const handleSubmit = async () => {
    try {
      const url = editingPartner
        ? `/api/partners/${editingPartner.ID}`
        : "/api/partners/create";
      const method = editingPartner ? "PUT" : "POST";

      const response = await fetch(url, {
        method,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        handleCloseDialog();
        fetchPartners();
        showSnackbar(
          editingPartner
            ? "Partner updated successfully"
            : "Partner created successfully"
        );
      } else {
        showSnackbar("Error saving partner", "error");
      }
    } catch (error) {
      console.error("Error saving partner:", error);
      showSnackbar("Error saving partner", "error");
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this partner?")) {
      try {
        const response = await fetch(`/api/partners/${id}`, {
          method: "DELETE",
        });

        if (response.ok) {
          fetchPartners();
          showSnackbar("Partner deleted successfully");
        } else {
          showSnackbar("Error deleting partner", "error");
        }
      } catch (error) {
        console.error("Error deleting partner:", error);
        showSnackbar("Error deleting partner", "error");
      }
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const toggleFilterSection = () => {
    setIsFilterOpen((prev) => !prev);
  };

  return (
    <Box sx={{ p: 3, bgcolor: "#f5f5f9", minHeight: "100vh" }}>
      {/* Header Section */}
      <Paper
        elevation={0}
        sx={{
          p: 2,
          mb: 3,
          borderRadius: 2,
          boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
          transition: "all 0.3s",
          "&:hover": {
            boxShadow: "0 6px 16px rgba(0,0,0,0.15)",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <IconButton
            onClick={toggleFilterSection}
            sx={{
              transition: "transform 0.3s",
              transform: isFilterOpen ? "rotate(180deg)" : "rotate(0deg)",
            }}
          >
            <ExpandMoreIcon />
          </IconButton>
          <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
            <IconButton
              onClick={() => handleOpenDialog()}
              sx={{
                bgcolor: theme.palette.primary.main,
                color: "white",
                "&:hover": {
                  bgcolor: theme.palette.primary.dark,
                },
              }}
            >
              <AddIcon />
            </IconButton>
            <TextField
              placeholder="Search..."
              value={searchQuery}
              onChange={handleSearch}
              size="small"
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: 2,
                  bgcolor: "#f5f5f9",
                  "&:hover": {
                    bgcolor: "#eeeef2",
                  },
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon color="action" />
                  </InputAdornment>
                ),
                endAdornment: isSearching && (
                  <InputAdornment position="end">
                    <CircularProgress size={20} />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Box>
      </Paper>

      {/* Partners Table */}
      <Grow in timeout={1000}>
        <Card
          elevation={0}
          sx={{
            borderRadius: 2,
            overflow: "hidden",
            boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
            "& .MuiTableRow-root:hover": {
              bgcolor: "#f5f5f9",
              transform: "translateY(-2px)",
              boxShadow: "0 4px 12px rgba(0,0,0,0.05)",
            },
          }}
        >
          <TableContainer>
            {loading ? (
              <Box sx={{ display: "flex", justifyContent: "center", p: 5 }}>
                <CircularProgress />
              </Box>
            ) : (
              <>
                <Table>
                  <TableHead>
                    <TableRow sx={{ bgcolor: "#f5f5f9" }}>
                      <TableCell sx={{ fontWeight: 600 }}>Partner</TableCell>
                      <TableCell sx={{ fontWeight: 600 }}>Location</TableCell>
                      <TableCell sx={{ fontWeight: 600 }}>Contact</TableCell>
                      <TableCell sx={{ fontWeight: 600 }}>Projects</TableCell>
                      <TableCell align="right" sx={{ fontWeight: 600 }}>
                        Actions
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(searchQuery ? searchResults : partners).map((partner) => (
                      <TableRow
                        key={partner.ID}
                        sx={{
                          transition: "all 0.2s",
                          "&:hover": {
                            transform: "translateY(-2px)",
                            boxShadow: "0 4px 12px rgba(0,0,0,0.05)",
                          },
                        }}
                      >
                        <TableCell>
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                          >
                            <Avatar
                              sx={{
                                width: 45,
                                height: 45,
                                bgcolor: alpha(theme.palette.primary.main, 0.1),
                                color: theme.palette.primary.main,
                                fontSize: "1.2rem",
                                fontWeight: 600,
                              }}
                            >
                              {partner.Name.split(" ")
                                .map((n) => n[0])
                                .join("")}
                            </Avatar>
                            <Box>
                              <Typography variant="subtitle1" fontWeight={600}>
                                {partner.Name}
                              </Typography>
                              <Typography
                                variant="caption"
                                color="text.secondary"
                              >
                                {partner.AreaOfDuty}
                              </Typography>
                            </Box>
                          </Stack>
                        </TableCell>
                        <TableCell>
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={1}
                          >
                            <LocationIcon fontSize="small" color="action" />
                            <Typography variant="body2">
                              {partner.Country}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell>
                          <Stack spacing={0.5}>
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={1}
                            >
                              <ContactIcon fontSize="small" color="action" />
                              <Typography variant="body2">
                                {partner.ContactPerson}
                              </Typography>
                            </Stack>
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={1}
                            >
                              <PhoneIcon fontSize="small" color="action" />
                              <Typography variant="body2">
                                {partner.Telephone}
                              </Typography>
                            </Stack>
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={1}
                            >
                              <EmailIcon fontSize="small" color="action" />
                              <Typography variant="body2">
                                {partner.Email}
                              </Typography>
                            </Stack>
                          </Stack>
                        </TableCell>
                        <TableCell>
                          <Chip
                            size="small"
                            label={partner.Projects || "No Projects"}
                            sx={{
                              borderRadius: 1,
                              bgcolor: alpha(theme.palette.info.main, 0.1),
                              color: theme.palette.info.main,
                            }}
                          />
                        </TableCell>
                        <TableCell align="right">
                          <Stack
                            direction="row"
                            spacing={1}
                            justifyContent="flex-end"
                          >
                            <IconButton
                              onClick={() => handleOpenDialog(partner)}
                              size="small"
                              sx={{
                                color: "primary.main",
                                bgcolor: alpha(theme.palette.primary.main, 0.1),
                                "&:hover": {
                                  bgcolor: alpha(
                                    theme.palette.primary.main,
                                    0.2
                                  ),
                                },
                              }}
                            >
                              <EditIcon fontSize="small" />
                            </IconButton>
                            <IconButton
                              onClick={() => handleDelete(partner.ID)}
                              size="small"
                              sx={{
                                color: "error.main",
                                bgcolor: alpha(theme.palette.error.main, 0.1),
                                "&:hover": {
                                  bgcolor: alpha(theme.palette.error.main, 0.2),
                                },
                              }}
                            >
                              <DeleteIcon fontSize="small" />
                            </IconButton>
                          </Stack>
                        </TableCell>
                      </TableRow>
                    ))}
                    {(searchQuery ? searchResults : partners).length === 0 && (
                      <TableRow>
                        <TableCell colSpan={5} align="center" sx={{ py: 3 }}>
                          <Typography color="text.secondary">
                            {searchQuery
                              ? "No partners found matching your search"
                              : "No partners available"}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
                {!searchQuery && (
                  <Box sx={{ p: 2 }}>
                    <TablePagination
                      component="div"
                      count={-1}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      rowsPerPageOptions={[10]}
                    />
                  </Box>
                )}
              </>
            )}
          </TableContainer>
        </Card>
      </Grow>

      {/* Partner Dialog */}
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        maxWidth="md"
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: 2,
            boxShadow: "0 8px 32px rgba(0,0,0,0.1)",
          },
        }}
      >
        <DialogTitle sx={{ pb: 1 }}>
          <Typography variant="h6" component="div" sx={{ fontWeight: 600 }}>
            {editingPartner ? "Edit Partner" : "Add New Partner"}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {editingPartner
              ? "Update the partner's information"
              : "Enter the details for the new partner"}
          </Typography>
        </DialogTitle>
        <Divider />
        <DialogContent sx={{ py: 3 }}>
          <Box
            sx={{
              display: "grid",
              gap: 3,
              gridTemplateColumns: "1fr 1fr",
            }}
          >
            <TextField
              label="Name"
              value={formData.Name}
              onChange={(e) =>
                setFormData({ ...formData, Name: e.target.value })
              }
              fullWidth
              size="small"
              required
            />
            <TextField
              label="Country"
              value={formData.Country}
              onChange={(e) =>
                setFormData({ ...formData, Country: e.target.value })
              }
              fullWidth
              size="small"
              required
            />
            <TextField
              label="Offices"
              value={formData.Offices}
              onChange={(e) =>
                setFormData({ ...formData, Offices: e.target.value })
              }
              fullWidth
              size="small"
            />
            <TextField
              label="Area of Duty"
              value={formData.AreaOfDuty}
              onChange={(e) =>
                setFormData({ ...formData, AreaOfDuty: e.target.value })
              }
              fullWidth
              size="small"
              required
            />
            <TextField
              label="Projects"
              value={formData.Projects}
              onChange={(e) =>
                setFormData({ ...formData, Projects: e.target.value })
              }
              fullWidth
              size="small"
            />
            <TextField
              label="Website"
              value={formData.Website}
              onChange={(e) =>
                setFormData({ ...formData, Website: e.target.value })
              }
              fullWidth
              size="small"
            />
            <TextField
              label="Contact Person"
              value={formData.ContactPerson}
              onChange={(e) =>
                setFormData({ ...formData, ContactPerson: e.target.value })
              }
              fullWidth
              size="small"
              required
            />
            <TextField
              label="Telephone"
              value={formData.Telephone}
              onChange={(e) =>
                setFormData({ ...formData, Telephone: e.target.value })
              }
              fullWidth
              size="small"
              required
            />
            <TextField
              label="Email"
              value={formData.Email}
              onChange={(e) =>
                setFormData({ ...formData, Email: e.target.value })
              }
              fullWidth
              size="small"
              required
            />
            <TextField
              label="Address"
              value={formData.Address}
              onChange={(e) =>
                setFormData({ ...formData, Address: e.target.value })
              }
              fullWidth
              size="small"
              multiline
              rows={2}
            />
          </Box>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ p: 2, gap: 1 }}>
          <Button
            onClick={handleCloseDialog}
            disabled={isSubmitting}
            sx={{
              textTransform: "none",
              borderRadius: 1,
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            variant="contained"
            disabled={isSubmitting}
            sx={{
              textTransform: "none",
              borderRadius: 1,
              px: 3,
              minWidth: 120,
              position: "relative",
            }}
          >
            {isSubmitting ? (
              <>
                <CircularProgress
                  size={20}
                  sx={{
                    position: "absolute",
                    left: "50%",
                    marginLeft: "-10px",
                    color: "white",
                  }}
                />
                <span style={{ opacity: 0 }}>Saving...</span>
              </>
            ) : editingPartner ? (
              "Save Changes"
            ) : (
              "Create Partner"
            )}
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Partners;
