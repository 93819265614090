import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Card,
  Typography,
  Button,
  Stack,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Alert,
  Snackbar,
  CircularProgress,
  Grid,
  Autocomplete,
} from "@mui/material";
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  LocationOn as LocationIcon,
  Store as StoreIcon,
} from "@mui/icons-material";
import LocationMap from "../common/LocationMap";

const Agrovets = () => {
  const [agrovets, setAgrovets] = useState([]);
  const [partners, setPartners] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [editingAgrovet, setEditingAgrovet] = useState(null);
  const [showMap, setShowMap] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const [formData, setFormData] = useState({
    Name: "",
    Location: "",
    Latitude: "",
    Longitude: "",
    Contact: "",
    Partner: "",
    Email: "",
    Address: "",
    Services: "",
    Status: true,
  });
  const [searchQuery, setSearchQuery] = useState("");
  const mapRef = useRef();
  const [map, setMap] = useState(null);
  const [markers, setMarkers] = useState([]);

  const fetchAgrovets = async () => {
    try {
      const queryParams = rowsPerPage
        ? `?page=${page + 1}&limit=${rowsPerPage}`
        : "";
      const response = await fetch(`/api/utility-agrovets/all${queryParams}`);
      const data = await response.json();
      setAgrovets(data.data || []);
      setTotalCount(data.total || 0);
    } catch (error) {
      console.error("Error fetching agrovets:", error);
      showSnackbar("Failed to load agrovets", "error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAgrovets();
    fetchPartners();
  }, [page, rowsPerPage]);

  const fetchPartners = async () => {
    try {
      const response = await fetch("/api/partners");
      const data = await response.json();
      setPartners(Array.isArray(data?.data) ? data?.data : []);
    } catch (error) {
      console.error("Error fetching partners:", error);
      setPartners([]);
    }
  };

  const handleSubmit = async () => {
    try {
      const url = editingAgrovet
        ? `/api/utility-agrovets/${editingAgrovet.FID}`
        : "/api/utility-agrovets/create";
      const method = editingAgrovet ? "PUT" : "POST";

      const response = await fetch(url, {
        method,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) throw new Error("Failed to save");

      showSnackbar(
        `Agrovet ${editingAgrovet ? "updated" : "created"} successfully`
      );
      setOpenDialog(false);
      fetchAgrovets();
    } catch (error) {
      console.error("Error saving agrovet:", error);
      showSnackbar("Failed to save agrovet", "error");
    }
  };

  const handleDelete = async (id) => {
    if (!window.confirm("Are you sure you want to delete this agrovet?"))
      return;

    try {
      const response = await fetch(`/api/utility-agrovets/${id}`, {
        method: "DELETE",
      });

      if (!response.ok) throw new Error("Failed to delete");

      showSnackbar("Agrovet deleted successfully");
      fetchAgrovets();
    } catch (error) {
      console.error("Error deleting agrovet:", error);
      showSnackbar("Failed to delete agrovet", "error");
    }
  };

  const handleOpenMap = (agrovet) => {
    setSelectedLocation(agrovet);
    setShowMap(true);
  };

  const showSnackbar = (message, severity = "success") => {
    setSnackbar({ open: true, message, severity });
  };

  const filteredData = agrovets.filter((item) =>
    item.Name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <Box sx={{ p: 3 }}>
      {/* Header Card */}
      <Card sx={{ mb: 3, borderRadius: 2 }}>
        {/* Table Section */}
        <TableContainer sx={{ p: 2 }}>
          <Box sx={{ mb: 2, display: "flex", justifyContent: "space-between" }}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={() => {
                setEditingAgrovet(null);
                setFormData({
                  Name: "",
                  Location: "",
                  Latitude: "",
                  Longitude: "",
                  Contact: "",
                  Partner: "",
                  Email: "",
                  Address: "",
                  Services: "",
                  Status: true,
                  Country: "",
                  Region: "",
                  Town: "",
                  Project: "",
                  Unit: "",
                });
                setOpenDialog(true);
              }}
              sx={{ borderRadius: 2 }}
            >
              Add Agrovet
            </Button>
            <TextField
              size="small"
              variant="outlined"
              placeholder="Search by name, location, or partner..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              sx={{ width: 350, borderRadius: 2 }}
            />
          </Box>

          {loading ? (
            <Box sx={{ display: "flex", justifyContent: "center", p: 3 }}>
              <CircularProgress />
            </Box>
          ) : (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Location</TableCell>
                  <TableCell>Country</TableCell>
                  <TableCell>Region</TableCell>
                  <TableCell>Town</TableCell>
                  <TableCell>Project</TableCell>
                  <TableCell>Partner</TableCell>
                  <TableCell align="right">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredData.map((agrovet) => (
                  <TableRow key={agrovet.FID}>
                    <TableCell>{agrovet.Name}</TableCell>
                    <TableCell>{agrovet.Location}</TableCell>
                    <TableCell>{agrovet.Country}</TableCell>
                    <TableCell>{agrovet.Region}</TableCell>
                    <TableCell>{agrovet.Town}</TableCell>
                    <TableCell>{agrovet.Project}</TableCell>
                    <TableCell>{agrovet.Partner}</TableCell>
                    <TableCell align="right">
                      <IconButton
                        color="info"
                        onClick={() => handleOpenMap(agrovet)}
                        size="small"
                      >
                        <LocationIcon />
                      </IconButton>
                      <IconButton
                        color="primary"
                        onClick={() => {
                          setEditingAgrovet(agrovet);
                          setFormData(agrovet);
                          setOpenDialog(true);
                        }}
                        size="small"
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        color="error"
                        onClick={() => handleDelete(agrovet.FID)}
                        size="small"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
          <TablePagination
            sx={{ mt: 2 }}
            component="div"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(_, newPage) => setPage(newPage)}
            onRowsPerPageChange={(event) => {
              setPage(0);
              setRowsPerPage(parseInt(event.target.value, 10));
            }}
            rowsPerPageOptions={[10, 25, 50]}
          />
        </TableContainer>
      </Card>

      {/* Map Dialog */}
      <Dialog
        open={showMap}
        onClose={() => setShowMap(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Location Map</DialogTitle>
        <DialogContent>
          {selectedLocation && (
            <Box sx={{ height: 400, width: "100%" }}>
              <LocationMap location={selectedLocation} />
            </Box>
          )}
        </DialogContent>
      </Dialog>

      {/* Add/Edit Dialog */}
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          {editingAgrovet ? "Edit Agrovet" : "Add New Agrovet"}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={3} sx={{ mt: 1 }}>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Name"
                fullWidth
                value={formData.Name}
                onChange={(e) =>
                  setFormData({ ...formData, Name: e.target.value })
                }
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                select
                label="Partner"
                fullWidth
                value={formData.Partner}
                onChange={(e) =>
                  setFormData({ ...formData, Partner: e.target.value })
                }
                SelectProps={{
                  native: true,
                }}
                required
              >
                <option value="">Select Partner</option>
                {partners.map((partner) => (
                  <option key={partner?.ID} value={partner?.Name}>
                    {partner?.Name}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Country"
                fullWidth
                value={formData.Country}
                onChange={(e) =>
                  setFormData({ ...formData, Country: e.target.value })
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Region"
                fullWidth
                value={formData.Region}
                onChange={(e) =>
                  setFormData({ ...formData, Region: e.target.value })
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Town"
                fullWidth
                value={formData.Town}
                onChange={(e) =>
                  setFormData({ ...formData, Town: e.target.value })
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Project"
                fullWidth
                value={formData.Project}
                onChange={(e) =>
                  setFormData({ ...formData, Project: e.target.value })
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Location"
                fullWidth
                value={formData.Location}
                onChange={(e) =>
                  setFormData({ ...formData, Location: e.target.value })
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Unit"
                fullWidth
                value={formData.Unit}
                onChange={(e) =>
                  setFormData({ ...formData, Unit: e.target.value })
                }
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ p: 2.5 }}>
          <Button onClick={() => setOpenDialog(false)} color="inherit">
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleSubmit}
            disabled={!formData.Name || !formData.Partner}
            sx={{ borderRadius: 2, px: 3 }}
          >
            {editingAgrovet ? "Save Changes" : "Add Agrovet"}
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          variant="filled"
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Agrovets;
