import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  Typography,
  Button,
  Stack,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Alert,
  Snackbar,
  CircularProgress,
  Grid,
  Autocomplete,
} from "@mui/material";
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  LocationOn as LocationIcon,
  LocalHospital as LocalHospitalIcon,
} from "@mui/icons-material";
import LocationMap from "../common/LocationMap";

const Practitioners = () => {
  const [practitioners, setPractitioners] = useState([]);
  const [partners, setPartners] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openDialog, setOpenDialog] = useState(false);
  const [editingPractitioner, setEditingPractitioner] = useState(null);
  const [showMap, setShowMap] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const [formData, setFormData] = useState({
    AHP_Name: "",
    AHP_ID: "",
    Country: "",
    Context: "",
    Region: "",
    Location: "",
    Community: "",
    Partner: "",
    Gender: "",
  });
  const [searchQuery, setSearchQuery] = useState("");
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    fetchPractitioners();
    fetchPartners();
  }, [page, rowsPerPage]);

  const fetchPartners = async () => {
    try {
      const response = await fetch("/api/partners");
      const data = await response.json();
      setPartners(Array.isArray(data?.data) ? data?.data : []);
    } catch (error) {
      setPartners([]);
    }
  };

  const fetchPractitioners = async () => {
    try {
      const queryParams = rowsPerPage
        ? `?page=${page + 1}&limit=${rowsPerPage}`
        : "";
      const response = await fetch(
        `/api/practitioners-utility/all${queryParams}`
      );
      const data = await response.json();
      setPractitioners(data.data || []);
      setTotalCount(data.total || 0);
    } catch (error) {
      console.error("Error fetching practitioners:", error);
      showSnackbar("Failed to load practitioners", "error");
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    try {
      const url = editingPractitioner
        ? `/api/practitioners-utility/${editingPractitioner.FID}`
        : "/api/practitioners-utility/create";
      const method = editingPractitioner ? "PUT" : "POST";

      const response = await fetch(url, {
        method,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to save");
      }

      showSnackbar(
        `Practitioner ${
          editingPractitioner ? "updated" : "created"
        } successfully`
      );
      setOpenDialog(false);
      setFormData({
        AHP_Name: "",
        AHP_ID: "",
        Country: "",
        Context: "",
        Region: "",
        Location: "",
        Community: "",
        Partner: "",
        Gender: "",
      });
      setEditingPractitioner(null);
      fetchPractitioners();
    } catch (error) {
      console.error("Error saving practitioner:", error);
      showSnackbar(error.message || "Failed to save practitioner", "error");
    }
  };

  const handleDelete = async (id) => {
    if (!window.confirm("Are you sure you want to delete this practitioner?"))
      return;

    try {
      const response = await fetch(`/api/practitioners-utility/${id}`, {
        method: "DELETE",
      });

      if (!response.ok) throw new Error("Failed to delete");

      showSnackbar("Practitioner deleted successfully");
      fetchPractitioners();
    } catch (error) {
      console.error("Error deleting practitioner:", error);
      showSnackbar("Failed to delete practitioner", "error");
    }
  };

  const handleOpenMap = (practitioner) => {
    setSelectedLocation(practitioner);
    setShowMap(true);
  };

  const showSnackbar = (message, severity = "success") => {
    setSnackbar({ open: true, message, severity });
  };

  const filteredData = practitioners.filter((item) =>
    item.AHP_Name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <Box sx={{ p: 3 }}>
      <Card sx={{ mb: 3, borderRadius: 2 }}>
        <TableContainer sx={{ p: 2 }}>
          <Box sx={{ mb: 2, display: "flex", justifyContent: "space-between" }}>
            <TextField
              size="small"
              variant="outlined"
              placeholder="Search by name, ID, or partner..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              sx={{ width: 350, borderRadius: 2 }}
            />
            <Button
              variant="contained"
              color="info"
              sx={{ borderRadius: 2, px: 3 }}
              onClick={() => setOpenDialog(true)}
            >
              <AddIcon sx={{ mr: 1 }} />
              Add Practitioner
            </Button>
          </Box>

          {loading ? (
            <Box sx={{ display: "flex", justifyContent: "center", p: 3 }}>
              <CircularProgress />
            </Box>
          ) : (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>AHP ID</TableCell>
                  <TableCell>Country</TableCell>
                  <TableCell>Region</TableCell>
                  <TableCell>Location</TableCell>
                  <TableCell>Community</TableCell>
                  <TableCell>Partner</TableCell>
                  <TableCell align="right">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredData.map((practitioner) => (
                  <TableRow key={practitioner.FID}>
                    <TableCell>{practitioner.AHP_Name}</TableCell>
                    <TableCell>{practitioner.AHP_ID}</TableCell>
                    <TableCell>{practitioner.Country}</TableCell>
                    <TableCell>{practitioner.Region}</TableCell>
                    <TableCell>{practitioner.Location}</TableCell>
                    <TableCell>{practitioner.Community}</TableCell>
                    <TableCell>{practitioner.Partner}</TableCell>
                    <TableCell align="right">
                      <IconButton
                        color="info"
                        onClick={() => handleOpenMap(practitioner)}
                        size="small"
                      >
                        <LocationIcon />
                      </IconButton>
                      <IconButton
                        color="primary"
                        onClick={() => {
                          setEditingPractitioner(practitioner);
                          setFormData(practitioner);
                          setOpenDialog(true);
                        }}
                        size="small"
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        color="error"
                        onClick={() => handleDelete(practitioner.FID)}
                        size="small"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
          <TablePagination
            sx={{ mt: 2 }}
            component="div"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(_, newPage) => setPage(newPage)}
            onRowsPerPageChange={(event) => {
              setPage(0);
              setRowsPerPage(parseInt(event.target.value, 10));
            }}
            rowsPerPageOptions={[10, 25, 50]}
          />
        </TableContainer>
      </Card>

      {/* Map Dialog */}
      <Dialog
        open={showMap}
        onClose={() => setShowMap(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Location Map</DialogTitle>
        <DialogContent>
          {selectedLocation && (
            <Box sx={{ height: 400, width: "100%" }}>
              <LocationMap location={selectedLocation} />
            </Box>
          )}
        </DialogContent>
      </Dialog>

      {/* Add/Edit Dialog */}
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          {editingPractitioner ? "Edit Practitioner" : "Add New Practitioner"}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={3} sx={{ mt: 1 }}>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Name"
                fullWidth
                value={formData.AHP_Name}
                onChange={(e) =>
                  setFormData({ ...formData, AHP_Name: e.target.value })
                }
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="AHP ID"
                fullWidth
                value={formData.AHP_ID}
                onChange={(e) =>
                  setFormData({ ...formData, AHP_ID: e.target.value })
                }
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                options={partners}
                getOptionLabel={(option) => option.Name || ""}
                value={
                  Array.isArray(partners)
                    ? partners.find((p) => p.Name === formData.Partner) || null
                    : null
                }
                onChange={(_, newValue) =>
                  setFormData({ ...formData, Partner: newValue?.Name || "" })
                }
                renderInput={(params) => (
                  <TextField {...params} label="Partner" required />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Country"
                fullWidth
                value={formData.Country}
                onChange={(e) =>
                  setFormData({ ...formData, Country: e.target.value })
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Region"
                fullWidth
                value={formData.Region}
                onChange={(e) =>
                  setFormData({ ...formData, Region: e.target.value })
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Location"
                fullWidth
                value={formData.Location}
                onChange={(e) =>
                  setFormData({ ...formData, Location: e.target.value })
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Community"
                fullWidth
                value={formData.Community}
                onChange={(e) =>
                  setFormData({ ...formData, Community: e.target.value })
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Context"
                fullWidth
                value={formData.Context}
                onChange={(e) =>
                  setFormData({ ...formData, Context: e.target.value })
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Gender"
                fullWidth
                value={formData.Gender}
                onChange={(e) =>
                  setFormData({ ...formData, Gender: e.target.value })
                }
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ p: 2.5 }}>
          <Button onClick={() => setOpenDialog(false)} color="inherit">
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleSubmit}
            disabled={
              !formData.AHP_Name || !formData.AHP_ID || !formData.Partner
            }
          >
            {editingPractitioner ? "Save Changes" : "Add Practitioner"}
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          variant="filled"
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Practitioners;
