import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Grid,
  Card,
  Typography,
  Button,
  Container,
  Paper,
  IconButton,
  Divider,
  Stack,
  LinearProgress,
  Tooltip,
  Chip,
  Avatar,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import {
  Assessment,
  Pets,
  Groups,
  Store,
  School,
  Build,
  LocalHospital,
  ArrowForward,
  Dashboard,
  TrendingUp,
  BarChart,
  Notifications,
  CalendarToday,
  FilterList,
  Download,
  Refresh,
  MoreVert,
  CheckCircle,
  Warning,
  Error,
  LocationOn,
  Insights,
  MonitorHeart,
} from "@mui/icons-material";
import theme from "../../theme";
import { alpha } from "@mui/material/styles";
import {
  BarChart as RechartsBarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as ChartTooltip,
  Legend,
  ResponsiveContainer,
  LineChart,
  Line,
  PieChart,
  Pie,
  Cell,
} from "recharts";

const Home = ({ user }) => {
  const navigate = useNavigate();
  const [stats, setStats] = useState(null);
  const [loading, setLoading] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedTimeframe, setSelectedTimeframe] = useState("This Month");
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedQuarter, setSelectedQuarter] = useState("Quarter 1");
  const [anchorElYear, setAnchorElYear] = useState(null);
  const [anchorElQuarter, setAnchorElQuarter] = useState(null);

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const response = await fetch(
          `/api/stats/home/all?year=${selectedYear}&quarter=${selectedQuarter}`
        );
        if (response.ok) {
          const data = await response.json();
          setStats(data);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    fetchStats();
  }, [selectedYear, selectedQuarter]);

  console.log(stats);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleTimeframeChange = (timeframe) => {
    setSelectedTimeframe(timeframe);
    handleMenuClose();
  };

  const handleYearChange = (year) => {
    setSelectedYear(year);
    handleMenuClose();
  };

  const handleQuarterChange = (quarter) => {
    setSelectedQuarter(quarter);
    handleMenuClose();
  };

  const handleYearMenuClick = (event) => {
    setAnchorElYear(event.currentTarget);
  };

  const handleQuarterMenuClick = (event) => {
    setAnchorElQuarter(event.currentTarget);
  };

  const handleYearMenuClose = () => {
    setAnchorElYear(null);
  };

  const handleQuarterMenuClose = () => {
    setAnchorElQuarter(null);
  };

  const StatCard = ({
    title,
    value,
    icon,
    trend,
    color,
    subtitle,
    onClick,
  }) => (
    <Card
      sx={{
        height: "100%",
        backgroundColor: "#fff",
        borderRadius: 2,
        transition: "transform 0.3s ease, box-shadow 0.3s ease",
        position: "relative",
        overflow: "hidden",
        border: `1px solid ${alpha(theme.palette[color].main, 0.1)}`,
        "&:hover": {
          transform: "translateY(-6px)",
          boxShadow: `0 16px 32px -12px ${alpha(
            theme.palette[color].main,
            0.3
          )}`,
        },
      }}
      onClick={onClick}
    >
      <Box
        sx={{
          position: "absolute",
          top: 0,
          right: 0,
          width: "150px",
          height: "150px",
          background: `radial-gradient(circle, ${alpha(
            theme.palette[color].main,
            0.05
          )} 0%, transparent 70%)`,
          transition: "transform 0.3s ease",
          transform: "scale(1)",
          transformOrigin: "top right",
        }}
      />
      <Box sx={{ position: "relative", p: 3, zIndex: 1 }}>
        <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
          <Box
            sx={{
              backgroundColor: alpha(theme.palette[color].main, 0.1),
              borderRadius: "12px",
              p: 1.5,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: theme.palette[color].main,
              mr: 2,
            }}
          >
            {icon}
          </Box>
          <Typography
            variant="h6"
            sx={{
              fontWeight: 600,
              color: theme.palette.text.secondary,
              flex: 1,
              fontSize: "0.95rem",
            }}
          >
            {title}
          </Typography>
          {trend && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                color: theme.palette.success.main,
              }}
            >
              <TrendingUp sx={{ fontSize: 20, mr: 0.5 }} />
              <Typography
                variant="body2"
                fontWeight={600}
                sx={{ fontSize: "0.75rem" }}
              >
                {trend}
              </Typography>
            </Box>
          )}
        </Box>
        <Typography
          variant="h3"
          sx={{
            fontWeight: 700,
            color: theme.palette[color].main,
            mb: 1,
            fontSize: "2rem",
          }}
        >
          {value?.toLocaleString() || "0"}
        </Typography>
        {subtitle && (
          <Typography
            variant="body2"
            sx={{
              color: theme.palette.text.secondary,
              fontSize: "0.8rem",
            }}
          >
            {subtitle}
          </Typography>
        )}
      </Box>
    </Card>
  );

  const PerformanceCard = ({ title, data, color }) => (
    <Card
      sx={{
        height: "100%",
        backgroundColor: "#fff",
        borderRadius: 2,
        transition: "transform 0.3s ease, box-shadow 0.3s ease",
        position: "relative",
        overflow: "hidden",
        border: `1px solid ${alpha(theme.palette[color].main, 0.1)}`,
        "&:hover": {
          transform: "translateY(-6px)",
          boxShadow: `0 16px 32px -12px ${alpha(
            theme.palette[color].main,
            0.3
          )}`,
        },
      }}
    >
      <Box sx={{ p: 3 }}>
        <Typography
          variant="h6"
          sx={{
            fontWeight: 600,
            color: theme.palette.text.primary,
            mb: 3,
          }}
        >
          {title}
        </Typography>
        <ResponsiveContainer width="100%" height={300}>
          <LineChart data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <ChartTooltip />
            <Legend />
            <Line
              type="monotone"
              dataKey="value"
              stroke={theme.palette[color].main}
              strokeWidth={2}
              dot={{ r: 4 }}
            />
          </LineChart>
        </ResponsiveContainer>
      </Box>
    </Card>
  );

  const ActivityCard = ({ title, activities }) => (
    <Card
      sx={{
        height: "100%",
        backgroundColor: "#fff",
        borderRadius: 2,
        transition: "transform 0.3s ease, box-shadow 0.3s ease",
        position: "relative",
        overflow: "hidden",
        border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
        "&:hover": {
          transform: "translateY(-6px)",
          boxShadow: `0 16px 32px -12px ${alpha(
            theme.palette.primary.main,
            0.3
          )}`,
        },
      }}
    >
      <Box sx={{ p: 3 }}>
        <Typography
          variant="h6"
          sx={{
            fontWeight: 600,
            color: theme.palette.text.primary,
            mb: 3,
          }}
        >
          {title}
        </Typography>
        <Stack spacing={2}>
          {activities.map((activity, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 2,
                p: 1,
                borderRadius: 1,
                "&:hover": {
                  backgroundColor: alpha(theme.palette.primary.main, 0.05),
                },
              }}
            >
              <Avatar
                sx={{
                  bgcolor: alpha(theme.palette[activity.color].main, 0.1),
                  color: theme.palette[activity.color].main,
                }}
              >
                {activity.icon}
              </Avatar>
              <Box sx={{ flex: 1 }}>
                <Typography variant="body2" sx={{ fontWeight: 500 }}>
                  {activity.title}
                </Typography>
                <Typography
                  variant="caption"
                  sx={{ color: theme.palette.text.secondary }}
                >
                  {activity.time}
                </Typography>
              </Box>
              <Chip
                label={activity.status}
                size="small"
                color={activity.color}
                sx={{ fontWeight: 500 }}
              />
            </Box>
          ))}
        </Stack>
      </Box>
    </Card>
  );

  const QuickActionButton = ({ title, icon, path, color }) => (
    <Button
      onClick={() => navigate(path)}
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: 1,
        p: 2,
        borderRadius: 2,
        backgroundColor: alpha(theme.palette[color].main, 0.05),
        color: theme.palette[color].main,
        transition: "transform 0.3s ease, background-color 0.3s ease",
        "&:hover": {
          backgroundColor: alpha(theme.palette[color].main, 0.15),
          transform: "translateY(-4px)",
        },
      }}
    >
      <Box
        sx={{
          p: 1.5,
          borderRadius: "12px",
          backgroundColor: alpha(theme.palette[color].main, 0.1),
        }}
      >
        {icon}
      </Box>
      <Typography
        variant="body2"
        sx={{
          fontWeight: 500,
          textAlign: "center",
        }}
      >
        {title}
      </Typography>
    </Button>
  );

  return (
    <Box
      sx={{
        minHeight: "100vh",
        backgroundColor: alpha(theme.palette.primary.main, 0.02),
        animation: "fadeIn 0.5s ease-in-out",
      }}
    >
      <Container maxWidth="xl" sx={{ py: 6 }}>
        {/* Header Section */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 6,
            gap: 2,
          }}
        >
          <Box>
            <Typography
              variant="h3"
              sx={{
                fontWeight: 700,
                color: theme.palette.secondary.main,
                position: "relative",
                fontSize: "1.75rem",
                "&::after": {
                  content: '""',
                  position: "absolute",
                  bottom: -8,
                  left: 0,
                  width: 60,
                  height: 4,
                  backgroundColor: theme.palette.primary.main,
                },
              }}
            >
              Dashboard
            </Typography>
          </Box>
          <Box sx={{ display: "flex", gap: 2 }}>
            <Button
              variant="outlined"
              startIcon={<CalendarToday />}
              onClick={handleYearMenuClick}
              sx={{
                borderColor: alpha(theme.palette.primary.main, 0.2),
                color: theme.palette.primary.main,
                "&:hover": {
                  borderColor: theme.palette.primary.main,
                },
              }}
            >
              {selectedYear}
            </Button>
            <Button
              variant="outlined"
              startIcon={<FilterList />}
              onClick={handleQuarterMenuClick}
              sx={{
                borderColor: alpha(theme.palette.primary.main, 0.2),
                color: theme.palette.primary.main,
                "&:hover": {
                  borderColor: theme.palette.primary.main,
                },
              }}
            >
              {selectedQuarter}
            </Button>
            <IconButton
              sx={{
                backgroundColor: alpha(theme.palette.primary.main, 0.05),
                color: theme.palette.primary.main,
                "&:hover": {
                  backgroundColor: alpha(theme.palette.primary.main, 0.1),
                },
              }}
            >
              <Notifications />
            </IconButton>
          </Box>
        </Box>

        {/* Stats Section */}
        <Grid container spacing={4} sx={{ mb: 6 }}>
          <Grid item xs={12} md={3}>
            <StatCard
              title="Active Partners"
              value={stats?.partnersCount || 0}
              icon={<Groups />}
              trend="+15%"
              color="success"
              subtitle="Working with us"
              onClick={() => navigate("/partners")}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <StatCard
              title="Agrovets Assessed"
              value={stats?.agrovetsCount || 0}
              icon={<Assessment />}
              trend="+12%"
              color="primary"
              subtitle={`${selectedYear}, ${selectedQuarter}`}
              onClick={() => navigate("/frameworks/amf")}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <StatCard
              title="Farriers Assessed"
              value={stats?.farriersCount || 0}
              icon={<CalendarToday />}
              trend="+8%"
              color="info"
              subtitle={`${selectedYear}, ${selectedQuarter}`}
              onClick={() => navigate("/frameworks/fmf")}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <StatCard
              title="AHPs Assessed"
              value={stats?.practitionersCount || 0}
              icon={<BarChart />}
              trend="+5%"
              color="warning"
              subtitle={`${selectedYear}, ${selectedQuarter}`}
              onClick={() => navigate("/frameworks/ahmf")}
            />
          </Grid>
        </Grid>

        {/* Secondary Stats Section */}
        <Grid container spacing={4} sx={{ mb: 6 }}>
          <Grid item xs={12} md={4}>
            <StatCard
              title="No of Agrovets"
              value={stats?.agrovetsUtilityCount || 0}
              icon={<Store />}
              trend="+10%"
              color="primary"
              subtitle="Active Agrovets"
              onClick={() => navigate("/utility/agrovets")}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <StatCard
              title="No of Farriers"
              value={stats?.farriersUtilityCount || 0}
              icon={<Build />}
              trend="+7%"
              color="success"
              subtitle="Active Farriers"
              onClick={() => navigate("/utility/farriers")}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <StatCard
              title="No of AHPs"
              value={stats?.practitionersUtilityCount || 0}
              icon={<LocalHospital />}
              trend="+12%"
              color="info"
              subtitle="Active AHPs"
              onClick={() => navigate("/utility/practitioners")}
            />
          </Grid>
        </Grid>

        {/* Performance Section */}
        <Grid container spacing={4} sx={{ mb: 6 }}>
          <Grid item xs={12} md={8}>
            <PerformanceCard
              title="Assessment Performance"
              data={[
                { name: "Jan", value: 65 },
                { name: "Feb", value: 72 },
                { name: "Mar", value: 68 },
                { name: "Apr", value: 75 },
                { name: "May", value: 82 },
                { name: "Jun", value: 78 },
              ]}
              color="primary"
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <ActivityCard
              title="Recent Activities"
              activities={[
                {
                  title: "New Agrovet Monitored",
                  time: "2 hours ago",
                  icon: <Store />,
                  color: "warning",
                  status: "New",
                },
                {
                  title: "New Farrier Monitored",
                  time: "4 hours ago",
                  icon: <Build />,
                  color: "success",
                  status: "New",
                },
                {
                  title: "New Practitioner Monitored",
                  time: "6 hours ago",
                  icon: <LocalHospital />,
                  color: "info",
                  status: "New",
                },
                {
                  title: "Indicator Performance Updated",
                  time: "8 hours ago",
                  icon: <BarChart />,
                  color: "primary",
                  status: "Updated",
                },
              ]}
            />
          </Grid>
        </Grid>

        {/* Indicator Performance Section */}
        <Box sx={{ mb: 6 }}>
          <Typography
            variant="h5"
            sx={{
              fontWeight: 600,
              color: theme.palette.text.primary,
              mb: 3,
              textTransform: "uppercase",
              letterSpacing: 1,
            }}
          >
            Indicator Performance & Targets
          </Typography>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <Card
                sx={{
                  height: "100%",
                  backgroundColor: "#fff",
                  borderRadius: 2,
                  transition: "all 0.3s ease",
                  position: "relative",
                  overflow: "hidden",
                  border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
                }}
              >
                <Box sx={{ p: 3 }}>
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: 600,
                      color: theme.palette.text.primary,
                      mb: 3,
                    }}
                  >
                    Key Indicators Progress
                  </Typography>
                  <Stack spacing={3}>
                    {[
                      {
                        label: "Animal Health Services",
                        value: 75,
                        target: 80,
                        color: "primary",
                      },
                      {
                        label: "Farrier Competency",
                        value: 82,
                        target: 85,
                        color: "success",
                      },
                      {
                        label: "Community Engagement",
                        value: 68,
                        target: 75,
                        color: "warning",
                      },
                      {
                        label: "Training Completion",
                        value: 90,
                        target: 90,
                        color: "info",
                      },
                    ].map((indicator, index) => (
                      <Box key={index}>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            mb: 1,
                          }}
                        >
                          <Typography variant="body2" sx={{ fontWeight: 500 }}>
                            {indicator.label}
                          </Typography>
                          <Typography
                            variant="body2"
                            sx={{
                              color:
                                indicator.value >= indicator.target
                                  ? theme.palette.success.main
                                  : theme.palette.warning.main,
                              fontWeight: 600,
                            }}
                          >
                            {indicator.value}% / {indicator.target}%
                          </Typography>
                        </Box>
                        <LinearProgress
                          variant="determinate"
                          value={(indicator.value / indicator.target) * 100}
                          sx={{
                            height: 8,
                            borderRadius: 4,
                            bgcolor: alpha(
                              theme.palette[indicator.color].main,
                              0.1
                            ),
                            "& .MuiLinearProgress-bar": {
                              bgcolor: theme.palette[indicator.color].main,
                            },
                          }}
                        />
                      </Box>
                    ))}
                  </Stack>
                </Box>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card
                sx={{
                  height: "100%",
                  backgroundColor: "#fff",
                  borderRadius: 2,
                  transition: "all 0.3s ease",
                  position: "relative",
                  overflow: "hidden",
                  border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
                }}
              >
                <Box sx={{ p: 3 }}>
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: 600,
                      color: theme.palette.text.primary,
                      mb: 3,
                    }}
                  >
                    Target Achievement Summary
                  </Typography>
                  <ResponsiveContainer width="100%" height={300}>
                    <BarChart
                      data={[
                        {
                          name: "Animal Health",
                          achieved: 75,
                          target: 80,
                        },
                        {
                          name: "Farrier Services",
                          achieved: 82,
                          target: 85,
                        },
                        {
                          name: "Community Engagement",
                          achieved: 68,
                          target: 75,
                        },
                        {
                          name: "Training Programs",
                          achieved: 90,
                          target: 90,
                        },
                        {
                          name: "Equine Welfare",
                          achieved: 85,
                          target: 88,
                        },
                        {
                          name: "Service Coverage",
                          achieved: 78,
                          target: 82,
                        },
                      ]}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis
                        dataKey="name"
                        angle={-45}
                        textAnchor="end"
                        height={100}
                        interval={0}
                        tick={{ fontSize: 12 }}
                      />
                      <YAxis domain={[0, 100]} tick={{ fontSize: 12 }} />
                      <ChartTooltip
                        formatter={(value, name) => [`${value}%`, name]}
                        labelStyle={{ fontSize: 12 }}
                      />
                      <Legend />
                      <Bar
                        dataKey="achieved"
                        name="Achieved"
                        fill={theme.palette.primary.main}
                        radius={[4, 4, 0, 0]}
                      />
                      <Bar
                        dataKey="target"
                        name="Target"
                        fill={alpha(theme.palette.primary.main, 0.2)}
                        radius={[4, 4, 0, 0]}
                      />
                    </BarChart>
                  </ResponsiveContainer>
                </Box>
              </Card>
            </Grid>
          </Grid>
        </Box>

        {/* Quick Actions Section */}
        <Box sx={{ mb: 6 }}>
          <Typography
            variant="h5"
            sx={{
              fontWeight: 600,
              color: theme.palette.text.primary,
              mb: 3,
              textTransform: "uppercase",
              letterSpacing: 1,
            }}
          >
            Quick Actions
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={6} sm={4} md={3}>
              <QuickActionButton
                title="GIS"
                icon={<LocationOn />}
                path="/gis/maps"
                color="primary"
              />
            </Grid>
            <Grid item xs={6} sm={4} md={3}>
              <QuickActionButton
                title="MEL"
                icon={<Insights />}
                path="/mel/hierarchy"
                color="info"
              />
            </Grid>
            <Grid item xs={6} sm={4} md={3}>
              <QuickActionButton
                title="AMF"
                icon={<MonitorHeart />}
                path="/frameworks/amf"
                color="success"
              />
            </Grid>
            <Grid item xs={6} sm={4} md={3}>
              <QuickActionButton
                title="Agrovets"
                icon={<Store />}
                path="/utility/agrovets"
                color="warning"
              />
            </Grid>
          </Grid>
        </Box>
      </Container>

      {/* Timeframe Menu */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        PaperProps={{
          sx: {
            mt: 1.5,
            borderRadius: 2,
            minWidth: 200,
            boxShadow: "0 6px 24px rgba(0,0,0,0.15)",
          },
        }}
      >
        <Divider />
        {[2023, 2024, 2025].map((year) => (
          <MenuItem key={year} onClick={() => handleYearChange(year)}>
            <ListItemText>{year}</ListItemText>
          </MenuItem>
        ))}
        <Divider />
        {["Quarter 1", "Quarter 2", "Quarter 3", "Quarter 4"].map((quarter) => (
          <MenuItem key={quarter} onClick={() => handleQuarterChange(quarter)}>
            <ListItemText>{quarter}</ListItemText>
          </MenuItem>
        ))}
      </Menu>

      {/* Year Menu */}
      <Menu
        anchorEl={anchorElYear}
        open={Boolean(anchorElYear)}
        onClose={handleYearMenuClose}
        PaperProps={{
          sx: {
            mt: 1.5,
            borderRadius: 2,
            minWidth: 200,
            boxShadow: "0 6px 24px rgba(0,0,0,0.15)",
          },
        }}
      >
        {[2023, 2024, 2025].map((year) => (
          <MenuItem key={year} onClick={() => handleYearChange(year)}>
            <ListItemText>{year}</ListItemText>
          </MenuItem>
        ))}
      </Menu>

      {/* Quarter Menu */}
      <Menu
        anchorEl={anchorElQuarter}
        open={Boolean(anchorElQuarter)}
        onClose={handleQuarterMenuClose}
        PaperProps={{
          sx: {
            mt: 1.5,
            borderRadius: 2,
            minWidth: 200,
            boxShadow: "0 6px 24px rgba(0,0,0,0.15)",
          },
        }}
      >
        {["Quarter 1", "Quarter 2", "Quarter 3", "Quarter 4"].map((quarter) => (
          <MenuItem key={quarter} onClick={() => handleQuarterChange(quarter)}>
            <ListItemText>{quarter}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default Home;
