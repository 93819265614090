import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  TextField,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Grid,
  Paper,
  Button,
  Card,
  CardContent,
  Chip,
  InputAdornment,
  Divider,
  Stack,
  Tabs,
  Tab,
  Avatar,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  ListItemSecondaryAction,
  Menu,
  MenuItem,
  Tooltip,
  Collapse,
  Pagination,
  FormControl,
  Select,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import {
  Visibility as VisibilityIcon,
  Refresh,
  Search as SearchIcon,
  CalendarToday as CalendarIcon,
  LocationOn as LocationIcon,
  Person as PersonIcon,
  FilterList as FilterIcon,
  MoreVert as MoreVertIcon,
  Assessment as AssessmentIcon,
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
  Error as ErrorIcon,
  Download,
} from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import Results from "./Results";
import Competencies from "./Competencies";
import XLSX from "xlsx-js-style";

const Assessments = ({ partner, start, end, title = "AMF", onDataLoad }) => {
  // Helper functions first
  const getTool = () => {
    switch (title) {
      case "AMF":
        return "Agrovets";
      case "AHMF":
        return "Practitioners";
      case "FMF":
        return "Farriers";
      default:
        return "Agrovets";
    }
  };

  // Field group functions
  const getAMFFieldGroups = () => ({
    "Legal Compliance": [
      { label: "Education", field: "Q01_Education", isYesNo: true },
      {
        label: "Registered Practitioner",
        field: "Q02_RegAgrovetPractitioner",
        isYesNo: true,
      },
      {
        label: "Registered Premises",
        field: "Q03_RegAgrovetPremises",
        isYesNo: true,
      },
      {
        label: "Pest Control Registration",
        field: "Q04_RegPestControl",
        isYesNo: true,
      },
    ],
    "Pharmacological Expert": [
      {
        label: "Medicine Storage",
        field: "Q33_MedicineStorage",
        isYesNo: true,
      },
      {
        label: "Medicine Technique",
        field: "Medicine_Technique",
        isYesNo: true,
      },
      {
        label: "Dose Calculation",
        field: "Q21_DoseCalculation",
        isYesNo: true,
      },
      {
        label: "Administration Route",
        field: "Q22_RouteOfAdministration",
        isYesNo: true,
      },
    ],
    "Content of Agrovet": [
      {
        label: "Equipment & Disposables",
        field: "Q34_EquipmentDisposables",
        isYesNo: true,
      },
      {
        label: "Product Display",
        field: "Q35_ProductDisplay",
        isYesNo: true,
      },
      {
        label: "Available Products",
        field: "Available_Products",
        isYesNo: true,
      },
    ],
    "Agrovet Governance": [
      {
        label: "Record Keeping",
        field: "Q37_PrescriptionRecords",
        isYesNo: true,
      },
      {
        label: "Waste Management",
        field: "Q36_SharpsWasteManagements",
        isYesNo: true,
      },
      {
        label: "Professional Conduct",
        field: "Q15_GoodConduct",
        isYesNo: true,
      },
    ],
  });

  const getAHMFFieldGroups = () => ({
    "Legal Compliance": [
      {
        label: "Professional Registration",
        field: "ProfessionalReg",
        isYesNo: true,
      },
      { label: "License Status", field: "License", isYesNo: true },
      {
        label: "Proper Documentation",
        field: "Documentation",
        isYesNo: true,
      },
    ],
    "Clinical Expert": [
      { label: "History Taking", field: "HistoryTaking", isYesNo: true },
      {
        label: "Distance Observation",
        field: "DistanceObservation",
        isYesNo: true,
      },
      { label: "Diagnosis", field: "Diagnosis", isYesNo: true },
      { label: "Treatment", field: "Treatment", isYesNo: true },
      { label: "Pain Recognition", field: "PainRecognition", isYesNo: true },
    ],
    "Kit Content": [
      { label: "Kit Contents", field: "Kit_Contents", isYesNo: true },
      {
        label: "Equipment & Disposables",
        field: "Equipment_Disposables",
        isYesNo: true,
      },
      { label: "Medicine Storage", field: "MedicineStorage", isYesNo: true },
    ],
    "Clinical Governance": [
      { label: "Record Keeping", field: "RecordKeeping", isYesNo: true },
      { label: "Sharps Management", field: "Sharps", isYesNo: true },
      { label: "Cleanliness", field: "Cleanliness", isYesNo: true },
    ],
  });

  const getFMFFieldGroups = () => ({
    "Legal Compliance": [
      {
        label: "Professional Registration",
        field: "ProfessionalReg",
        isYesNo: true,
      },
      { label: "Safety Awareness", field: "Safety", isYesNo: true },
      { label: "Safe Working", field: "SafeWorking", isYesNo: true },
    ],
    "Farriery Expert": [
      {
        label: "Static Examination",
        field: "StaticExamination",
        isYesNo: true,
      },
      {
        label: "Dynamic Examination",
        field: "DynamicExamination",
        isYesNo: true,
      },
      { label: "Hoof Balance", field: "HoofBalance", isYesNo: true },
      { label: "Trimming", field: "Trimming", isYesNo: true },
      { label: "Shoeing", field: "Shoeing", isYesNo: true },
    ],
    "Farrier Kit": [
      { label: "Kit Tools", field: "KitTools", isYesNo: true },
      { label: "Kit Maintenance", field: "KitMaintenance", isYesNo: true },
      { label: "Kit Nails", field: "KitNails", isYesNo: true },
      { label: "Kit Shoes", field: "KitShoes", isYesNo: true },
    ],
    Governance: [
      { label: "Record Keeping", field: "RecordKeeping", isYesNo: true },
      {
        label: "Professional Conduct",
        field: "Professionalism",
        isYesNo: true,
      },
      { label: "Animal Welfare", field: "AnimalComfort", isYesNo: true },
    ],
  });

  // Then state declarations
  const [data, setData] = useState([]);
  const [pageSize, setPageSize] = useState(12);
  const [currentPage, setCurrentPage] = useState(1);
  const [filter, setFilter] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [totalRows, setTotalRows] = useState(0);
  const [selectedAssessment, setSelectedAssessment] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);

  // New state for tabs and menu
  const [activeTab, setActiveTab] = useState(0);
  const [detailsTab, setDetailsTab] = useState(0);

  // Add new state for filtered results and competencies
  const [filteredResults, setFilteredResults] = useState([]);
  const [filteredCompetencies, setFilteredCompetencies] = useState([]);

  // Add new state for result details dialog
  const [selectedResult, setSelectedResult] = useState(null);
  const [showResultDetails, setShowResultDetails] = useState(false);

  // Calculate pagination
  const totalPages = Math.ceil(totalRows / pageSize);
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;

  // New state for exporting
  const [isExporting, setIsExporting] = useState(false);

  // Function to fetch data from API
  const fetchData = async () => {
    if (!partner || !start || !end) return;

    setLoading(true);
    setError(null);
    try {
      const offset = (currentPage - 1) * pageSize;
      const tool = getTool().toLowerCase();
      const response = await fetch(
        `/api/${tool}-monitoring/all/${encodeURIComponent(
          partner
        )}/${encodeURIComponent(start)}/${encodeURIComponent(
          end
        )}?offset=${offset}&limit=${pageSize}`
      );

      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        throw new Error(errorData.message || "Failed to fetch data");
      }

      const result = await response.json();
      

      if (result?.data) {
        setData(Array.isArray(result.data) ? result.data : []);
        setTotalRows(
          result.total || (Array.isArray(result.data) ? result.data.length : 0)
        );
        if (onDataLoad) {
          onDataLoad(result.data);
        }
      } else {
        setError("No data available");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setError(error.message || "Failed to fetch data");
    } finally {
      setLoading(false);
    }
  };

  // Function to fetch filtered data for selected item
  const fetchFilteredData = async () => {
    if (!selectedAssessment) return;

    const nameField =
      title === "AMF"
        ? "Agrovet_Name"
        : title === "AHMF"
        ? "AHP_Name"
        : "FarrierName";
    const selectedName = selectedAssessment[nameField];
    const tool = getTool().toLowerCase();

    try {
      // Fetch Results data
      const resultsResponse = await fetch(
        `/api/${tool}-monitoring/results/${encodeURIComponent(
          partner
        )}/${encodeURIComponent(start)}/${encodeURIComponent(end)}`
      );

      if (!resultsResponse.ok) {
        const errorData = await resultsResponse.json().catch(() => ({}));
        console.error(
          "Error fetching results:",
          errorData.message || "Failed to fetch results"
        );
        return;
      }

      const resultsData = await resultsResponse.json();
      if (resultsData?.data) {
        const filtered = resultsData.data.filter(
          (item) => (item.Name || item[nameField]) === selectedName
        );
        setFilteredResults(filtered);
      }

      // Fetch Competencies data
      const competenciesResponse = await fetch(
        `/api/${tool}-monitoring/competency-distribution/${encodeURIComponent(
          partner
        )}/${encodeURIComponent(start)}/${encodeURIComponent(end)}`
      );

      if (!competenciesResponse.ok) {
        const errorData = await competenciesResponse.json().catch(() => ({}));
        console.error(
          "Error fetching competencies:",
          errorData.message || "Failed to fetch competencies"
        );
        return;
      }

      const competenciesData = await competenciesResponse.json();
      if (competenciesData?.data) {
        const filtered = competenciesData.data.filter((item) => {
          const itemName = item[nameField] || item.Name;
          return itemName === selectedName;
        });
        setFilteredCompetencies(filtered);
      }
    } catch (error) {
      console.error("Error fetching filtered data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [partner, start, end, currentPage, pageSize]);

  // Update filtered data when selected assessment changes
  useEffect(() => {
    if (selectedAssessment) {
      fetchFilteredData();
    }
  }, [selectedAssessment]);

  // Filter the data based on search text
  const filteredData = data.filter((row) => {
    if (!filter) return true; // If no filter, return all data

    const nameField =
      title === "AMF"
        ? "Agrovet_Name"
        : title === "AHMF"
        ? "AHP_Name"
        : "FarrierName";

    const searchText = filter.toLowerCase();
    const name = (row[nameField] || "").toLowerCase();
    const location = (row.Location || "").toLowerCase();
    const quarter = (row.Reporting_Quarter || row.Quarter || "").toLowerCase();

    return (
      name.includes(searchText) ||
      location.includes(searchText) ||
      quarter.includes(searchText)
    );
  });

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleMenuOpen = (event, item) => {
    setAnchorEl(event.currentTarget);
    setSelectedItem(item);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedItem(null);
  };

  const getStatusColor = (item) => {
    const complianceFields = [
      "Q01_Education",
      "Q02_RegAgrovetPractitioner",
      "Q03_RegAgrovetPremises",
      "Q04_RegPestControl",
    ];

    const passedChecks = complianceFields.filter(
      (field) => item[field] === "YES"
    ).length;
    const total = complianceFields.length;
    const percentage = (passedChecks / total) * 100;

    if (percentage >= 75) return "info";
    if (percentage >= 50) return "secondary";
    return "default";
  };

  // Enhanced assessment details rendering
  const renderAssessmentDetails = () => {
    if (!selectedAssessment) return null;

    const getBasicInfoFields = () => {
      switch (title) {
        case "AMF":
          return [
            { label: "Agrovet Name", field: "Agrovet_Name" },
            { label: "Location", field: "Location" },
            {
              label: "Assessment Date",
              field: "Assessment_Date",
              isDate: true,
            },
            { label: "Reporting Quarter", field: "Reporting_Quarter" },
            { label: "Reporting Year", field: "Reporting_Year" },
            { label: "Assessor", field: "Assessor" },
            { label: "Partner", field: "Partner" },
          ];
        case "AHMF":
          return [
            { label: "Practitioner Name", field: "AHP_Name" },
            { label: "Practitioner ID", field: "AHP_ID" },
            { label: "Type", field: "AhpType" },
            { label: "Location", field: "Location" },
            { label: "Assessment Number", field: "Assesment_No" },
            { label: "Date", field: "Date", isDate: true },
            { label: "Quarter", field: "Quarter" },
            { label: "Assessor", field: "Assessor" },
          ];
        case "FMF":
          return [
            { label: "Farrier Name", field: "FarrierName" },
            { label: "Farrier ID", field: "Farrier_ID" },
            { label: "Site", field: "ALC_Site" },
            { label: "Date", field: "Date", isDate: true },
            { label: "Quarter", field: "Quarter" },
            { label: "Assessor", field: "Assessor" },
          ];
        default:
          return [];
      }
    };

    return (
      <Box sx={{ height: "100%" }}>
        <Grid container spacing={2}>
          {/* Basic Information Section */}
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Typography
                  variant="h6"
                  sx={{
                    mb: 2,
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <AssessmentIcon color="primary" />
                  Basic Information
                </Typography>
                <List dense>
                  {getBasicInfoFields().map((field) => (
                    <ListItem
                      key={field.field}
                      sx={{
                        borderBottom: "1px solid",
                        borderColor: "divider",
                        py: 1,
                      }}
                    >
                      <ListItemText
                        primary={field.label}
                        primaryTypographyProps={{
                          variant: "body2",
                          color: "text.secondary",
                        }}
                        secondary={
                          <Typography variant="body1" sx={{ mt: 0.5 }}>
                            {field.isDate
                              ? new Date(
                                  selectedAssessment[field.field]
                                ).toLocaleDateString()
                              : selectedAssessment[field.field] || "-"}
                          </Typography>
                        }
                      />
                    </ListItem>
                  ))}
                </List>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    );
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    // Scroll to top when page changes
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
    setCurrentPage(1); // Reset to first page when changing page size
  };

  const handleDetailsTabChange = (event, newValue) => {
    setDetailsTab(newValue);
  };

  const exportToExcel = async () => {
    try {
      setIsExporting(true);

      // Fetch all data for export using query parameters
      const tool = getTool().toLowerCase();
      const response = await fetch(
        `/api/${tool}-monitoring/all/${encodeURIComponent(
          partner
        )}/${encodeURIComponent(start)}/${encodeURIComponent(
          end
        )}?offset=0&limit=1000`
      );

      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        throw new Error(
          errorData.message || `HTTP error! status: ${response.status}`
        );
      }

      const result = await response.json();

      if (!result || !result.data) {
        throw new Error("No data received from the server");
      }

      const allData = result.data;

      if (!Array.isArray(allData) || allData.length === 0) {
        throw new Error("No assessment data available for the selected period");
      }

      // Transform data for export by converting dates and handling empty values
      const exportData = allData.map(item => {
        const transformedItem = {};
        
        Object.entries(item).forEach(([key, value]) => {
          // Convert snake_case to Title Case for column headers
          const header = key.split('_')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
            .join(' ');

          // Handle date fields
          if (key.toLowerCase().includes('date')) {
            transformedItem[header] = value ? new Date(value).toLocaleDateString() : '';
          } else {
            transformedItem[header] = value || '';
          }
        });

        return transformedItem;
      });

      // Create workbook and worksheet
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet(exportData);

      // Set column widths
      const colWidths = exportData[0]
        ? Object.keys(exportData[0]).map(() => ({ wch: 20 }))
        : [];
      ws["!cols"] = colWidths;

      // Add the worksheet to the workbook
      XLSX.utils.book_append_sheet(wb, ws, "Assessments");

      // Generate filename with date
      const date = new Date().toISOString().split("T")[0];
      const filename = `${title}_Assessments_${date}.xlsx`;

      // Save the file
      XLSX.writeFile(wb, filename);
    } catch (error) {
      console.error("Export failed:", error);
      alert("Failed to export data. Please try again.");
    } finally {
      setIsExporting(false);
    }
  };

  const renderControls = () => (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        mb: 2,
      }}
    >
      <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
        <TextField
          placeholder="Search..."
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
          size="small"
          sx={{
            width: 200,
            "& .MuiOutlinedInput-root": {
              bgcolor: "background.paper",
              "& fieldset": { borderColor: "transparent" },
              "&:hover fieldset": { borderColor: "divider" },
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon fontSize="small" />
              </InputAdornment>
            ),
          }}
        />
        <Tooltip title="Refresh">
          <IconButton size="small" onClick={fetchData}>
            <Refresh fontSize="small" />
          </IconButton>
        </Tooltip>
        <Typography variant="body2" color="text.secondary" sx={{ ml: 1 }}>
          {filteredData.length} items
        </Typography>
      </Box>
      <Button
        variant="contained"
        color="primary"
        startIcon={
          isExporting ? (
            <CircularProgress size={16} color="inherit" />
          ) : (
            <Download sx={{ fontSize: "1.1rem" }} />
          )
        }
        onClick={exportToExcel}
        disabled={isExporting || !data.length}
        sx={{
          height: 32,
          px: 2,
          fontSize: "0.825rem",
          fontWeight: 500,
          boxShadow: "none",
          minWidth: 120,
          "&:hover": {
            boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
            transform: "translateY(-1px)",
          },
          transition: "all 0.2s ease",
        }}
      >
        {isExporting ? "Exporting..." : "Export Report"}
      </Button>
    </Box>
  );

  const renderMainContent = () => {
    if (loading) {
      return (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "50vh",
          }}
        >
          <CircularProgress />
        </Box>
      );
    }

    if (error) {
      return (
        <Card
          sx={{
            p: 3,
            borderLeft: 4,
            borderColor: "error.main",
            textAlign: "center",
          }}
        >
          <Typography color="error" variant="h6">
            {error}
          </Typography>
        </Card>
      );
    }

    return (
      <Box sx={{ position: "relative", pt: 5 }}>
        {renderControls()}

        <Grid container spacing={2}>
          {/* Tabs */}
          <Grid item xs={12}>
            <Tabs
              value={activeTab}
              onChange={handleTabChange}
              sx={{
                minHeight: 42,
                mb: 2,
                "& .MuiTab-root": {
                  minHeight: 42,
                  py: 0,
                },
              }}
            >
              <Tab label="Grid View" />
              <Tab label="List View" />
            </Tabs>
          </Grid>

          {/* Content */}
          <Grid item xs={12}>
            {activeTab === 1 ? (
              <List sx={{ bgcolor: "background.paper" }}>
                {filteredData.map((item) => (
                  <ListItem
                    key={item.ID}
                    sx={{
                      mb: 1,
                      borderRadius: 1,
                      border: "1px solid",
                      borderColor: "divider",
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar sx={{ bgcolor: "primary.main" }}>
                        {(
                          item.Agrovet_Name?.[0] ||
                          item.AHP_Name?.[0] ||
                          item.FarrierName?.[0] ||
                          "A"
                        ).toUpperCase()}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <Typography variant="subtitle1">
                          {item.Agrovet_Name ||
                            item.AHP_Name ||
                            item.FarrierName}
                        </Typography>
                      }
                      secondary={
                        <Stack direction="row" spacing={1} sx={{ mt: 0.5 }}>
                          <Chip
                            icon={<LocationIcon sx={{ fontSize: "0.75rem" }} />}
                            label={item.Location}
                            size="small"
                            variant="outlined"
                          />
                          <Chip
                            icon={<CalendarIcon sx={{ fontSize: "0.75rem" }} />}
                            label={`Date Monitored: ${new Date(
                              item.Assessment_Date || item.Date
                            ).toLocaleDateString("en-US", {
                              year: "numeric",
                              month: "short",
                              day: "numeric",
                            })}`}
                            size="small"
                            sx={{
                              "& .MuiChip-label": {
                                px: 1,
                                fontWeight: 500,
                              },
                              bgcolor: "grey.50",
                              border: "none",
                            }}
                          />
                          <Chip
                            icon={<PersonIcon sx={{ fontSize: "0.75rem" }} />}
                            label={`Monitored by: ${item.Assessor}`}
                            size="small"
                            sx={{
                              "& .MuiChip-label": {
                                px: 1,
                                fontWeight: 500,
                              },
                              bgcolor: "grey.50",
                              border: "none",
                            }}
                          />
                          <Chip
                            label={item.Reporting_Quarter || item.Quarter}
                            size="small"
                            color={getStatusColor(item)}
                            sx={{
                              fontWeight: 500,
                              bgcolor: (theme) => theme.palette.grey[100],
                              color: "text.primary",
                            }}
                          />
                        </Stack>
                      }
                    />
                    <ListItemSecondaryAction>
                      <Tooltip title="Actions">
                        <IconButton
                          edge="end"
                          onClick={(event) => handleMenuOpen(event, item)}
                        >
                          <MoreVertIcon />
                        </IconButton>
                      </Tooltip>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            ) : (
              <Grid container spacing={2}>
                {filteredData.map((item) => (
                  <Grid item xs={12} sm={6} md={4} key={item.ID}>
                    <Card
                      sx={{
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        transition: "all 0.2s ease-in-out",
                        "&:hover": {
                          transform: "translateY(-2px)",
                          boxShadow: (theme) => theme.shadows[4],
                        },
                      }}
                    >
                      <CardContent sx={{ p: 2.5, flex: 1 }}>
                        <Stack spacing={2}>
                          <Stack
                            direction="row"
                            spacing={2}
                            alignItems="center"
                          >
                            <Avatar
                              sx={{
                                bgcolor: "primary.main",
                                width: 48,
                                height: 48,
                                fontSize: "1.25rem",
                              }}
                            >
                              {(
                                item.Agrovet_Name?.[0] ||
                                item.AHP_Name?.[0] ||
                                item.FarrierName?.[0] ||
                                "A"
                              ).toUpperCase()}
                            </Avatar>
                            <Box sx={{ flex: 1, minWidth: 0 }}>
                              <Typography
                                variant="subtitle1"
                                noWrap
                                sx={{
                                  fontWeight: 600,
                                  color: "text.primary",
                                }}
                              >
                                {item.Agrovet_Name ||
                                  item.AHP_Name ||
                                  item.FarrierName}
                              </Typography>
                              <Stack
                                direction="row"
                                spacing={1}
                                alignItems="center"
                              >
                                <LocationIcon
                                  sx={{
                                    fontSize: "0.875rem",
                                    color: "text.secondary",
                                  }}
                                />
                                <Typography
                                  variant="body2"
                                  color="text.secondary"
                                  noWrap
                                >
                                  {item.Location}
                                </Typography>
                              </Stack>
                            </Box>
                            <IconButton
                              size="small"
                              onClick={(event) => handleMenuOpen(event, item)}
                              sx={{
                                color: "text.secondary",
                                "&:hover": {
                                  color: "primary.main",
                                  bgcolor: "primary.lighter",
                                },
                              }}
                            >
                              <MoreVertIcon />
                            </IconButton>
                          </Stack>
                          <Stack spacing={1.5}>
                            <Box>
                              <Chip
                                icon={
                                  <CalendarIcon sx={{ fontSize: "0.75rem" }} />
                                }
                                label={`Date Monitored: ${new Date(
                                  item.Assessment_Date || item.Date
                                ).toLocaleDateString("en-US", {
                                  year: "numeric",
                                  month: "short",
                                  day: "numeric",
                                })}`}
                                size="small"
                                sx={{
                                  width: "100%",
                                  justifyContent: "flex-start",
                                  "& .MuiChip-label": {
                                    px: 1,
                                    fontWeight: 500,
                                  },
                                  bgcolor: "grey.50",
                                  border: "none",
                                }}
                              />
                            </Box>
                            <Box>
                              <Chip
                                icon={
                                  <PersonIcon sx={{ fontSize: "0.75rem" }} />
                                }
                                label={`Monitored by: ${item.Assessor}`}
                                size="small"
                                sx={{
                                  width: "100%",
                                  justifyContent: "flex-start",
                                  "& .MuiChip-label": {
                                    px: 1,
                                    fontWeight: 500,
                                  },
                                  bgcolor: "grey.50",
                                  border: "none",
                                }}
                              />
                            </Box>
                            <Box sx={{ mt: "auto" }}>
                              <Chip
                                label={item.Reporting_Quarter || item.Quarter}
                                size="small"
                                color={getStatusColor(item)}
                                sx={{
                                  width: "100%",
                                  justifyContent: "center",
                                  fontWeight: 500,
                                  bgcolor: (theme) => theme.palette.grey[100],
                                  color: "text.primary",
                                }}
                              />
                            </Box>
                          </Stack>
                        </Stack>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            )}
          </Grid>

          {/* Pagination Controls */}
          <Grid item xs={12}>
            <Stack
              direction="row"
              spacing={2}
              alignItems="center"
              justifyContent="center"
              sx={{ mt: 3, mb: 2 }}
            >
              <FormControl size="small" variant="outlined">
                <Select
                  value={pageSize}
                  onChange={handlePageSizeChange}
                  sx={{ height: 32 }}
                >
                  <MenuItem value={12}>12 per page</MenuItem>
                  <MenuItem value={24}>24 per page</MenuItem>
                  <MenuItem value={48}>48 per page</MenuItem>
                </Select>
              </FormControl>
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography variant="body2" color="text.secondary">
                  {`${startIndex + 1}-${Math.min(
                    endIndex,
                    totalRows
                  )} of ${totalRows}`}
                </Typography>
                <Pagination
                  count={totalPages}
                  page={currentPage}
                  onChange={handlePageChange}
                  color="primary"
                  size="medium"
                  showFirstButton
                  showLastButton
                />
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    );
  };

  return (
    <Box sx={{ height: "100%", width: "100%" }}>
      {renderMainContent()}

      {/* Actions Menu */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem
          onClick={() => {
            setSelectedAssessment(selectedItem);
            setOpenDialog(true);
            handleMenuClose();
          }}
        >
          <ListItemText primary="View Details" />
        </MenuItem>
      </Menu>

      {/* Details Dialog */}
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        maxWidth="lg"
        fullWidth
        PaperProps={{
          elevation: 0,
          sx: {
            borderRadius: 2,
            overflow: "hidden",
            "& .MuiDialogContent-root": {
              p: 0,
              "&:first-of-type": {
                pt: 0,
              },
            },
          },
        }}
      >
        <DialogTitle
          sx={{
            p: 3,
            pb: 2,
            borderBottom: "1px solid",
            borderColor: "divider",
            bgcolor: "background.paper",
            position: "sticky",
            top: 0,
            zIndex: 1,
            boxShadow: "0 1px 2px rgba(0,0,0,0.05)",
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            spacing={2}
          >
            <Stack direction="row" spacing={2} alignItems="center">
              <Avatar
                sx={{
                  width: 48,
                  height: 48,
                  bgcolor: "primary.main",
                  fontSize: "1.25rem",
                  boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                }}
              >
                {(
                  selectedAssessment?.Agrovet_Name?.[0] ||
                  selectedAssessment?.AHP_Name?.[0] ||
                  selectedAssessment?.FarrierName?.[0] ||
                  "A"
                ).toUpperCase()}
              </Avatar>
              <Box>
                <Typography
                  variant="h5"
                  sx={{ fontWeight: 600, color: "text.primary" }}
                >
                  {selectedAssessment
                    ? title === "AMF"
                      ? selectedAssessment.Agrovet_Name
                      : title === "AHMF"
                      ? selectedAssessment.AHP_Name
                      : selectedAssessment.FarrierName
                    : "Assessment Details"}
                </Typography>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Chip
                    icon={<LocationIcon sx={{ fontSize: "0.75rem" }} />}
                    label={
                      selectedAssessment?.Location || "Location not specified"
                    }
                    size="small"
                    sx={{
                      bgcolor: "grey.50",
                      border: "none",
                      "& .MuiChip-label": {
                        px: 1,
                        fontWeight: 500,
                      },
                    }}
                  />
                  <Chip
                    icon={<CalendarIcon sx={{ fontSize: "0.75rem" }} />}
                    label={`Date: ${new Date(
                      selectedAssessment?.Assessment_Date ||
                        selectedAssessment?.Date
                    ).toLocaleDateString("en-US", {
                      year: "numeric",
                      month: "short",
                      day: "numeric",
                    })}`}
                    size="small"
                    sx={{
                      bgcolor: "grey.50",
                      border: "none",
                      "& .MuiChip-label": {
                        px: 1,
                        fontWeight: 500,
                      },
                    }}
                  />
                  <Chip
                    icon={<PersonIcon sx={{ fontSize: "0.75rem" }} />}
                    label={`Assessor: ${selectedAssessment?.Assessor}`}
                    size="small"
                    sx={{
                      bgcolor: "grey.50",
                      border: "none",
                      "& .MuiChip-label": {
                        px: 1,
                        fontWeight: 500,
                      },
                    }}
                  />
                </Stack>
              </Box>
            </Stack>
            <IconButton
              onClick={() => setOpenDialog(false)}
              sx={{
                color: "text.secondary",
                "&:hover": {
                  bgcolor: "action.hover",
                  color: "text.primary",
                },
              }}
            >
              <CloseIcon />
            </IconButton>
          </Stack>
        </DialogTitle>
        <Box
          sx={{
            borderBottom: "1px solid",
            borderColor: "divider",
            bgcolor: "background.paper",
            position: "sticky",
            top: 64,
            zIndex: 1,
            boxShadow: "0 1px 2px rgba(0,0,0,0.05)",
          }}
        >
          <Tabs
            value={detailsTab}
            onChange={handleDetailsTabChange}
            sx={{
              px: 3,
              "& .MuiTab-root": {
                textTransform: "none",
                fontWeight: 500,
                minHeight: 48,
                color: "text.secondary",
                "&.Mui-selected": {
                  color: "primary.main",
                  fontWeight: 600,
                },
              },
            }}
          >
            <Tab
              label="Assessment Details"
              icon={<AssessmentIcon sx={{ mb: 0.5 }} />}
              iconPosition="top"
            />
            <Tab
              label="Results"
              icon={<CheckCircleIcon sx={{ mb: 0.5 }} />}
              iconPosition="top"
            />
            <Tab
              label="Competencies"
              icon={<AssessmentIcon sx={{ mb: 0.5 }} />}
              iconPosition="top"
            />
          </Tabs>
        </Box>
        <DialogContent
          sx={{
            p: 3,
            height: "calc(100vh - 200px)",
            overflow: "auto",
            bgcolor: "grey.50",
          }}
        >
          {detailsTab === 0 && (
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
                gap: 3,
                p: 1,
              }}
            >
              {renderAssessmentDetails()}
            </Box>
          )}
          {detailsTab === 1 && (
            <Box
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                gap: 3,
              }}
            >
              {/* Results Grid */}
              <Box
                sx={{
                  flex: 1,
                  bgcolor: "background.paper",
                  borderRadius: 1,
                  overflow: "hidden",
                  boxShadow: "0 1px 3px rgba(0,0,0,0.05)",
                }}
              >
                <Results
                  partner={partner}
                  start={start}
                  end={end}
                  title={title}
                  data={filteredResults}
                  hideSearch={true}
                  onRowClick={(row) => {
                    setSelectedResult(row);
                    setShowResultDetails(true);
                  }}
                />
              </Box>

              {/* Result Details Dialog */}
              <Dialog
                open={showResultDetails}
                onClose={() => setShowResultDetails(false)}
                maxWidth="lg"
                fullWidth
                PaperProps={{
                  elevation: 0,
                  sx: {
                    borderRadius: 2,
                    overflow: "hidden",
                    "& .MuiDialogContent-root": {
                      p: 0,
                      "&:first-of-type": {
                        pt: 0,
                      },
                    },
                  },
                }}
              >
                <DialogTitle
                  sx={{
                    p: 3,
                    pb: 2,
                    borderBottom: "1px solid",
                    borderColor: "divider",
                    bgcolor: "background.paper",
                    position: "sticky",
                    top: 0,
                    zIndex: 1,
                    boxShadow: "0 1px 2px rgba(0,0,0,0.05)",
                  }}
                >
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    spacing={2}
                  >
                    <Stack direction="row" spacing={2} alignItems="center">
                      <Avatar
                        sx={{
                          width: 48,
                          height: 48,
                          bgcolor: "primary.main",
                          fontSize: "1.25rem",
                          boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                        }}
                      >
                        {(
                          selectedResult?.Agrovet_Name?.[0] ||
                          selectedResult?.AHP_Name?.[0] ||
                          selectedResult?.FarrierName?.[0] ||
                          "A"
                        ).toUpperCase()}
                      </Avatar>
                      <Box>
                        <Typography
                          variant="h5"
                          sx={{ fontWeight: 600, color: "text.primary" }}
                        >
                          {selectedResult
                            ? title === "AMF"
                              ? selectedResult.Agrovet_Name
                              : title === "AHMF"
                              ? selectedResult.AHP_Name
                              : selectedResult.FarrierName
                            : "Result Details"}
                        </Typography>
                        <Stack direction="row" spacing={1} alignItems="center">
                          <Chip
                            icon={<LocationIcon sx={{ fontSize: "0.75rem" }} />}
                            label={
                              selectedResult?.Location ||
                              "Location not specified"
                            }
                            size="small"
                            sx={{
                              bgcolor: "grey.50",
                              border: "none",
                              "& .MuiChip-label": {
                                px: 1,
                                fontWeight: 500,
                              },
                            }}
                          />
                          <Chip
                            icon={<CalendarIcon sx={{ fontSize: "0.75rem" }} />}
                            label={`Date: ${new Date(
                              selectedResult?.Assessment_Date ||
                                selectedResult?.Date
                            ).toLocaleDateString("en-US", {
                              year: "numeric",
                              month: "short",
                              day: "numeric",
                            })}`}
                            size="small"
                            sx={{
                              bgcolor: "grey.50",
                              border: "none",
                              "& .MuiChip-label": {
                                px: 1,
                                fontWeight: 500,
                              },
                            }}
                          />
                          <Chip
                            icon={<PersonIcon sx={{ fontSize: "0.75rem" }} />}
                            label={`Assessor: ${selectedResult?.Assessor}`}
                            size="small"
                            sx={{
                              bgcolor: "grey.50",
                              border: "none",
                              "& .MuiChip-label": {
                                px: 1,
                                fontWeight: 500,
                              },
                            }}
                          />
                        </Stack>
                      </Box>
                    </Stack>
                    <IconButton
                      onClick={() => setShowResultDetails(false)}
                      sx={{
                        color: "text.secondary",
                        "&:hover": {
                          bgcolor: "action.hover",
                          color: "text.primary",
                        },
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Stack>
                </DialogTitle>
                <DialogContent
                  sx={{
                    p: 3,
                    height: "calc(100vh - 200px)",
                    overflow: "auto",
                    bgcolor: "grey.50",
                  }}
                >
                  {/* Summary Cards */}
                  {selectedResult && (
                    <>
                      {/* Total Score Card */}
                      <Card
                        sx={{
                          mb: 3,
                          bgcolor: "primary.lighter",
                          position: "relative",
                          overflow: "hidden",
                          "&::before": {
                            content: '""',
                            position: "absolute",
                            top: 0,
                            left: 0,
                            right: 0,
                            height: "4px",
                            background:
                              "linear-gradient(90deg, #1976d2, #64b5f6)",
                          },
                        }}
                      >
                        <CardContent>
                          <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <Box>
                              <Typography
                                variant="h6"
                                sx={{
                                  fontWeight: 600,
                                  color: "primary.main",
                                  mb: 0.5,
                                }}
                              >
                                Total Score
                              </Typography>
                              <Typography
                                variant="body2"
                                color="text.secondary"
                              >
                                Overall assessment performance
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 1,
                              }}
                            >
                              <Typography
                                variant="h4"
                                sx={{
                                  fontWeight: 700,
                                  color: "primary.main",
                                  lineHeight: 1,
                                }}
                              >
                                {Object.entries(
                                  title === "FMF"
                                    ? getFMFFieldGroups()
                                    : title === "AHMF"
                                    ? getAHMFFieldGroups()
                                    : getAMFFieldGroups()
                                ).reduce((total, [_, fields]) => {
                                  return (
                                    total +
                                    fields.reduce((acc, field) => {
                                      const value = selectedResult[field.field];
                                      if (value?.toUpperCase() === "YES")
                                        return acc + 1;
                                      if (value?.toUpperCase() === "CRITICAL")
                                        return acc - 1;
                                      return acc;
                                    }, 0)
                                  );
                                }, 0)}
                              </Typography>
                              <Typography
                                variant="body2"
                                color="text.secondary"
                                sx={{ fontWeight: 500 }}
                              >
                                points
                              </Typography>
                            </Box>
                          </Stack>
                        </CardContent>
                      </Card>

                      <Grid container spacing={2}>
                        {Object.entries(
                          title === "FMF"
                            ? getFMFFieldGroups()
                            : title === "AHMF"
                            ? getAHMFFieldGroups()
                            : getAMFFieldGroups()
                        ).map(([groupName, fields]) => {
                          // Calculate score for this group
                          const score = fields.reduce((acc, field) => {
                            const value = selectedResult[field.field];
                            if (value?.toUpperCase() === "YES") return acc + 1;
                            if (value?.toUpperCase() === "CRITICAL")
                              return acc - 1;
                            return acc;
                          }, 0);

                          return (
                            <Grid item xs={12} md={6} key={groupName}>
                              <Card
                                sx={{
                                  height: "100%",
                                  transition: "all 0.2s ease-in-out",
                                  "&:hover": {
                                    transform: "translateY(-2px)",
                                    boxShadow: (theme) => theme.shadows[4],
                                  },
                                }}
                              >
                                <CardContent>
                                  <Stack
                                    direction="row"
                                    alignItems="center"
                                    justifyContent="space-between"
                                    sx={{ mb: 2 }}
                                  >
                                    <Typography
                                      variant="h6"
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: 1,
                                        fontWeight: 600,
                                      }}
                                    >
                                      <AssessmentIcon color="primary" />
                                      {groupName}
                                    </Typography>
                                    <Chip
                                      label={`${score} points`}
                                      color="primary"
                                      size="small"
                                      sx={{
                                        fontWeight: 600,
                                        bgcolor: "primary.lighter",
                                        color: "primary.main",
                                      }}
                                    />
                                  </Stack>
                                  <List dense>
                                    {fields.map((field) => (
                                      <ListItem
                                        key={field.field}
                                        sx={{
                                          borderBottom: "1px solid",
                                          borderColor: "divider",
                                          py: 1,
                                          "&:last-child": {
                                            borderBottom: "none",
                                          },
                                        }}
                                      >
                                        <ListItemText
                                          primary={
                                            <Typography
                                              variant="body2"
                                              sx={{ fontWeight: 500 }}
                                            >
                                              {field.label}
                                            </Typography>
                                          }
                                          secondary={
                                            field.isYesNo ? (
                                              <Chip
                                                icon={
                                                  selectedResult[
                                                    field.field
                                                  ]?.toUpperCase() === "YES" ? (
                                                    <CheckCircleIcon fontSize="small" />
                                                  ) : selectedResult[
                                                      field.field
                                                    ]?.toUpperCase() ===
                                                    "CRITICAL" ? (
                                                    <ErrorIcon fontSize="small" />
                                                  ) : (
                                                    <CancelIcon fontSize="small" />
                                                  )
                                                }
                                                label={
                                                  selectedResult[
                                                    field.field
                                                  ]?.toUpperCase() === "YES"
                                                    ? "Yes"
                                                    : selectedResult[
                                                        field.field
                                                      ]?.toUpperCase() ===
                                                      "CRITICAL"
                                                    ? "Critical"
                                                    : "No"
                                                }
                                                size="small"
                                                color={
                                                  selectedResult[
                                                    field.field
                                                  ]?.toUpperCase() === "YES"
                                                    ? "success"
                                                    : selectedResult[
                                                        field.field
                                                      ]?.toUpperCase() ===
                                                      "CRITICAL"
                                                    ? "error"
                                                    : "default"
                                                }
                                                sx={{
                                                  mt: 0.5,
                                                  "& .MuiChip-label": {
                                                    px: 1,
                                                    fontWeight: 500,
                                                  },
                                                }}
                                              />
                                            ) : (
                                              <Typography
                                                variant="body1"
                                                sx={{ mt: 0.5 }}
                                              >
                                                {field.isDate
                                                  ? new Date(
                                                      selectedResult[
                                                        field.field
                                                      ]
                                                    ).toLocaleDateString()
                                                  : selectedResult[
                                                      field.field
                                                    ] || "-"}
                                              </Typography>
                                            )
                                          }
                                        />
                                      </ListItem>
                                    ))}
                                  </List>
                                </CardContent>
                              </Card>
                            </Grid>
                          );
                        })}
                      </Grid>
                    </>
                  )}
                </DialogContent>
              </Dialog>
            </Box>
          )}
          {detailsTab === 2 && (
            <Box
              sx={{
                height: "100%",
                bgcolor: "background.paper",
                borderRadius: 1,
                overflow: "hidden",
                boxShadow: "0 1px 3px rgba(0,0,0,0.05)",
              }}
            >
              <Competencies
                partner={partner}
                start={start}
                end={end}
                title={title}
                data={filteredCompetencies}
                hideSearch={true}
              />
            </Box>
          )}
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default Assessments;
