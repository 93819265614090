import React, { cloneElement, Fragment, useEffect, useState } from "react";
import {
  Home as HomeIcon,
  Assessment,
  Pets,
  LocationOn,
  People,
  Settings,
  BarChart,
  Assignment,
  Business,
  LocalHospital,
  Store,
  Groups,
  School,
  Build,
  ExpandLess,
  ExpandMore,
  ChevronLeft,
  ChevronRight,
  Dashboard,
  MonitorHeart,
  Analytics,
  Insights,
  DataUsage,
  Timeline,
  AccountTree,
  Logout as LogoutIcon,
  PersonAdd as PersonAddIcon,
  AccountCircle as ProfileIcon,
  Menu as MenuIcon,
  Pets as HorseIcon,
  Storefront as AgrovetIcon,
  LocalPharmacy as AgrovetMonitorIcon,
  Pets as FarrierMonitorIcon,
  LocalHospital as PractitionerMonitorIcon,
} from "@mui/icons-material";
import { Money } from "@phosphor-icons/react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Box, Toolbar, AppBar, Drawer, Typography, alpha } from "@mui/material";
import Header from "./Header/Header";
import BarChartIcon from "@mui/icons-material/BarChart";
import logo from "../assets/imgs/logo.png";

const drawerWidth = 240;

const Navbar = ({ user, setUser, children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const [open, setOpen] = useState(true);
  const [openSections, setOpenSections] = useState({
    customerService: false,
    riskManagement: false,
    debtCollection: false,
  });
  const [menuItems, setMenuItems] = useState([]);

  const handleDrawerOpen = () => setOpen(true);
  const handleDrawerClose = () => setOpen(false);

  const handleToggle = (section) => {
    setOpenSections((prevState) => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  };

  const logout = () => {
    fetch("/api/auth/logout", {
      method: "GET",
      credentials: "include",
    })
      .then((res) => {
        if (res.ok) return res.json();
        else throw new Error();
      })
      .then(() => {
        localStorage.clear();
        window.location.href = "/";
      })
      .catch((error) => {
        // Still clear and redirect on error
        localStorage.clear();
        window.location.href = "/";
      });
  };

  const adminItems = [
    { text: "Home", icon: <HomeIcon />, path: "/home" },
    {
      text: "Frameworks",
      icon: <Assessment />,
      subItems: [
        {
          text: "Agrovets Monitoring",
          path: "/frameworks/amf",
          icon: <AgrovetMonitorIcon />,
        },
        {
          text: "Farriers Monitoring",
          path: "/frameworks/fmf",
          icon: <HorseIcon />,
        },
        {
          text: "Animal Health",
          path: "/frameworks/ahmf",
          icon: <PractitionerMonitorIcon />,
        },
      ],
    },
    {
      text: "MEL",
      icon: <Insights />,
      subItems: [
        {
          text: "Hierarchy",
          path: "/mel/hierarchy",
          icon: <AccountTree />,
        },

        ...(user?.Partner === "Brooke East Africa"
          ? [
              {
                text: "Partner Overview",
                path: "/partner-overview",
                icon: <BarChartIcon />,
              },
            ]
          : []),
      ],
    },
    {
      text: "GIS",
      icon: <LocationOn />,
      subItems: [
        {
          text: "Maps",
          path: "/gis/maps",
          icon: <LocationOn />,
        },
        {
          text: "New Map",
          path: "/gis/new",
          icon: <Assignment />,
        },
      ],
    },
    {
      text: "Utility Data",
      icon: <DataUsage />,
      subItems: [
        {
          text: "Agrovets",
          path: "/utility/agrovets",
          icon: <AgrovetIcon />,
        },
        {
          text: "Farriers",
          path: "/utility/farriers",
          icon: <HorseIcon />,
        },
        {
          text: "Practitioners",
          path: "/utility/practitioners",
          icon: <LocalHospital />,
        },
      ],
    },
    { text: "Partners", icon: <Business />, path: "/partners" },
  ];

  useEffect(() => {
    if (user) {
      setMenuItems(adminItems);
    }
  }, [user]);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          marginLeft: open ? drawerWidth : 64,
          width: `calc(100% - ${open ? drawerWidth : 64}px)`,
          transition: theme.transitions.create(["margin", "width"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
          zIndex: theme.zIndex.drawer + 1,
          backgroundColor: "#fff",
          boxShadow: "0 1px 3px rgba(0,0,0,0.05)",
          backdropFilter: "blur(8px)",
        }}
      >
        <Toolbar>
          <Header
            setUser={setUser}
            handleDrawerOpen={handleDrawerOpen}
            open={open}
          />
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        sx={{
          width: open ? drawerWidth : 64,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: open ? drawerWidth : 64,
            boxSizing: "border-box",
            transition: theme.transitions.create("width", {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.enteringScreen,
            }),
          },
        }}
        open={open}
      >
        <Box
          sx={{
            p: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {open && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
                overflow: "hidden",
              }}
            >
              <img
                src={logo}
                alt="Logo"
                style={{
                  height: 32,
                  width: "auto",
                  objectFit: "contain",
                }}
              />
              <Typography
                variant="h6"
                noWrap
                sx={{
                  fontWeight: 600,
                  color: "primary.main",
                }}
              >
                Brooke
              </Typography>
            </Box>
          )}
          <IconButton
            onClick={handleDrawerClose}
            sx={{
              color: "text.secondary",
              "&:hover": {
                bgcolor: (theme) => alpha(theme.palette.primary.main, 0.04),
                color: "primary.main",
              },
            }}
          >
            {theme.direction === "rtl" ? <ChevronRight /> : <ChevronLeft />}
          </IconButton>
        </Box>
        <Divider />
        <List
          sx={{
            px: 1,
            "& .MuiListItem-root": {
              borderRadius: 1,
              mb: 0.5,
              "&:hover": {
                bgcolor: (theme) => alpha(theme.palette.primary.main, 0.04),
              },
            },
          }}
        >
          {menuItems.map((item) => (
            <Fragment key={item.text}>
              {item.subItems ? (
                <>
                  <ListItem
                    button
                    onClick={() => handleToggle(item.text)}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                        color:
                          location.pathname === item.path
                            ? theme.palette.primary.main
                            : "inherit",
                        "& .MuiSvgIcon-root": {
                          fontSize: "1.3rem",
                          transition: "all 0.2s ease",
                        },
                      }}
                    >
                      {item.icon}
                    </ListItemIcon>
                    {open && (
                      <>
                        <ListItemText
                          primary={item.text}
                          sx={{
                            opacity: open ? 1 : 0,
                            transition: "opacity 0.2s ease",
                            "& .MuiTypography-root": {
                              fontSize: "0.875rem",
                              fontWeight:
                                location.pathname === item.path ? 600 : 400,
                            },
                          }}
                        />
                        {openSections[item.text] ? (
                          <ExpandLess />
                        ) : (
                          <ExpandMore />
                        )}
                      </>
                    )}
                  </ListItem>
                  {open && openSections[item.text] && (
                    <List component="div" disablePadding>
                      {item.subItems.map((subItem) => (
                        <ListItem
                          key={subItem.text}
                          button
                          onClick={() => navigate(subItem.path)}
                          selected={location.pathname === subItem.path}
                          sx={{
                            pl: 4,
                            minHeight: 40,
                            "&.Mui-selected": {
                              bgcolor: (theme) =>
                                alpha(theme.palette.primary.main, 0.08),
                            },
                          }}
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: 0,
                              mr: 3,
                              justifyContent: "center",
                              color:
                                location.pathname === subItem.path
                                  ? theme.palette.primary.main
                                  : "inherit",
                              "& .MuiSvgIcon-root": {
                                fontSize: "1.2rem",
                                transition: "all 0.2s ease",
                              },
                            }}
                          >
                            {subItem.icon}
                          </ListItemIcon>
                          <ListItemText
                            primary={subItem.text}
                            sx={{
                              "& .MuiTypography-root": {
                                fontSize: "0.75rem",
                                fontWeight:
                                  location.pathname === subItem.path
                                    ? 600
                                    : 400,
                              },
                            }}
                          />
                        </ListItem>
                      ))}
                    </List>
                  )}
                </>
              ) : (
                <ListItem
                  button
                  onClick={() => navigate(item.path)}
                  selected={location.pathname === item.path}
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    "&.Mui-selected": {
                      bgcolor: (theme) =>
                        alpha(theme.palette.primary.main, 0.08),
                    },
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                      color:
                        location.pathname === item.path
                          ? theme.palette.primary.main
                          : "inherit",
                      "& .MuiSvgIcon-root": {
                        fontSize: "1.3rem",
                        transition: "all 0.2s ease",
                      },
                    }}
                  >
                    {item.icon}
                  </ListItemIcon>
                  {open && (
                    <ListItemText
                      primary={item.text}
                      sx={{
                        opacity: open ? 1 : 0,
                        transition: "opacity 0.2s ease",
                        "& .MuiTypography-root": {
                          fontSize: "0.875rem",
                          fontWeight:
                            location.pathname === item.path ? 600 : 400,
                        },
                      }}
                    />
                  )}
                </ListItem>
              )}
            </Fragment>
          ))}
        </List>
        <Divider />
        <List
          sx={{
            px: 1,
            "& .MuiListItem-root": {
              borderRadius: 1,
              mb: 0.5,
              "&:hover": {
                bgcolor: (theme) => alpha(theme.palette.primary.main, 0.04),
              },
            },
          }}
        >
          {user && user.Role === "Admin" && (
            <ListItem
              button
              onClick={() => navigate("/users")}
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                  "& .MuiSvgIcon-root": {
                    fontSize: "1.3rem",
                    transition: "all 0.2s ease",
                  },
                }}
              >
                <PersonAddIcon />
              </ListItemIcon>
              {open && (
                <ListItemText
                  primary="Users"
                  sx={{
                    opacity: open ? 1 : 0,
                    transition: "opacity 0.2s ease",
                    "& .MuiTypography-root": {
                      fontSize: "0.875rem",
                    },
                  }}
                />
              )}
            </ListItem>
          )}

          <ListItem
            button
            onClick={() => navigate("/profile")}
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : "auto",
                justifyContent: "center",
                "& .MuiSvgIcon-root": {
                  fontSize: "1.3rem",
                  transition: "all 0.2s ease",
                },
              }}
            >
              <ProfileIcon />
            </ListItemIcon>
            {open && (
              <ListItemText
                primary="Profile"
                sx={{
                  opacity: open ? 1 : 0,
                  transition: "opacity 0.2s ease",
                  "& .MuiTypography-root": {
                    fontSize: "0.875rem",
                  },
                }}
              />
            )}
          </ListItem>

          <ListItem
            button
            onClick={logout}
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : "auto",
                justifyContent: "center",
                "& .MuiSvgIcon-root": {
                  fontSize: "1.3rem",
                  transition: "all 0.2s ease",
                },
              }}
            >
              <LogoutIcon />
            </ListItemIcon>
            {open && (
              <ListItemText
                primary="Logout"
                sx={{
                  opacity: open ? 1 : 0,
                  transition: "opacity 0.2s ease",
                  "& .MuiTypography-root": {
                    fontSize: "0.875rem",
                  },
                }}
              />
            )}
          </ListItem>
        </List>
      </Drawer>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          pt: 10,
          minHeight: "100vh",
          bgcolor: (theme) => alpha(theme.palette.background.default, 0.8),
          backdropFilter: "blur(8px)",
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default Navbar;
