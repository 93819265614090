import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  Box,
  CircularProgress,
  Typography,
  IconButton,
  Tooltip,
  TextField,
  InputAdornment,
} from "@mui/material";
import { TrendingUp, Search } from "@mui/icons-material";
import AgrovetTrendDialog from "./TrendDialogs/AgrovetTrendDialog";
import PractitionerTrendDialog from "./TrendDialogs/PractitionerTrendDialog";
import FarrierTrendDialog from "./TrendDialogs/FarrierTrendDialog";

const Competencies = ({
  title,
  partner,
  start,
  end,
  data,
  hideSearch = false,
}) => {
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [trendDialogOpen, setTrendDialogOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [localData, setLocalData] = useState([]);

  // Helper function to get field names based on framework type
  const getFieldNames = () => {
    switch (title) {
      case "AMF":
        return {
          name: "Agrovet_Name",
          quarter: "Reporting_Quarter",
          date: "Assessment_Date",
        };
      case "AHMF":
        return {
          name: "Name",
          quarter: "Quarter",
          date: "Date",
        };
      case "FMF":
        return {
          name: "Name",
          quarter: "Quarter",
          date: "Date",
        };
      default:
        return {
          name: "Name",
          quarter: "Quarter",
          date: "Date",
        };
    }
  };

  // Use provided data or fetch from API
  useEffect(() => {
    if (data) {
      setLocalData(data);
      return;
    }

    const fetchCompetencyData = async () => {
      if (!partner || !start || !end) return;

      setLoading(true);
      try {
        const controller = new AbortController();
        const timeoutId = setTimeout(() => controller.abort(), 30000);

        const endpoint =
          title === "AHMF"
            ? `/api/data/practitioners/competency/data/${partner}/${start}/${end}`
            : title === "FMF"
            ? `/api/data/farriers/competency/data/${partner}/${start}/${end}`
            : `/api/data/agrovets/competency/data/${partner}/${start}/${end}`;

        const response = await fetch(endpoint, {
          signal: controller.signal,
        });

        clearTimeout(timeoutId);

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const result = await response.json();
        console.log("AHMF API Response:", result);

        if (result && result.data) {
          const fieldNames = getFieldNames();
          console.log("AHMF Field Names:", fieldNames);
          const transformedData = result.data.map((item) => {
            if (title === "AMF") {
              return {
                id: item.ID,
                ID: item.ID,
                Name: item[fieldNames.name],
                Quarter: item[fieldNames.quarter],
                Date: item[fieldNames.date],
                "Legal Compliance": item["Legal Compliance"],
                Communicator: item.Communicator,
                "Pharmacological Expert": item["Pharmacological Expert"],
                "Content of Agrovet": item["Content of Agrovet"],
                "Agrovet Governance": item["Agrovet Governance"],
              };
            }

            if (title === "FMF") {
              return {
                id: item.ID,
                ID: item.ID,
                Name: item.Name,
                Quarter: item.Quarter,
                ALC_Site: item.ALC_Site,
                Date: item.Date,
                "Legal Compliance": item.LegalComplicance,
                Communicator: item.Communicator,
                "Farriery Expert": item["Farriery Expert"],
                "Farrier Kit": item["Farrier Kit"],
                Governance: item.Governance,
              };
            }

            // AHMF transformation
            const ahmfData = {
              ID: item.ID,
              Name: item.AHP_Name,
              Quarter: item.Quarter,
              Date: item.Date,
              LegalComplicance: item.LegalComplicance,
              "Clinical Expert": item.ClinicalExpert,
              "Kit Content": item.Content,
              "Clinical Governance": item.ClinicalGovernance,
            };
            console.log("AHMF Raw Item:", item);
            console.log("AHMF Transformed Item:", ahmfData);
            return ahmfData;
          });

          console.log("AHMF Final Transformed Data:", transformedData);
          setLocalData(transformedData);
        }
      } catch (error) {
        if (error.name === "AbortError") {
          setError("Request timed out. Please try again.");
        } else {
          setError(error.message);
        }
        setLocalData([]);
      } finally {
        setLoading(false);
      }
    };

    fetchCompetencyData();
  }, [partner, start, end, title, data]);

  // Update filtered data when data or search term changes
  useEffect(() => {
    if (!searchTerm.trim() || hideSearch) {
      setFilteredData(localData);
      return;
    }

    const fieldNames = getFieldNames();
    const filtered = localData.filter((item) =>
      item[fieldNames.name]?.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredData(filtered);
  }, [localData, searchTerm, hideSearch]);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  // Framework-specific columns
  const getColumns = () => {
    const fieldNames = getFieldNames();

    // Common base columns without ALC_Site and Name
    const commonColumns = [
      { field: fieldNames.quarter, headerName: "Quarter", width: 100 },
      { field: fieldNames.date, headerName: "Date", width: 100 },
      {
        field: title === "AHMF" ? "LegalComplicance" : "Legal Compliance",
        headerName: "Legal Compliance",
        width: 120,
      },
    ];

    // Add ALC_Site for FMF only
    const baseColumns =
      title === "FMF"
        ? [
            ...commonColumns.slice(0, 1), // Insert ALC_Site after Quarter
            { field: "ALC_Site", headerName: "ALC Site", width: 120 },
            ...commonColumns.slice(1), // Rest of the columns
          ]
        : commonColumns;

    if (title === "AMF") {
      return [
        ...baseColumns,
        {
          field: "Communicator",
          headerName: "Communicator",
          width: 120,
        },
        {
          field: "Pharmacological Expert",
          headerName: "Pharmacological Expert",
          width: 120,
        },
        {
          field: "Content of Agrovet",
          headerName: "Content of Agrovet",
          width: 120,
        },
        {
          field: "Agrovet Governance",
          headerName: "Agrovet Governance",
          width: 120,
        },
        getTrendColumn(),
      ];
    } else if (title === "FMF") {
      return [
        ...baseColumns,
        {
          field: "Communicator",
          headerName: "Communicator",
          width: 120,
        },
        {
          field: "Farriery Expert",
          headerName: "Farriery Expert",
          width: 120,
        },
        {
          field: "Farrier Kit",
          headerName: "Farrier Kit",
          width: 120,
        },
        {
          field: "Governance",
          headerName: "Governance",
          width: 120,
        },
        getTrendColumn(),
      ];
    } else {
      // AHMF columns
      return [
        ...baseColumns,
        {
          field: "ClinicalExpert",
          headerName: "Clinical Expert",
          width: 120,
        },
        {
          field: "Content",
          headerName: "Kit Content",
          width: 120,
        },
        {
          field: "ClinicalGovernance",
          headerName: "Clinical Governance",
          width: 120,
        },
        getTrendColumn(),
      ];
    }
  };

  const getTrendColumn = () => ({
    field: "actions",
    headerName: "Trend",
    width: 100,
    sortable: false,
    renderCell: (params) => {
      return (
        <Tooltip title="View Trend">
          <IconButton
            onClick={() => {
              setSelectedRecord(params.row);
              setTrendDialogOpen(true);
            }}
            size="small"
          >
            <TrendingUp />
          </IconButton>
        </Tooltip>
      );
    },
  });

  const getTrendDialog = () => {
    switch (title) {
      case "AMF":
        return (
          <AgrovetTrendDialog
            open={trendDialogOpen}
            onClose={() => setTrendDialogOpen(false)}
            record={selectedRecord}
            framework={title}
            year={new Date(start).getFullYear()}
          />
        );
      case "AHMF":
        return (
          <PractitionerTrendDialog
            open={trendDialogOpen}
            onClose={() => setTrendDialogOpen(false)}
            record={selectedRecord}
            framework={title}
            year={new Date(start).getFullYear()}
          />
        );
      case "FMF":
        return (
          <FarrierTrendDialog
            open={trendDialogOpen}
            onClose={() => setTrendDialogOpen(false)}
            record={selectedRecord}
            framework={title}
            year={new Date(start).getFullYear()}
          />
        );
      default:
        return null;
    }
  };

  if (loading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", p: 3 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          p: 3,
          color: "error.main",
        }}
      >
        <Typography>Error loading data: {error}</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ height: 600, width: "100%" }}>
      {/* Name Display */}
      {filteredData.length > 0 && (
        <Box sx={{ mb: 2, p: 1, bgcolor: "grey.50", borderRadius: 1 }}>
          <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
            {filteredData[0][getFieldNames().name]}
          </Typography>
        </Box>
      )}

      {/* Search Box */}
      {!hideSearch && (
        <Box sx={{ mb: 2 }}>
          <TextField
            fullWidth
            size="small"
            placeholder={`Search ${
              title === "AMF" ? "agrovet" : title === "AHMF" ? "AHP" : "farrier"
            } name...`}
            value={searchTerm}
            onChange={handleSearch}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      )}

      <DataGrid
        rows={filteredData}
        columns={getColumns()}
        pageSize={10}
        rowsPerPageOptions={[10]}
        disableSelectionOnClick
        density="compact"
        loading={loading}
        getRowId={(row) => row.ID}
        sx={{
          "& .MuiDataGrid-cell": {
            fontSize: "0.875rem",
            py: 0.5,
          },
          "& .MuiDataGrid-columnHeader": {
            backgroundColor: "rgba(0, 0, 0, 0.02)",
            fontWeight: 600,
            py: 0.5,
          },
          border: 1,
          borderColor: "divider",
          "& .MuiDataGrid-cell:focus": {
            outline: "none",
          },
        }}
      />
      {getTrendDialog()}
    </Box>
  );
};

export default Competencies;
