import "../Styles/login.scss";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Button,
  TextField,
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Card,
  Snackbar,
  Alert,
  Grid2,
  Container,
  Stack,
  Divider,
  CircularProgress,
  Link,
  IconButton,
  Grid,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useUser } from "../context/UserContext";
const lg1 = require("../assets/imgs/lg1.jpg");
const lg2 = require("../assets/imgs/lg2.jpg");
const lg3 = require("../assets/imgs/lg3.jpg");
const lg4 = require("../assets/imgs/lg4.jpg");
const lg5 = require("../assets/imgs/lg5.jpg");
const lg6 = require("../assets/imgs/lg6.jpg");
const lg7 = require("../assets/imgs/lg7.jpg");
const lg8 = require("../assets/imgs/lg8.jpg");
const lg9 = require("../assets/imgs/lg9.jpg");
const lg10 = require("../assets/imgs/lg10.jpg");
const logo = require("../assets/imgs/logo.png");

const images = [lg1, lg2, lg3, lg4, lg5, lg6, lg7, lg8, lg9, lg10];

export default function LoginPage(props) {
  const rfEmail = useRef();
  const rsEmail = useRef();
  const rfPassword = useRef();
  const [showPassword, setShowPassword] = useState(false);
  const code = useRef();
  const [isErr, setIsErr] = useState("");
  const [loading, setLoading] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [fadeIn, setFadeIn] = useState(true);
  const [body, updateBody] = useState({
    Email: null,
  });

  const [openResetDialog, setOpenResetDialog] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const navigate = useNavigate();

  const [resetEmail, setResetEmail] = useState("");
  const [dialogError, setDialogError] = useState("");

  const { setUser } = useUser();

  const login = () => {
    let d = body;
    d.Email = rfEmail.current.value.toLowerCase().trim();
    d.Password = rfPassword.current.value;
    updateBody(d);
    setIsErr("");

    if (!validateEmail(body.Email)) {
      setIsErr("Please Enter a Valid Email Address!");
      return;
    }
    if (!validatePassword(body.Password)) {
      setIsErr("Password must be at least 6 characters!");
      return;
    }

    if (validateEmail(body.Email) && validatePassword(body.Password)) {
      setLoading(true);
      fetch("/api/auth/login", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(body),
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw Error("Login Failed");
          }
        })
        .then((data) => {
          if (data.token) {
            localStorage.setItem("gdfhgfhtkn", data.token);
            if (data.user) {
              localStorage.setItem("user", JSON.stringify(data.user));
              setUser(data.user);
            }
            setIsErr("");
            setLoading(false);
            setTimeout(() => {
              window.location.href = "/home";
            }, 500);
          } else {
            setLoading(false);
            setIsErr(data.error || "Login failed");
          }
        })
        .catch((err) => {
          console.error("Login error:", err);
          setLoading(false);
          setIsErr("Login failed");
        });
    }
  };

  const reset = () => {
    let d = body;
    d.Email = rsEmail.current.value.toLowerCase().trim();
    updateBody(d);
    setIsErr("");

    if (!validateEmail(body.Email)) {
      setIsErr("Please Enter a Valid Email Address!");
      setSnackbarOpen(true);
      return;
    }

    if (validateEmail(body.Email)) {
      setLoading(true);
      fetch("/api/auth/forgot", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(body),
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw Error("Login Failed");
          }
        })
        .then((data) => {
          if (data.success) {
            localStorage.setItem("gdfhgfhtkngdfhgfhtkn", data.token);
            localStorage.removeItem("path");
            setIsErr(data.success);
            setLoading(false);
            navigate("/home");
          } else {
            setLoading(false);
            setIsErr(data.error);
            setSnackbarOpen(true);
          }
        })
        .catch((err) => {
          setLoading(false);
          setIsErr("Login failed");
          setSnackbarOpen(true);
        });
    }
  };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]/.,;:\s@"]+(\.[^<>()[\]/.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const validatePassword = (password) => {
    return password.length >= 6;
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setFadeIn(false);
      setTimeout(() => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
        setFadeIn(true);
      }, 1000); // Wait for fade out before changing image
    }, 5000); // Change every 5 seconds

    return () => clearInterval(intervalId);
  }, []);

  const handleResetDialogClose = () => {
    setOpenResetDialog(false);
    setResetEmail("");
    setDialogError("");
  };

  const handleRequestReset = async () => {
    if (!validateEmail(resetEmail)) {
      setDialogError("Please enter a valid email address!");
      return;
    }

    setLoading(true);
    try {
      const response = await fetch("/api/auth/forgot", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ Email: resetEmail }),
      });
      const data = await response.json();

      if (response.ok) {
        setDialogError("New password has been sent to your email");
        setTimeout(() => {
          handleResetDialogClose();
        }, 2000);
      } else {
        setDialogError(data.error || "Failed to reset password");
      }
    } catch (error) {
      setDialogError("Failed to reset password");
    }
    setLoading(false);
  };

  const handleForgotPasswordClick = (e) => {
    e.preventDefault();
    setOpenResetDialog(true);
  };

  const renderResetDialogContent = () => {
    return (
      <>
        <DialogTitle
          sx={{
            textAlign: "center",
            borderBottom: "1px solid",
            borderColor: "divider",
            pb: 2,
          }}
        >
          <Typography variant="h5" component="div" fontWeight="500">
            Reset Password
          </Typography>
        </DialogTitle>

        <DialogContent sx={{ mt: 2, minWidth: { xs: "300px", sm: "400px" } }}>
          {dialogError && (
            <Alert
              severity={
                dialogError.includes("sent to your email") ? "success" : "error"
              }
              sx={{ mb: 2 }}
            >
              {dialogError}
            </Alert>
          )}

          <Box sx={{ mb: 3 }}>
            <DialogContentText sx={{ mb: 2 }}>
              Enter your email address below and we'll send you instructions to
              reset your password.
            </DialogContentText>

            <TextField
              autoFocus
              margin="dense"
              label="Email Address"
              type="email"
              fullWidth
              value={resetEmail}
              onChange={(e) => setResetEmail(e.target.value)}
              variant="outlined"
              InputProps={{
                sx: { borderRadius: 1 },
              }}
              sx={{ mt: 1 }}
            />
          </Box>

          {loading ? (
            <Box sx={{ display: "flex", justifyContent: "center", my: 2 }}>
              <CircularProgress size={24} />
            </Box>
          ) : null}
        </DialogContent>

        <DialogActions
          sx={{
            px: 3,
            py: 2,
            borderTop: "1px solid",
            borderColor: "divider",
            justifyContent: "space-between",
          }}
        >
          <Button
            onClick={handleResetDialogClose}
            sx={{
              color: "text.secondary",
              "&:hover": {
                backgroundColor: "action.hover",
              },
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleRequestReset}
            variant="contained"
            disabled={!resetEmail || loading}
            sx={{
              minWidth: "120px",
              boxShadow: "none",
              "&:hover": {
                boxShadow: "none",
              },
            }}
          >
            Reset Password
          </Button>
        </DialogActions>
      </>
    );
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      minHeight="100vh"
      position="relative"
      sx={{
        overflow: "hidden",
        py: { xs: 4, md: 0 },
      }}
    >
      {/* Background image slider */}
      <div
        className="login-background"
        style={{
          backgroundImage: `url(${images[currentImageIndex]})`,
          opacity: fadeIn ? 1 : 0,
          transition: "opacity 1s ease-in-out",
        }}
      ></div>

      {/* Dark overlay */}
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0, 0, 0, 0.65)",
          zIndex: 1,
        }}
      />

      {/* Login container */}
      <Container maxWidth="md" sx={{ position: "relative", zIndex: 2 }}>
        <Grid
          container
          spacing={0}
          sx={{
            backgroundColor: "#ffffff",
            borderRadius: 4,
            overflow: "hidden",
            boxShadow: "0 20px 40px rgba(66, 85, 99, 0.15)",
          }}
        >
          {/* Left side - Logo and branding */}
          <Grid
            item
            xs={12}
            md={5}
            sx={{
              p: 6,
              background: "#fe5000",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              color: "#ffffff",
            }}
          >
            <img
              src={logo}
              alt="Logo"
              style={{
                width: 120,
                height: 120,
                marginBottom: "24px",
              }}
            />
            <Typography
              variant="h4"
              sx={{
                fontWeight: 700,
                textAlign: "center",
                mb: 2,
                color: "#ffffff",
              }}
            >
              BROOKE EAST AFRICA
            </Typography>
            <Typography
              variant="h6"
              sx={{
                fontWeight: 400,
                textAlign: "center",
                opacity: 0.9,
                color: "#ffffff",
              }}
            >
              GIS Data Portal
            </Typography>
          </Grid>

          {/* Right side - Login form */}
          <Grid item xs={12} md={7}>
            <Box
              sx={{
                p: 6,
                display: "flex",
                flexDirection: "column",
                height: "100%",
                backgroundColor: "#ffffff",
              }}
            >
              <Typography
                variant="h4"
                sx={{
                  mb: 4,
                  fontWeight: 600,
                  color: "#425563",
                }}
              >
                Welcome Back
              </Typography>

              {isErr && (
                <Alert severity="error" sx={{ mb: 3 }}>
                  {isErr}
                </Alert>
              )}

              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  login();
                }}
                noValidate
                style={{ flex: 1 }}
              >
                <Stack spacing={3}>
                  <TextField
                    inputRef={rfEmail}
                    label="Email Address"
                    variant="outlined"
                    fullWidth
                    type="email"
                    required
                    InputProps={{
                      sx: { borderRadius: 2 },
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "#fe5000",
                        },
                      },
                      "& .MuiInputLabel-root.Mui-focused": {
                        color: "#fe5000",
                      },
                      "& .MuiInputLabel-root": {
                        color: "#425563",
                      },
                      "& .MuiOutlinedInput-root:hover fieldset": {
                        borderColor: "#425563",
                      },
                    }}
                  />

                  <TextField
                    inputRef={rfPassword}
                    label="Password"
                    variant="outlined"
                    fullWidth
                    type={showPassword ? "text" : "password"}
                    required
                    InputProps={{
                      sx: { borderRadius: 2 },
                      endAdornment: (
                        <IconButton
                          onClick={() => setShowPassword((prev) => !prev)}
                          edge="end"
                          sx={{ color: "#425563" }}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      ),
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "#fe5000",
                        },
                      },
                      "& .MuiInputLabel-root.Mui-focused": {
                        color: "#fe5000",
                      },
                      "& .MuiInputLabel-root": {
                        color: "#425563",
                      },
                      "& .MuiOutlinedInput-root:hover fieldset": {
                        borderColor: "#425563",
                      },
                    }}
                  />

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Link
                      component="button"
                      variant="body2"
                      onClick={handleForgotPasswordClick}
                      sx={{
                        color: "#425563",
                        textDecoration: "none",
                        "&:hover": {
                          color: "#fe5000",
                          textDecoration: "underline",
                        },
                      }}
                    >
                      Forgot password?
                    </Link>
                  </Box>

                  <Button
                    variant="contained"
                    fullWidth
                    onClick={login}
                    disabled={loading}
                    sx={{
                      py: 1.5,
                      mt: 2,
                      borderRadius: 2,
                      textTransform: "none",
                      fontSize: "1rem",
                      fontWeight: 600,
                      backgroundColor: "#fe5000",
                      "&:hover": {
                        backgroundColor: "#425563",
                      },
                      "&:disabled": {
                        backgroundColor: "rgba(66, 85, 99, 0.12)",
                      },
                    }}
                  >
                    {loading ? (
                      <CircularProgress size={24} color="inherit" />
                    ) : (
                      "Sign In"
                    )}
                  </Button>
                </Stack>
              </form>
            </Box>
          </Grid>
        </Grid>
      </Container>

      {/* Keep existing Dialog and Snackbar components */}
      <Dialog
        open={openResetDialog}
        onClose={handleResetDialogClose}
        PaperProps={{
          sx: {
            borderRadius: 3,
            boxShadow: "0 8px 32px rgba(0,0,0,0.2)",
          },
        }}
      >
        {renderResetDialogContent()}
      </Dialog>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {isErr}
        </Alert>
      </Snackbar>
    </Box>
  );
}
