import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Paper,
  Typography,
  CircularProgress,
  Card,
  CardContent,
  Stack,
  Chip,
  Divider,
  IconButton,
  Tooltip,
  LinearProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  List,
  ListItem,
  ListItemText,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import {
  Assessment as AssessmentIcon,
  TrendingUp,
  BarChart as BarChartIcon,
  People as PeopleIcon,
  CalendarToday as CalendarIcon,
  CheckCircle as CheckCircleIcon,
  Warning as WarningIcon,
  Error as ErrorIcon,
  Refresh as RefreshIcon,
  Close as CloseIcon,
  Download as DownloadIcon,
} from "@mui/icons-material";
import theme from "../../../theme";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as ChartTooltip,
  Legend,
  ResponsiveContainer,
  LineChart,
  Line,
  PieChart,
  Pie,
  Cell,
} from "recharts";

// Custom components for the dashboard
const StatCard = ({ title, value, icon, color, subtitle, onClick }) => (
  <Card
    sx={{
      height: "100%",
      transition: "transform 0.2s, box-shadow 0.2s",
      "&:hover": {
        transform: "translateY(-4px)",
        boxShadow: 3,
      },
      cursor: onClick ? "pointer" : "default",
    }}
    onClick={onClick}
  >
    <CardContent>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Box>
          <Typography variant="subtitle2" color="text.secondary" gutterBottom>
            {title}
          </Typography>
          <Typography
            variant="h4"
            component="div"
            sx={{
              mb: 0.5,
              fontWeight: 600,
              background: `linear-gradient(45deg, ${theme.palette[color].main}, ${theme.palette[color].dark})`,
              backgroundClip: "text",
              textFillColor: "transparent",
            }}
          >
            {value}
          </Typography>
          {subtitle && (
            <Typography variant="body2" color="text.secondary">
              {subtitle}
            </Typography>
          )}
        </Box>
        <Box
          sx={{
            p: 2,
            borderRadius: 2,
            bgcolor: `${color}.lighter`,
            color: `${color}.main`,
            transition: "all 0.2s",
            "&:hover": {
              bgcolor: `${color}.light`,
              transform: "scale(1.1)",
            },
          }}
        >
          {icon}
        </Box>
      </Stack>
    </CardContent>
  </Card>
);

const ProgressCard = ({ title, data, color }) => (
  <Card sx={{ height: "100%" }}>
    <CardContent>
      <Typography variant="subtitle2" color="text.secondary" gutterBottom>
        {title}
      </Typography>
      <Stack spacing={2}>
        {data.map((item, index) => (
          <Box key={index}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{ mb: 0.5 }}
            >
              <Typography variant="body2">{item.label}</Typography>
              <Typography variant="body2" color="text.secondary">
                {item.value}%
              </Typography>
            </Stack>
            <LinearProgress
              variant="determinate"
              value={item.value}
              sx={{
                height: 8,
                borderRadius: 4,
                bgcolor: `${color}.lighter`,
                "& .MuiLinearProgress-bar": {
                  bgcolor: `${color}.main`,
                },
              }}
            />
          </Box>
        ))}
      </Stack>
    </CardContent>
  </Card>
);

const StatusCard = ({ title, data, color }) => (
  <Card sx={{ height: "100%" }}>
    <CardContent>
      <Typography variant="subtitle2" color="text.secondary" gutterBottom>
        {title}
      </Typography>
      <Stack spacing={1.5}>
        {data.map((item, index) => (
          <Stack
            key={index}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="body2">{item.label}</Typography>
            <Chip
              icon={item.icon}
              label={item.value}
              size="small"
              color={item.color}
              sx={{ fontWeight: 500 }}
            />
          </Stack>
        ))}
      </Stack>
    </CardContent>
  </Card>
);

const FrameworkDashboard = ({ framework, partner, dateRange, onDataLoad }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [data, setData] = useState({
    performanceStats: null,
    trends: null,
    competencies: null,
  });
  const [assessmentStatus, setAssessmentStatus] = useState(null);
  const [showAssessedDialog, setShowAssessedDialog] = useState(false);
  const [showNotAssessedDialog, setShowNotAssessedDialog] = useState(false);
  const [dialogType, setDialogType] = useState("assessed"); // 'assessed' or 'notAssessed'

  // Get the correct endpoint based on framework
  const getEndpoint = () => {
    switch (framework) {
      case "agrovets":
        return "/agrovets-monitoring";
      case "farriers":
        return "/farriers-monitoring";
      case "practitioners":
        return "/practitioners-monitoring";
      default:
        throw new Error(`Unknown framework: ${framework}`);
    }
  };

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      if (!partner || !dateRange?.start || !dateRange?.end) {
        if (isMounted) {
          setLoading(false);
          setError("Please select a partner and date range");
        }
        return;
      }

      if (isMounted) {
        setLoading(true);
        setError(null);
        setData({
          performanceStats: null,
          trends: null,
          competencies: null,
        });
      }

      try {
        const baseEndpoint = getEndpoint();
        const dateParams = `${encodeURIComponent(partner)}/${encodeURIComponent(
          dateRange.start
        )}/${encodeURIComponent(dateRange.end)}`;

        // Fetch all data in parallel
        const [
          performanceResponse,
          trendsResponse,
          competencyResponse,
          assessmentStatusResponse,
        ] = await Promise.all([
          fetch(`/api${baseEndpoint}/performance-stats/${dateParams}`),
          fetch(`/api${baseEndpoint}/quarterly-trends/${dateParams}`),
          fetch(`/api${baseEndpoint}/competency-distribution/${dateParams}`),
          fetch(`/api${baseEndpoint}/assessment-status/${dateParams}`),
        ]);

        // Check if any request failed
        if (
          !performanceResponse.ok ||
          !trendsResponse.ok ||
          !competencyResponse.ok ||
          !assessmentStatusResponse.ok
        ) {
          throw new Error("One or more requests failed");
        }

        const [
          performanceData,
          trendsData,
          competencyData,
          assessmentStatusData,
        ] = await Promise.all([
          performanceResponse.json(),
          trendsResponse.json(),
          competencyResponse.json(),
          assessmentStatusResponse.json(),
        ]);

        if (
          !performanceData.success ||
          !trendsData.success ||
          !competencyData.success ||
          !assessmentStatusData.success
        ) {
          throw new Error("One or more data fetches failed");
        }

        if (isMounted) {
          const newData = {
            performanceStats: performanceData.data,
            trends: trendsData.data,
            competencies: competencyData.data,
          };
          setData(newData);
          setAssessmentStatus(assessmentStatusData.data);

          if (onDataLoad) {
            onDataLoad(newData);
          }
        }
      } catch (error) {
        console.error(`Error fetching ${framework} data:`, error);
        if (isMounted) {
          setError(error.message);
          setData({
            performanceStats: null,
            trends: null,
            competencies: null,
          });
          if (onDataLoad) {
            onDataLoad(null);
          }
        }
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [framework, partner, dateRange?.start, dateRange?.end, onDataLoad]);

  const getPerformanceStats = () => {
    if (!data?.performanceStats)
      return {
        totalAssessments: 0,
        uniqueNames: 0,
        averageScore: 0,
        performanceLevels: {
          excellent: 0,
          good: 0,
          fair: 0,
          poor: 0,
        },
      };

    const { uniqueNames, totalAssessments, averageScore, performanceLevels } =
      data.performanceStats;

    return {
      totalAssessments: totalAssessments || 0,
      uniqueNames: uniqueNames || 0,
      averageScore: averageScore || 0,
      performanceLevels: {
        excellent: performanceLevels?.excellent || 0,
        good: performanceLevels?.good || 0,
        fair: performanceLevels?.fair || 0,
        poor: performanceLevels?.poor || 0,
      },
    };
  };

  const getCompetencyStats = () => {
    if (!data?.competencies) return [];

    return data.competencies.map((comp) => ({
      category: comp.category || "",
      value: parseFloat(comp.value) || 0,
    }));
  };

  const getStatusStats = () => {
    if (!data?.performanceStats) return [];

    const { totalAssessments = 0, performanceLevels = {} } =
      data.performanceStats;
    const passed =
      (performanceLevels.excellent || 0) + (performanceLevels.good || 0);
    const failed =
      (performanceLevels.fair || 0) + (performanceLevels.poor || 0);

    return [
      {
        label: "Passed Assessments",
        value: passed,
        icon: <CheckCircleIcon fontSize="small" />,
        color: "success",
      },
      {
        label: "Failed Assessments",
        value: failed,
        icon: <ErrorIcon fontSize="small" />,
        color: "error",
      },
      {
        label: "Total Assessments",
        value: totalAssessments,
        icon: <AssessmentIcon fontSize="small" />,
        color: "primary",
      },
    ];
  };

  const handleOpenDialog = (type) => {
    setDialogType(type);
    setShowAssessedDialog(true);
  };

  // Add export function
  const exportToCSV = (data, filename) => {
    const headers = Object.keys(data[0]).join(",");
    const rows = data.map((item) => Object.values(item).join(","));
    const csvContent = [headers, ...rows].join("\n");
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = filename;
    link.click();
  };

  if (loading) {
    return (
      <Box
        sx={{
          p: 3,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 2,
        }}
      >
        <CircularProgress />
        <Typography variant="body2" color="textSecondary">
          Loading dashboard data...
        </Typography>
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ p: 3 }}>
        <Typography color="error" gutterBottom>
          Error loading dashboard data
        </Typography>
        <Typography variant="body2" color="error">
          {error}
        </Typography>
      </Box>
    );
  }

  if (!data.performanceStats || !data.trends || !data.competencies) {
    return (
      <Box sx={{ p: 3 }}>
        <Typography color="textSecondary">
          No monitoring data available for the selected period.
        </Typography>
      </Box>
    );
  }

  const performanceStats = getPerformanceStats();
  const competencyStats = getCompetencyStats();
  const statusStats = getStatusStats();

  const getSuccessRate = () => {
    const total = performanceStats?.totalAssessments || 0;
    if (total === 0) return "0.0";

    const passed =
      (performanceStats?.performanceLevels?.excellent || 0) +
      (performanceStats?.performanceLevels?.good || 0);
    return ((passed / total) * 100).toFixed(1);
  };

  return (
    <Box sx={{ flexGrow: 1, p: 3 }}>
      <Grid container spacing={3}>
        {/* Header Stats */}
        <Grid item xs={12} md={3}>
          <StatCard
            title={`Total ${
              framework === "agrovets"
                ? "Agrovets"
                : framework === "farriers"
                ? "Farriers"
                : "Practitioners"
            }`}
            value={assessmentStatus?.total || 0}
            icon={<PeopleIcon sx={{ fontSize: 32 }} />}
            color="primary"
            subtitle={`${assessmentStatus?.assessed || 0} assessed, ${
              assessmentStatus?.notAssessed || 0
            } not assessed`}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <StatCard
            title="Assessed"
            value={assessmentStatus?.assessed || 0}
            icon={<CheckCircleIcon sx={{ fontSize: 32 }} />}
            color="success"
            subtitle={`${(
              ((assessmentStatus?.assessed || 0) /
                (assessmentStatus?.total || 1)) *
              100
            ).toFixed(1)}% of total`}
            onClick={() =>
              assessmentStatus?.assessed > 0 && setShowAssessedDialog(true)
            }
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <StatCard
            title="Not Assessed"
            value={assessmentStatus?.notAssessed || 0}
            icon={<WarningIcon sx={{ fontSize: 32 }} />}
            color="warning"
            subtitle={`${(
              ((assessmentStatus?.notAssessed || 0) /
                (assessmentStatus?.total || 1)) *
              100
            ).toFixed(1)}% of total`}
            onClick={() =>
              assessmentStatus?.notAssessed > 0 &&
              setShowNotAssessedDialog(true)
            }
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <StatCard
            title="Average Performance"
            value={`${performanceStats?.averageScore?.toFixed(1) || 0}%`}
            icon={<TrendingUp sx={{ fontSize: 32 }} />}
            color="info"
            subtitle="Overall assessment score"
          />
        </Grid>

        {/* Performance Breakdown - Full Width */}
        <Grid item xs={12}>
          <Card
            sx={{
              height: "100%",
              transition: "transform 0.2s, box-shadow 0.2s",
              "&:hover": {
                transform: "translateY(-4px)",
                boxShadow: 3,
              },
            }}
          >
            <CardContent>
              <Typography
                variant="h6"
                sx={{
                  mb: 3,
                  fontWeight: 600,
                  color: theme.palette.primary.main,
                }}
              >
                Performance Breakdown
              </Typography>
              <ResponsiveContainer width="100%" height={400}>
                <LineChart data={data.trends}>
                  <CartesianGrid strokeDasharray="3 3" stroke="#f0f0f0" />
                  <XAxis
                    dataKey="month"
                    stroke="#666"
                    tick={{ fill: "#666" }}
                  />
                  <YAxis
                    domain={[0, 100]}
                    tickFormatter={(value) => `${value}%`}
                    stroke="#666"
                    tick={{ fill: "#666" }}
                  />
                  <ChartTooltip
                    formatter={(value) => `${value}%`}
                    contentStyle={{
                      backgroundColor: "rgba(255, 255, 255, 0.9)",
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                    }}
                  />
                  <Legend
                    wrapperStyle={{
                      paddingTop: "20px",
                    }}
                  />
                  <Line
                    type="monotone"
                    dataKey="excellent_percentage"
                    stroke={theme.palette.success.main}
                    name="Excellent (90%+)"
                    dot={{ r: 4 }}
                    strokeWidth={2}
                  />
                  <Line
                    type="monotone"
                    dataKey="good_percentage"
                    stroke={theme.palette.info.main}
                    name="Good (70-89%)"
                    dot={{ r: 4 }}
                    strokeWidth={2}
                  />
                  <Line
                    type="monotone"
                    dataKey="fair_percentage"
                    stroke={theme.palette.warning.main}
                    name="Fair (50-69%)"
                    dot={{ r: 4 }}
                    strokeWidth={2}
                  />
                  <Line
                    type="monotone"
                    dataKey="poor_percentage"
                    stroke={theme.palette.error.main}
                    name="Poor (<50%)"
                    dot={{ r: 4 }}
                    strokeWidth={2}
                  />
                </LineChart>
              </ResponsiveContainer>
            </CardContent>
          </Card>
        </Grid>

        {/* Performance Trends */}
        <Grid item xs={12} md={8}>
          <Card
            sx={{
              height: "100%",
              transition: "transform 0.2s, box-shadow 0.2s",
              "&:hover": {
                transform: "translateY(-4px)",
                boxShadow: 3,
              },
            }}
          >
            <CardContent>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ mb: 3 }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: 600,
                    color: theme.palette.primary.main,
                  }}
                >
                  Performance Trends
                </Typography>
                <Tooltip title="Refresh data">
                  <IconButton
                    size="small"
                    sx={{
                      color: theme.palette.primary.main,
                      "&:hover": {
                        bgcolor: "primary.lighter",
                      },
                    }}
                  >
                    <RefreshIcon />
                  </IconButton>
                </Tooltip>
              </Stack>
              <ResponsiveContainer width="100%" height={300}>
                <LineChart data={data.trends}>
                  <CartesianGrid strokeDasharray="3 3" stroke="#f0f0f0" />
                  <XAxis
                    dataKey="month"
                    stroke="#666"
                    tick={{ fill: "#666" }}
                  />
                  <YAxis
                    yAxisId="left"
                    domain={[0, 100]}
                    tickFormatter={(value) => `${value}%`}
                    stroke="#666"
                    tick={{ fill: "#666" }}
                  />
                  <YAxis
                    yAxisId="right"
                    orientation="right"
                    domain={[0, "auto"]}
                    stroke="#666"
                    tick={{ fill: "#666" }}
                  />
                  <ChartTooltip
                    formatter={(value, name) => [
                      name === "Average Score" ? `${value}%` : value,
                      name,
                    ]}
                    contentStyle={{
                      backgroundColor: "rgba(255, 255, 255, 0.9)",
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                    }}
                  />
                  <Legend
                    wrapperStyle={{
                      paddingTop: "20px",
                    }}
                  />
                  <Line
                    yAxisId="left"
                    type="monotone"
                    dataKey="average_score"
                    stroke={theme.palette.primary.main}
                    name="Average Score"
                    dot={{ r: 4 }}
                    strokeWidth={2}
                  />
                  <Line
                    yAxisId="right"
                    type="monotone"
                    dataKey="count"
                    stroke={theme.palette.secondary.main}
                    name="Total Assessments"
                    dot={{ r: 4 }}
                    strokeWidth={2}
                  />
                </LineChart>
              </ResponsiveContainer>
            </CardContent>
          </Card>
        </Grid>

        {/* Competency Distribution */}
        <Grid item xs={12} md={4}>
          <Card
            sx={{
              height: "100%",
              transition: "transform 0.2s, box-shadow 0.2s",
              "&:hover": {
                transform: "translateY(-4px)",
                boxShadow: 3,
              },
            }}
          >
            <CardContent>
              <Typography
                variant="h6"
                sx={{
                  mb: 3,
                  fontWeight: 600,
                  color: theme.palette.primary.main,
                }}
              >
                Competency Distribution
              </Typography>
              <ResponsiveContainer width="100%" height={300}>
                <BarChart data={data.competencies}>
                  <CartesianGrid strokeDasharray="3 3" stroke="#f0f0f0" />
                  <XAxis
                    dataKey="category"
                    angle={-45}
                    textAnchor="end"
                    height={70}
                    interval={0}
                    stroke="#666"
                    tick={{ fill: "#666" }}
                  />
                  <YAxis
                    domain={[0, 100]}
                    tickFormatter={(value) => `${value}%`}
                    stroke="#666"
                    tick={{ fill: "#666" }}
                  />
                  <ChartTooltip
                    formatter={(value) => `${value}%`}
                    labelFormatter={(label) => `${label}`}
                    contentStyle={{
                      backgroundColor: "rgba(255, 255, 255, 0.9)",
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                    }}
                  />
                  <Bar dataKey="value" fill={theme.palette.primary.main}>
                    {data.competencies.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={
                          entry.value >= 90
                            ? theme.palette.success.main
                            : entry.value >= 70
                            ? theme.palette.info.main
                            : entry.value >= 50
                            ? theme.palette.warning.main
                            : theme.palette.error.main
                        }
                      />
                    ))}
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Assessed Agrovets Dialog */}
      <Dialog
        open={showAssessedDialog}
        onClose={() => setShowAssessedDialog(false)}
        maxWidth="md"
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: 2,
          },
        }}
      >
        <DialogTitle
          sx={{
            bgcolor: "success.lighter",
            color: "success.main",
            fontWeight: 600,
          }}
        >
          Assessed
          <IconButton
            onClick={() => setShowAssessedDialog(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "success.main",
              "&:hover": {
                bgcolor: "success.lighter",
              },
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ mb: 2, display: "flex", justifyContent: "flex-end" }}>
            <Button
              variant="outlined"
              startIcon={<DownloadIcon />}
              onClick={() =>
                exportToCSV(
                  assessmentStatus?.assessedList.map((item) => ({
                    "Agrovet Name": item.name,
                    Partner: item.partner,
                    "Assessment Date": new Date(
                      item.assessmentDate
                    ).toLocaleDateString(),
                  })) || [],
                  "assessed_agrovets.csv"
                )
              }
              sx={{
                color: "success.main",
                borderColor: "success.main",
                "&:hover": {
                  borderColor: "success.dark",
                  bgcolor: "success.lighter",
                },
              }}
            >
              Export to CSV
            </Button>
          </Box>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: 600 }}>Agrovet Name</TableCell>
                  <TableCell sx={{ fontWeight: 600 }}>Partner</TableCell>
                  <TableCell sx={{ fontWeight: 600 }}>
                    Assessment Date
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {assessmentStatus?.assessedList.map((agrovet, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      "&:hover": {
                        bgcolor: "success.lighter",
                      },
                    }}
                  >
                    <TableCell>{agrovet.name}</TableCell>
                    <TableCell>{agrovet.partner}</TableCell>
                    <TableCell>
                      {new Date(agrovet.assessmentDate).toLocaleDateString()}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
      </Dialog>

      {/* Not Assessed Agrovets Dialog */}
      <Dialog
        open={showNotAssessedDialog}
        onClose={() => setShowNotAssessedDialog(false)}
        maxWidth="md"
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: 2,
          },
        }}
      >
        <DialogTitle
          sx={{
            bgcolor: "warning.lighter",
            color: "warning.main",
            fontWeight: 600,
          }}
        >
          Not Assessed
          <IconButton
            onClick={() => setShowNotAssessedDialog(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "warning.main",
              "&:hover": {
                bgcolor: "warning.lighter",
              },
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ mb: 2, display: "flex", justifyContent: "flex-end" }}>
            <Button
              variant="outlined"
              startIcon={<DownloadIcon />}
              onClick={() =>
                exportToCSV(
                  assessmentStatus?.notAssessedList.map((item) => ({
                    "Agrovet Name": item.name,
                    Partner: item.partner,
                    "Last Assessment Date": item.lastAssessmentDate
                      ? new Date(item.lastAssessmentDate).toLocaleDateString()
                      : "Never",
                  })) || [],
                  "not_assessed_agrovets.csv"
                )
              }
              sx={{
                color: "warning.main",
                borderColor: "warning.main",
                "&:hover": {
                  borderColor: "warning.dark",
                  bgcolor: "warning.lighter",
                },
              }}
            >
              Export to CSV
            </Button>
          </Box>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: 600 }}>Agrovet Name</TableCell>
                  <TableCell sx={{ fontWeight: 600 }}>Partner</TableCell>
                  <TableCell sx={{ fontWeight: 600 }}>
                    Last Assessment Date
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {assessmentStatus?.notAssessedList.map((agrovet, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      "&:hover": {
                        bgcolor: "warning.lighter",
                      },
                    }}
                  >
                    <TableCell>{agrovet.name}</TableCell>
                    <TableCell>{agrovet.partner}</TableCell>
                    <TableCell>
                      {agrovet.lastAssessmentDate
                        ? new Date(
                            agrovet.lastAssessmentDate
                          ).toLocaleDateString()
                        : "Never"}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default FrameworkDashboard;
