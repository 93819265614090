import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  TextField,
  CircularProgress,
  IconButton,
  Tooltip,
  Paper,
  Alert,
  Skeleton,
  Button,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Visibility as VisibilityIcon, Download } from "@mui/icons-material";
import XLSX from "xlsx-js-style";

const ResultsAll = ({
  partner,
  start,
  end,
  title = "AMF",
  data,
  hideSearch = false,
  onRowClick,
}) => {
  const [pageSize, setPageSize] = useState(10);
  const [filter, setFilter] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [localData, setLocalData] = useState([]);
  const [loadingDetails, setLoadingDetails] = useState(false);
  const [isExporting, setIsExporting] = useState(false);

  // Transform tool name to lowercase and remove spaces
  const getTool = () => {
    switch (title) {
      case "AMF":
        return "agrovets";
      case "AHMF":
        return "practitioners";
      case "FMF":
        return "farriers";
      default:
        return "agrovets";
    }
  };

  // Use provided data or fetch from API
  useEffect(() => {
    if (data) {
      setLocalData(data);
      return;
    }

    const fetchData = async () => {
      setLoading(true);
      setError(null);

      if (!partner || !start || !end) {
        setError("Missing required parameters");
        setLoading(false);
        return;
      }

      try {
        let url;
        switch (title) {
          case "AMF":
            url = `/api/agrovets-monitoring/results/data/${encodeURIComponent(
              partner
            )}/${encodeURIComponent(start)}/${encodeURIComponent(end)}`;
            break;
          case "AHMF":
            url = `/api/practitioners-monitoring/results/data/${encodeURIComponent(
              partner
            )}/${encodeURIComponent(start)}/${encodeURIComponent(end)}`;
            break;
          case "FMF":
            url = `/api/farriers-monitoring/results/data/${encodeURIComponent(
              partner
            )}/${encodeURIComponent(start)}/${encodeURIComponent(end)}`;
            break;
          default:
            throw new Error("Invalid framework type");
        }

        const response = await fetch(url);

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const result = await response.json();

        if (result?.success && Array.isArray(result?.data)) {
          const transformedData = result.data.map((item) => ({
            id: item.ID,
            ID: item.ID,
            Name: item.Name,
            Quarter: item.Quarter,
            Date: item.Date,
            Assessor: item.Assessor,
            Score: item.Score,
            Percentage: item.Percentage,
            Case_Summary: item.Case_Summary,
            ALC_Site: item.ALC_Site,
            createdAt: item.createdAt,
          }));
          setLocalData(transformedData);
        } else {
          setError("Data format is incorrect");
        }
      } catch (error) {
        setError(`Failed to fetch data: ${error.message}`);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [partner, start, end, title, data]);

  const handleViewDetails = async (params) => {
    setLoadingDetails(true);
    try {
      const name = params.row.Name;
      const date = params.row.Date || params.row.Assessment_Date;

      if (!name || !date) {
        throw new Error("Missing name or date for row");
      }

      const response = await fetch(
        `/api/${getTool()}/monitoring/all/${encodeURIComponent(
          partner
        )}/${encodeURIComponent(date)}/${encodeURIComponent(
          date
        )}/0?limit=1&name=${encodeURIComponent(name)}`
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      if (result.data && result.data.length > 0) {
        onRowClick(result.data[0]);
      }
    } catch (error) {
      console.error("Error fetching specific data:", error);
      // You might want to show this error to the user in a more user-friendly way
    } finally {
      setLoadingDetails(false);
    }
  };

  // Column definitions based on framework type
  const getColumns = () => {
    const baseColumns = [
      {
        field: "Name",
        headerName: "Name",
        flex: 1,
        minWidth: 200,
        renderCell: (params) => (
          <Typography variant="body2" sx={{ fontWeight: 500 }}>
            {params.value}
          </Typography>
        ),
      },
      {
        field: "Quarter",
        headerName: "Quarter",
        flex: 1,
        minWidth: 120,
        renderCell: (params) => (
          <Typography variant="body2" sx={{ fontWeight: 500 }}>
            {params.value}
          </Typography>
        ),
      },
      {
        field: "Date",
        headerName: "Date",
        flex: 1,
        minWidth: 120,
        renderCell: (params) => (
          <Typography variant="body2">
            {new Date(params.value).toLocaleDateString()}
          </Typography>
        ),
      },
      {
        field: "Assessor",
        headerName: "Assessor",
        flex: 1,
        minWidth: 150,
        renderCell: (params) => (
          <Typography variant="body2" sx={{ fontWeight: 500 }}>
            {params.value || "N/A"}
          </Typography>
        ),
      },
      {
        field: "Score",
        headerName: "Score",
        flex: 1,
        minWidth: 110,
        type: "number",
        renderCell: (params) => (
          <Typography
            variant="body2"
            sx={{
              fontWeight: 600,
              color: params.value >= 70 ? "success.main" : "warning.main",
            }}
          >
            {params.value}
          </Typography>
        ),
      },
      {
        field: "Percentage",
        headerName: "Percentage",
        flex: 1,
        minWidth: 110,
        renderCell: (params) => (
          <Typography
            variant="body2"
            sx={{
              fontWeight: 600,
              color: params.value >= "70%" ? "success.main" : "warning.main",
            }}
          >
            {params.value}
          </Typography>
        ),
      },
    ];

    // Add framework-specific columns
    switch (title) {
      case "AHMF":
        baseColumns.splice(3, 0, {
          field: "Case_Summary",
          headerName: "Case Summary",
          flex: 1,
          minWidth: 200,
          renderCell: (params) => (
            <Typography variant="body2" sx={{ fontWeight: 500 }}>
              {params.value || "N/A"}
            </Typography>
          ),
        });
        break;
      case "FMF":
        baseColumns.splice(3, 0, {
          field: "ALC_Site",
          headerName: "ALC Site",
          flex: 1,
          minWidth: 150,
          renderCell: (params) => (
            <Typography variant="body2" sx={{ fontWeight: 500 }}>
              {params.value || "N/A"}
            </Typography>
          ),
        });
        break;
    }

    return baseColumns;
  };

  const exportToExcel = async () => {
    try {
      setIsExporting(true);
      const exportData = localData.map((item) => {
        const baseData = {
          Name: item.Name,
          Quarter: item.Quarter,
          Date: new Date(item.Date).toLocaleDateString(),
          Assessor: item.Assessor || "N/A",
          Score: item.Score,
          Percentage: item.Percentage,
        };

        // Add framework-specific fields
        switch (title) {
          case "AHMF":
            return {
              ...baseData,
              "Case Summary": item.Case_Summary || "N/A",
            };
          case "FMF":
            return {
              ...baseData,
              "ALC Site": item.ALC_Site || "N/A",
            };
          default:
            return baseData;
        }
      });

      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet(exportData);
      XLSX.utils.book_append_sheet(wb, ws, "Results");

      const date = new Date().toISOString().split("T")[0];
      const filename = `${title}_Results_${date}.xlsx`;
      XLSX.writeFile(wb, filename);
    } catch (error) {
      console.error("Export failed:", error);
      alert("Failed to export data. Please try again.");
    } finally {
      setIsExporting(false);
    }
  };

  return (
    <Paper elevation={0} sx={{ height: 600, width: "100%", p: 2 }}>
      {!hideSearch && (
        <Box
          sx={{
            mb: 3,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <TextField
            label="Filter by Name"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            variant="outlined"
            size="small"
            sx={{ width: 250 }}
            InputProps={{
              sx: {
                "&:hover": {
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "primary.main",
                  },
                },
              },
            }}
          />
          <Button
            variant="contained"
            color="primary"
            startIcon={
              isExporting ? (
                <CircularProgress size={16} color="inherit" />
              ) : (
                <Download sx={{ fontSize: "1.1rem" }} />
              )
            }
            onClick={exportToExcel}
            disabled={isExporting || !localData.length}
            sx={{
              height: 32,
              px: 2,
              fontSize: "0.825rem",
              fontWeight: 500,
              boxShadow: "none",
              minWidth: 120,
              "&:hover": {
                boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
                transform: "translateY(-1px)",
              },
              transition: "all 0.2s ease",
            }}
          >
            {isExporting ? "Exporting..." : "Export Report"}
          </Button>
        </Box>
      )}

      {loading ? (
        <Box sx={{ p: 3 }}>
          <Skeleton variant="rectangular" height={400} />
          <Box sx={{ mt: 2, display: "flex", justifyContent: "center" }}>
            <CircularProgress size={24} />
          </Box>
        </Box>
      ) : error ? (
        <Alert severity="error" sx={{ m: 2 }}>
          {error}
        </Alert>
      ) : localData.length === 0 ? (
        <Box
          sx={{
            p: 3,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            color: "text.secondary",
          }}
        >
          <Typography variant="h6">No data available</Typography>
          <Typography variant="body2" sx={{ mt: 1 }}>
            Try adjusting your filters or date range
          </Typography>
        </Box>
      ) : (
        <DataGrid
          rows={localData.filter((row) => {
            if (hideSearch) return true;
            const searchName = (row.Name || "").toLowerCase();
            return searchName.includes(filter.toLowerCase());
          })}
          columns={getColumns()}
          pagination
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[5, 10, 20]}
          disableSelectionOnClick
          getRowId={(row) => row.ID}
          initialState={{
            sorting: {
              sortModel: [{ field: "createdAt", sort: "desc" }],
            },
          }}
          sx={{
            "& .MuiDataGrid-cell": {
              fontSize: "0.875rem",
              borderBottom: "1px solid",
              borderColor: "divider",
            },
            "& .MuiDataGrid-columnHeader": {
              backgroundColor: "background.paper",
              fontWeight: 600,
              borderBottom: "2px solid",
              borderColor: "primary.main",
            },
            "& .MuiDataGrid-row:hover": {
              backgroundColor: "action.hover",
            },
            "& .MuiDataGrid-cell:focus": {
              outline: "none",
            },
            border: "none",
          }}
        />
      )}
    </Paper>
  );
};

export default ResultsAll;
