import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  TextField,
  CircularProgress,
  IconButton,
  Tooltip,
  Paper,
  Alert,
  Skeleton,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Visibility as VisibilityIcon } from "@mui/icons-material";

const Results = ({
  partner,
  start,
  end,
  title = "AMF",
  data,
  hideSearch = false,
  onRowClick,
}) => {
  const [pageSize, setPageSize] = useState(10);
  const [filter, setFilter] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [localData, setLocalData] = useState([]);
  const [loadingDetails, setLoadingDetails] = useState(false);

  // Transform tool name to lowercase and remove spaces
  const getTool = () => {
    switch (title) {
      case "AMF":
        return "agrovets";
      case "AHMF":
        return "practitioners";
      case "FMF":
        return "farriers";
      default:
        return "agrovets";
    }
  };

  // Use provided data or fetch from API
  useEffect(() => {
    if (data) {
      setLocalData(data);
      return;
    }

    const fetchData = async () => {
      setLoading(true);
      setError(null);

      if (!partner || !start || !end) {
        setError("Missing required parameters");
        setLoading(false);
        return;
      }

      try {
        let url;
        switch (title) {
          case "AMF":
            url = `/api/data/agrovets/results/data/${encodeURIComponent(
              partner
            )}/${encodeURIComponent(start)}/${encodeURIComponent(end)}`;
            break;
          case "AHMF":
            url = `/api/data/practitioners/results/data/${encodeURIComponent(
              partner
            )}/${encodeURIComponent(start)}/${encodeURIComponent(end)}`;
            break;
          case "FMF":
            url = `/api/data/farriers/results/data/${encodeURIComponent(
              partner
            )}/${encodeURIComponent(start)}/${encodeURIComponent(end)}`;
            break;
          default:
            throw new Error("Invalid framework type");
        }

        const response = await fetch(url);

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const result = await response.json();

        if (Array.isArray(result?.data)) {
          setLocalData(result.data);
        } else {
          setError("Data format is incorrect");
        }
      } catch (error) {
        setError(`Failed to fetch data: ${error.message}`);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [partner, start, end, title, data]);

  const handleViewDetails = async (params) => {
    setLoadingDetails(true);
    try {
      const nameField =
        title === "AMF"
          ? "Agrovet_Name"
          : title === "AHMF"
          ? "AHP_Name"
          : "FarrierName";
      const name = params.row[nameField] || params.row.Name;
      const date = params.row.Date || params.row.Assessment_Date;

      if (!name || !date) {
        throw new Error("Missing name or date for row");
      }

      const response = await fetch(
        `/api/${getTool()}/monitoring/all/${encodeURIComponent(
          partner
        )}/${encodeURIComponent(date)}/${encodeURIComponent(
          date
        )}/0?limit=1&name=${encodeURIComponent(name)}`
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      if (result.data && result.data.length > 0) {
        onRowClick(result.data[0]);
      }
    } catch (error) {
      console.error("Error fetching specific data:", error);
      // You might want to show this error to the user in a more user-friendly way
    } finally {
      setLoadingDetails(false);
    }
  };

  // Column definitions based on framework type
  const getColumns = () => {
    return [
      {
        field: "Quarter",
        headerName: "Quarter",
        flex: 1,
        minWidth: 120,
        renderCell: (params) => (
          <Typography variant="body2" sx={{ fontWeight: 500 }}>
            {params.value}
          </Typography>
        ),
      },
      {
        field: "Date",
        headerName: "Date",
        flex: 1,
        minWidth: 120,
        renderCell: (params) => (
          <Typography variant="body2">
            {new Date(params.value).toLocaleDateString()}
          </Typography>
        ),
      },
      {
        field: "Assessor",
        headerName: "Assessor",
        flex: 1,
        minWidth: 150,
        renderCell: (params) => (
          <Typography variant="body2" sx={{ fontWeight: 500 }}>
            {params.value || "N/A"}
          </Typography>
        ),
      },
      {
        field: "Score",
        headerName: "Score",
        flex: 1,
        minWidth: 110,
        type: "number",
        renderCell: (params) => (
          <Typography
            variant="body2"
            sx={{
              fontWeight: 600,
              color: params.value >= 70 ? "success.main" : "warning.main",
            }}
          >
            {params.value}
          </Typography>
        ),
      },
      {
        field: "Percentage",
        headerName: "Percentage",
        flex: 1,
        minWidth: 110,
        renderCell: (params) => (
          <Typography
            variant="body2"
            sx={{
              fontWeight: 600,
              color: params.value >= 70 ? "success.main" : "warning.main",
            }}
          >
            {params.value}%
          </Typography>
        ),
      },
      {
        field: "actions",
        headerName: "Actions",
        width: 100,
        sortable: false,
        renderCell: (params) => (
          <Tooltip title="View Details">
            <IconButton
              onClick={() => handleViewDetails(params)}
              size="small"
              color="primary"
              disabled={loadingDetails}
              sx={{
                "&:hover": {
                  backgroundColor: "primary.light",
                  color: "primary.contrastText",
                },
              }}
            >
              <VisibilityIcon />
            </IconButton>
          </Tooltip>
        ),
      },
    ];
  };

  return (
    <Paper elevation={0} sx={{ height: 600, width: "100%", p: 2 }}>
      {!hideSearch && (
        <Box
          sx={{
            mb: 3,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <TextField
            label="Filter by Name"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            variant="outlined"
            size="small"
            sx={{ width: 250 }}
            InputProps={{
              sx: {
                "&:hover": {
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "primary.main",
                  },
                },
              },
            }}
          />
        </Box>
      )}

      {loading ? (
        <Box sx={{ p: 3 }}>
          <Skeleton variant="rectangular" height={400} />
          <Box sx={{ mt: 2, display: "flex", justifyContent: "center" }}>
            <CircularProgress size={24} />
          </Box>
        </Box>
      ) : error ? (
        <Alert severity="error" sx={{ m: 2 }}>
          {error}
        </Alert>
      ) : localData.length === 0 ? (
        <Box
          sx={{
            p: 3,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            color: "text.secondary",
          }}
        >
          <Typography variant="h6">No data available</Typography>
          <Typography variant="body2" sx={{ mt: 1 }}>
            Try adjusting your filters or date range
          </Typography>
        </Box>
      ) : (
        <DataGrid
          rows={localData.filter((row) => {
            if (hideSearch) return true;
            const searchName = (
              row.Name ||
              row.FarrierName ||
              row.AHP_Name ||
              row.Agrovet_Name ||
              ""
            ).toLowerCase();
            return searchName.includes(filter.toLowerCase());
          })}
          columns={getColumns()}
          pagination
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[5, 10, 20]}
          disableSelectionOnClick
          getRowId={(row) => row.ID}
          sx={{
            "& .MuiDataGrid-cell": {
              fontSize: "0.875rem",
              borderBottom: "1px solid",
              borderColor: "divider",
            },
            "& .MuiDataGrid-columnHeader": {
              backgroundColor: "background.paper",
              fontWeight: 600,
              borderBottom: "2px solid",
              borderColor: "primary.main",
            },
            "& .MuiDataGrid-row:hover": {
              backgroundColor: "action.hover",
            },
            "& .MuiDataGrid-cell:focus": {
              outline: "none",
            },
            border: "none",
          }}
        />
      )}
    </Paper>
  );
};

export default Results;
