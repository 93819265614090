import { createTheme } from "@mui/material/styles";

// Brooke's Official Brand Colors
const BROOKE_COLORS = {
  // Primary Palette
  primary: {
    main: "#fe5000", // Brooke Orange - PMS Orange 021
    dark: "#e54600", // Darker shade for hover states
    light: "#ff7133", // Lighter shade for highlights
  },
  secondary: {
    main: "#425563", // Brooke Grey - PMS 7545
    dark: "#374652", // Darker shade for hover states
    light: "#5f7182", // Lighter shade for highlights
  },
  common: {
    white: "#ffffff", // Brooke White
  },
  // Secondary Palette (for accents and special elements)
  accent: {
    yellow: "#f6be00", // PMS 7408
    green: "#c4d600", // PMS 382
    blue: "#0097a9", // PMS 7711
    red: "#ce0058", // PMS Rubine Red
  },
};

const theme = createTheme({
  palette: {
    primary: BROOKE_COLORS.primary,
    secondary: BROOKE_COLORS.secondary,
    common: BROOKE_COLORS.common,
    accent: BROOKE_COLORS.accent,
    background: {
      default: "#f5f5f5",
      paper: "#ffffff",
    },
    text: {
      primary: BROOKE_COLORS.secondary.main,
      secondary: BROOKE_COLORS.secondary.light,
    },
  },
  typography: {
    fontFamily: '"Helvetica Neue", Arial, sans-serif',
    h1: {
      color: BROOKE_COLORS.secondary.main,
      fontWeight: 700,
    },
    h2: {
      color: BROOKE_COLORS.secondary.main,
      fontWeight: 600,
    },
    h3: {
      color: BROOKE_COLORS.secondary.main,
      fontWeight: 600,
    },
    h4: {
      color: BROOKE_COLORS.secondary.main,
      fontWeight: 600,
    },
    h5: {
      color: BROOKE_COLORS.secondary.main,
      fontWeight: 600,
    },
    h6: {
      color: BROOKE_COLORS.secondary.main,
      fontWeight: 600,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 4,
          textTransform: "none",
          fontWeight: 500,
        },
        containedPrimary: {
          backgroundColor: BROOKE_COLORS.primary.main,
          "&:hover": {
            backgroundColor: BROOKE_COLORS.primary.dark,
          },
        },
        containedSecondary: {
          backgroundColor: BROOKE_COLORS.secondary.main,
          "&:hover": {
            backgroundColor: BROOKE_COLORS.secondary.dark,
          },
        },
        outlinedPrimary: {
          borderColor: BROOKE_COLORS.primary.main,
          color: BROOKE_COLORS.primary.main,
          "&:hover": {
            borderColor: BROOKE_COLORS.primary.dark,
            color: BROOKE_COLORS.primary.dark,
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
              borderColor: BROOKE_COLORS.primary.main,
            },
            "&:hover fieldset": {
              borderColor: BROOKE_COLORS.secondary.main,
            },
          },
          "& .MuiInputLabel-root.Mui-focused": {
            color: BROOKE_COLORS.primary.main,
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: BROOKE_COLORS.common.white,
          color: BROOKE_COLORS.secondary.main,
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          boxShadow: "0 2px 8px rgba(66, 85, 99, 0.1)",
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: BROOKE_COLORS.primary.main,
          "&:hover": {
            color: BROOKE_COLORS.primary.dark,
          },
        },
      },
    },
  },
});

export default theme;
