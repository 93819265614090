import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  IconButton,
  Tooltip,
  Divider,
  Alert,
  Snackbar,
  CircularProgress,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Autocomplete,
  Stack,
  Card,
  CardContent,
  Avatar,
  Chip,
  useTheme,
  alpha,
  Grid,
  Fade,
  ButtonBase,
  Grow,
} from "@mui/material";
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Add as AddIcon,
  Person as PersonIcon,
  Search as SearchIcon,
  AdminPanelSettings as AdminIcon,
  Support as StaffIcon,
  Handshake as PartnerIcon,
  PhoneAndroid as MobileIcon,
  Computer as PortalIcon,
  AllInclusive as FullAccessIcon,
} from "@mui/icons-material";
import InputAdornment from "@mui/material/InputAdornment";

const LEVELS = ["Full Access", "Mobile", "Portal"];
const ROLES = ["Staff", "Partner", "Admin"];

const Users = () => {
  const [users, setUsers] = useState([]);
  const [partners, setPartners] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(10);
  const [openDialog, setOpenDialog] = useState(false);
  const [editingUser, setEditingUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [counts, setCounts] = useState({
    total: 0,
    mobile: 0,
    portal: 0,
    fullAccess: 0,
  });
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const [formData, setFormData] = useState({
    Name: "",
    Email: "",
    Phone: "",
    Partner: "",
    Level: "",
    Role: "",
    Password: "",
    ConfirmPassword: "",
  });
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [confirmDialogData, setConfirmDialogData] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const theme = useTheme();

  useEffect(() => {
    fetchUsers();
    fetchPartners();
  }, [page]);

  const fetchUsers = async () => {
    setLoading(true);
    try {
      const response = await fetch(`/api/auth/paginated/${page * rowsPerPage}`);
      const data = await response.json();
      setUsers(data.result || []);
      setCounts(
        data.counts || {
          total: 0,
          mobile: 0,
          portal: 0,
          fullAccess: 0,
        }
      );
    } catch (error) {
      console.error("Error fetching users:", error);
      showSnackbar("Failed to load users", "error");
    } finally {
      setLoading(false);
    }
  };

  const fetchPartners = async () => {
    try {
      const response = await fetch("/api/partners");
      const data = await response.json();
      setPartners(Array.isArray(data) ? data : data.data || []);
    } catch (error) {
      console.error("Error fetching partners:", error);
      setPartners([]);
    }
  };

  const debounce = (func, wait) => {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(this, args), wait);
    };
  };

  const searchUsers = async (query) => {
    if (!query.trim()) {
      setIsSearching(false);
      fetchUsers();
      return;
    }

    setIsSearching(true);
    try {
      const response = await fetch(`/api/auth/quicksearch/${query}`);
      if (!response.ok) throw new Error("Search failed");
      const data = await response.json();
      setSearchResults(data.data || []);
    } catch (error) {
      console.error("Error searching users:", error);
      showSnackbar("Error searching users", "error");
    } finally {
      setIsSearching(false);
    }
  };

  const debouncedSearch = useCallback(
    debounce((query) => searchUsers(query), 300),
    []
  );

  const handleSearch = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    debouncedSearch(query);
  };

  const showSnackbar = (message, severity = "success") => {
    setSnackbar({ open: true, message, severity });
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const handleOpenDialog = (user = null) => {
    if (user) {
      setEditingUser(user);
      setFormData({
        Name: user.Name,
        Email: user.Email,
        Phone: user.Phone,
        Partner: user.Partner,
        Level: user.Level,
        Role: user.Role,
        Password: "",
        ConfirmPassword: "",
      });
    } else {
      setEditingUser(null);
      setFormData({
        Name: "",
        Email: "",
        Phone: "",
        Partner: "",
        Level: "",
        Role: "",
        Password: "",
        ConfirmPassword: "",
      });
    }
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setEditingUser(null);
  };

  const handleSubmit = async () => {
    if (!editingUser && formData.Password !== formData.ConfirmPassword) {
      showSnackbar("Passwords do not match", "error");
      return;
    }

    setConfirmDialogData({
      title: editingUser ? "Update User" : "Create New User",
      message: editingUser
        ? `Are you sure you want to update ${formData.Name}?`
        : `Are you sure you want to create a new user for ${formData.Name}?`,
      action: async () => {
        setIsSubmitting(true);
        try {
          const url = editingUser
            ? `/api/auth/${editingUser.UserID}`
            : "/api/auth/register";
          const method = editingUser ? "PUT" : "POST";

          const response = await fetch(url, {
            method,
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(formData),
          });

          if (response.ok) {
            handleCloseDialog();
            fetchUsers();
            showSnackbar(
              editingUser
                ? "User updated successfully"
                : "User created successfully"
            );
          } else {
            const error = await response.json();
            showSnackbar(error.error || "Error saving user", "error");
          }
        } catch (error) {
          console.error("Error saving user:", error);
          showSnackbar("Error saving user", "error");
        } finally {
          setIsSubmitting(false);
        }
      },
    });
    setConfirmDialogOpen(true);
  };

  const handleConfirmDialogClose = () => {
    setConfirmDialogOpen(false);
    setConfirmDialogData(null);
  };

  const handleConfirmDialogConfirm = async () => {
    if (confirmDialogData?.action) {
      await confirmDialogData.action();
    }
    handleConfirmDialogClose();
  };

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this user?")) {
      try {
        const response = await fetch(`/api/auth/${id}`, {
          method: "DELETE",
        });

        if (response.ok) {
          fetchUsers();
          showSnackbar("User deleted successfully");
        } else {
          showSnackbar("Error deleting user", "error");
        }
      } catch (error) {
        console.error("Error deleting user:", error);
        showSnackbar("Error deleting user", "error");
      }
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const getRoleIcon = (role) => {
    switch (role) {
      case "Admin":
        return <AdminIcon fontSize="small" />;
      case "Staff":
        return <StaffIcon fontSize="small" />;
      case "Partner":
        return <PartnerIcon fontSize="small" />;
      default:
        return <PersonIcon fontSize="small" />;
    }
  };

  const getLevelIcon = (level) => {
    switch (level) {
      case "Full Access":
        return <FullAccessIcon fontSize="small" />;
      case "Mobile":
        return <MobileIcon fontSize="small" />;
      case "Portal":
        return <PortalIcon fontSize="small" />;
      default:
        return null;
    }
  };

  const getRoleColor = (role) => {
    switch (role) {
      case "Admin":
        return theme.palette.error.main;
      case "Staff":
        return theme.palette.primary.main;
      case "Partner":
        return theme.palette.success.main;
      default:
        return theme.palette.grey[500];
    }
  };

  return (
    <Box sx={{ p: 3, bgcolor: "#f5f5f9", minHeight: "100vh" }}>
      {/* Stats Cards */}
      <Grid container spacing={2} sx={{ mb: 3 }}>
        <Grid item xs={12} md={3}>
          <Fade in timeout={500}>
            <Card
              sx={{
                p: 3,
                height: "100%",
                background: "linear-gradient(135deg, #6B8DE6 0%, #5E76CE 100%)",
                color: "white",
                position: "relative",
                overflow: "hidden",
              }}
            >
              <Box
                sx={{
                  position: "absolute",
                  top: -20,
                  right: -20,
                  width: 150,
                  height: 150,
                  borderRadius: "50%",
                  background: "rgba(255,255,255,0.1)",
                }}
              />
              <Stack spacing={1}>
                <Typography variant="h3" fontWeight="bold">
                  {counts.total}
                </Typography>
                <Typography variant="subtitle2" sx={{ opacity: 0.8 }}>
                  Total Users
                </Typography>
              </Stack>
            </Card>
          </Fade>
        </Grid>
        <Grid item xs={12} md={3}>
          <Fade in timeout={700}>
            <Card
              sx={{
                p: 3,
                height: "100%",
                background: "linear-gradient(135deg, #FF8B8B 0%, #E96C6C 100%)",
                color: "white",
                position: "relative",
                overflow: "hidden",
              }}
            >
              <Box
                sx={{
                  position: "absolute",
                  top: -20,
                  right: -20,
                  width: 150,
                  height: 150,
                  borderRadius: "50%",
                  background: "rgba(255,255,255,0.1)",
                }}
              />
              <Stack spacing={1}>
                <Typography variant="h3" fontWeight="bold">
                  {counts.mobile}
                </Typography>
                <Typography variant="subtitle2" sx={{ opacity: 0.8 }}>
                  Mobile Users
                </Typography>
              </Stack>
            </Card>
          </Fade>
        </Grid>
        <Grid item xs={12} md={3}>
          <Fade in timeout={900}>
            <Card
              sx={{
                p: 3,
                height: "100%",
                background: "linear-gradient(135deg, #63D471 0%, #4CAF50 100%)",
                color: "white",
                position: "relative",
                overflow: "hidden",
              }}
            >
              <Box
                sx={{
                  position: "absolute",
                  top: -20,
                  right: -20,
                  width: 150,
                  height: 150,
                  borderRadius: "50%",
                  background: "rgba(255,255,255,0.1)",
                }}
              />
              <Stack spacing={1}>
                <Typography variant="h3" fontWeight="bold">
                  {counts.portal}
                </Typography>
                <Typography variant="subtitle2" sx={{ opacity: 0.8 }}>
                  Portal Users
                </Typography>
              </Stack>
            </Card>
          </Fade>
        </Grid>
        <Grid item xs={12} md={3}>
          <Fade in timeout={1100}>
            <Card
              sx={{
                p: 3,
                height: "100%",
                background: "linear-gradient(135deg, #FFD93D 0%, #FFB800 100%)",
                color: "white",
                position: "relative",
                overflow: "hidden",
              }}
            >
              <Box
                sx={{
                  position: "absolute",
                  top: -20,
                  right: -20,
                  width: 150,
                  height: 150,
                  borderRadius: "50%",
                  background: "rgba(255,255,255,0.1)",
                }}
              />
              <Stack spacing={1}>
                <Typography variant="h3" fontWeight="bold">
                  {counts.fullAccess}
                </Typography>
                <Typography variant="subtitle2" sx={{ opacity: 0.8 }}>
                  Full Access Users
                </Typography>
              </Stack>
            </Card>
          </Fade>
        </Grid>
      </Grid>

      {/* Search and Add Section */}
      <Card
        elevation={0}
        sx={{
          mb: 3,
          p: 2,
          background: "white",
          borderRadius: 3,
        }}
      >
        <Stack
          direction={{ xs: "column", sm: "row" }}
          spacing={2}
          alignItems="center"
          justifyContent="space-between"
        >
          <TextField
            placeholder="Search users..."
            value={searchQuery}
            onChange={handleSearch}
            size="small"
            sx={{
              minWidth: { xs: "100%", sm: 300 },
              "& .MuiOutlinedInput-root": {
                borderRadius: 3,
                bgcolor: "#f5f5f9",
                "&:hover": {
                  bgcolor: "#eeeef2",
                },
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon color="action" />
                </InputAdornment>
              ),
              endAdornment: isSearching && (
                <InputAdornment position="end">
                  <CircularProgress size={20} />
                </InputAdornment>
              ),
            }}
          />
          <ButtonBase
            onClick={() => handleOpenDialog()}
            sx={{
              bgcolor: theme.palette.primary.main,
              color: "white",
              py: 1,
              px: 3,
              borderRadius: 3,
              fontWeight: 600,
              transition: "all 0.2s",
              "&:hover": {
                bgcolor: theme.palette.primary.dark,
                transform: "translateY(-2px)",
                boxShadow: "0 4px 12px rgba(0,0,0,0.15)",
              },
            }}
          >
            <AddIcon sx={{ mr: 1 }} />
            Add New User
          </ButtonBase>
        </Stack>
      </Card>

      {/* Users Table */}
      <Grow in timeout={1000}>
        <Card
          elevation={0}
          sx={{
            borderRadius: 3,
            overflow: "hidden",
            "& .MuiTableRow-root:hover": {
              bgcolor: "#f5f5f9",
            },
          }}
        >
          <TableContainer>
            {loading ? (
              <Box sx={{ display: "flex", justifyContent: "center", p: 5 }}>
                <CircularProgress />
              </Box>
            ) : (
              <>
                <Table>
                  <TableHead>
                    <TableRow sx={{ bgcolor: "#f5f5f9" }}>
                      <TableCell sx={{ fontWeight: 600 }}>User</TableCell>
                      <TableCell sx={{ fontWeight: 600 }}>Contact</TableCell>
                      <TableCell sx={{ fontWeight: 600 }}>Partner</TableCell>
                      <TableCell sx={{ fontWeight: 600 }}>
                        Access Level
                      </TableCell>
                      <TableCell sx={{ fontWeight: 600 }}>Role</TableCell>
                      <TableCell align="right" sx={{ fontWeight: 600 }}>
                        Actions
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(searchQuery ? searchResults : users).map((user) => (
                      <TableRow
                        key={user.UserID}
                        sx={{
                          transition: "all 0.2s",
                          "&:hover": {
                            transform: "translateY(-2px)",
                            boxShadow: "0 4px 12px rgba(0,0,0,0.05)",
                          },
                        }}
                      >
                        <TableCell>
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                          >
                            <Avatar
                              sx={{
                                width: 45,
                                height: 45,
                                bgcolor: alpha(getRoleColor(user.Role), 0.1),
                                color: getRoleColor(user.Role),
                                fontSize: "1.2rem",
                                fontWeight: 600,
                              }}
                            >
                              {user.Name.split(" ")
                                .map((n) => n[0])
                                .join("")}
                            </Avatar>
                            <Box>
                              <Typography variant="subtitle1" fontWeight={600}>
                                {user.Name}
                              </Typography>
                              <Typography
                                variant="caption"
                                color="text.secondary"
                              >
                                {user.Email}
                              </Typography>
                            </Box>
                          </Stack>
                        </TableCell>
                        <TableCell>{user.Phone}</TableCell>
                        <TableCell>
                          <Chip
                            size="small"
                            label={user.Partner}
                            sx={{ borderRadius: 1 }}
                          />
                        </TableCell>
                        <TableCell>
                          <Chip
                            size="small"
                            icon={getLevelIcon(user.Level)}
                            label={user.Level}
                            sx={{
                              borderRadius: 1,
                              bgcolor: alpha(theme.palette.info.main, 0.1),
                              color: theme.palette.info.main,
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <Chip
                            size="small"
                            icon={getRoleIcon(user.Role)}
                            label={user.Role}
                            sx={{
                              borderRadius: 1,
                              bgcolor: alpha(getRoleColor(user.Role), 0.1),
                              color: getRoleColor(user.Role),
                            }}
                          />
                        </TableCell>
                        <TableCell align="right">
                          <Stack
                            direction="row"
                            spacing={1}
                            justifyContent="flex-end"
                          >
                            <IconButton
                              onClick={() => handleOpenDialog(user)}
                              size="small"
                              sx={{
                                color: "primary.main",
                                bgcolor: alpha(theme.palette.primary.main, 0.1),
                                "&:hover": {
                                  bgcolor: alpha(
                                    theme.palette.primary.main,
                                    0.2
                                  ),
                                },
                              }}
                            >
                              <EditIcon fontSize="small" />
                            </IconButton>
                            <IconButton
                              onClick={() => handleDelete(user.UserID)}
                              size="small"
                              sx={{
                                color: "error.main",
                                bgcolor: alpha(theme.palette.error.main, 0.1),
                                "&:hover": {
                                  bgcolor: alpha(theme.palette.error.main, 0.2),
                                },
                              }}
                            >
                              <DeleteIcon fontSize="small" />
                            </IconButton>
                          </Stack>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                {!searchQuery && (
                  <Box sx={{ p: 2 }}>
                    <TablePagination
                      component="div"
                      count={-1}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      rowsPerPageOptions={[10]}
                    />
                  </Box>
                )}
              </>
            )}
          </TableContainer>
        </Card>
      </Grow>

      {/* Update Dialog styles */}
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        maxWidth="md"
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: 3,
            boxShadow: "0 8px 32px rgba(0,0,0,0.1)",
          },
        }}
      >
        <DialogTitle sx={{ pb: 1 }}>
          <Typography variant="h6" component="div" sx={{ fontWeight: 600 }}>
            {editingUser ? "Edit User" : "Add New User"}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {editingUser
              ? "Update the user's information"
              : "Enter the details for the new user"}
          </Typography>
        </DialogTitle>
        <Divider />
        <DialogContent sx={{ py: 3 }}>
          <Box
            sx={{
              display: "grid",
              gap: 3,
              gridTemplateColumns: "1fr 1fr",
            }}
          >
            <TextField
              label="Name"
              value={formData.Name}
              onChange={(e) =>
                setFormData({ ...formData, Name: e.target.value })
              }
              fullWidth
              size="small"
              required
            />
            <TextField
              label="Email"
              type="email"
              value={formData.Email}
              onChange={(e) =>
                setFormData({ ...formData, Email: e.target.value })
              }
              fullWidth
              size="small"
              required
            />
            <TextField
              label="Phone"
              value={formData.Phone}
              onChange={(e) =>
                setFormData({ ...formData, Phone: e.target.value })
              }
              fullWidth
              size="small"
              required
            />
            <Autocomplete
              options={partners}
              getOptionLabel={(option) => option.Name || ""}
              value={partners.find((p) => p.Name === formData.Partner) || null}
              onChange={(event, newValue) =>
                setFormData({ ...formData, Partner: newValue?.Name || "" })
              }
              renderInput={(params) => (
                <TextField {...params} label="Partner" size="small" required />
              )}
            />
            <FormControl fullWidth size="small" required>
              <InputLabel>Level</InputLabel>
              <Select
                value={formData.Level}
                label="Level"
                onChange={(e) =>
                  setFormData({ ...formData, Level: e.target.value })
                }
              >
                {LEVELS.map((level) => (
                  <MenuItem key={level} value={level}>
                    {level}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth size="small" required>
              <InputLabel>Role</InputLabel>
              <Select
                value={formData.Role}
                label="Role"
                onChange={(e) =>
                  setFormData({ ...formData, Role: e.target.value })
                }
              >
                {ROLES.map((role) => (
                  <MenuItem key={role} value={role}>
                    {role}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {!editingUser && (
              <>
                <TextField
                  label="Password"
                  type="password"
                  value={formData.Password}
                  onChange={(e) =>
                    setFormData({ ...formData, Password: e.target.value })
                  }
                  fullWidth
                  size="small"
                  required
                />
                <TextField
                  label="Confirm Password"
                  type="password"
                  value={formData.ConfirmPassword}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      ConfirmPassword: e.target.value,
                    })
                  }
                  fullWidth
                  size="small"
                  required
                  error={
                    formData.Password !== formData.ConfirmPassword &&
                    formData.ConfirmPassword !== ""
                  }
                  helperText={
                    formData.Password !== formData.ConfirmPassword &&
                    formData.ConfirmPassword !== ""
                      ? "Passwords do not match"
                      : ""
                  }
                />
              </>
            )}
          </Box>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ p: 2, gap: 1 }}>
          <Button
            onClick={handleCloseDialog}
            disabled={isSubmitting}
            sx={{
              textTransform: "none",
              borderRadius: 1,
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            variant="contained"
            disabled={isSubmitting}
            sx={{
              textTransform: "none",
              borderRadius: 1,
              px: 3,
              minWidth: 120,
              position: "relative",
            }}
          >
            {isSubmitting ? (
              <>
                <CircularProgress
                  size={20}
                  sx={{
                    position: "absolute",
                    left: "50%",
                    marginLeft: "-10px",
                    color: "white",
                  }}
                />
                <span style={{ opacity: 0 }}>Creating...</span>
              </>
            ) : editingUser ? (
              "Save Changes"
            ) : (
              "Create User"
            )}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Confirmation Dialog */}
      <Dialog
        open={confirmDialogOpen}
        onClose={handleConfirmDialogClose}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle>
          <Typography variant="h6" component="div">
            {confirmDialogData?.title}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography>{confirmDialogData?.message}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmDialogClose}>Cancel</Button>
          <Button
            onClick={handleConfirmDialogConfirm}
            variant="contained"
            color="primary"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Users;
