import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  Grid,
  Paper,
  Tabs,
  Tab,
  TextField,
  IconButton,
  Tooltip,
  MenuItem,
  Select,
  CircularProgress,
} from "@mui/material";
import {
  BarChart,
  Assessment,
  CalendarToday,
  FilterList,
  Download,
  Refresh,
} from "@mui/icons-material";
import { alpha } from "@mui/material/styles";
import theme from "../../theme";
import XLSX from "xlsx-js-style";

// Import components (you'll need to create these)
import Top from "./Components/FrameworkDashboard";
import ResultsAll from "./Components/ResultsAll";
import CompetenciesAll from "./Components/CompetenciesAll";
import Assessments from "./Components/Assessments";
import FarrierTrendDialog from "./Components/TrendDialogs/FarrierTrendDialog";

// Add TabPanel component definition
function TabPanel({ children, value, index, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

const FMF = () => {
  // Same state management as AMF
  const getCurrentFinancialYear = () => {
    const today = new Date();
    const currentMonth = today.getMonth();
    return currentMonth < 3 ? today.getFullYear() - 1 : today.getFullYear();
  };

  const [data, setData] = useState({});
  const [charts, setCharts] = useState(null);
  const [cCharts, setCcharts] = useState(null);
  const [selectedTab, setSelectedTab] = useState(0);
  const [year, setYear] = useState(getCurrentFinancialYear().toString());
  const [activeQuarter, setActiveQuarter] = useState(year);
  const [dateRange, setDateRange] = useState({
    start: "",
    end: "",
  });
  const [isExporting, setIsExporting] = useState(false);
  const [competencyData, setCompetencyData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [summaryData, setSummaryData] = useState(null);
  const [resultsData, setResultsData] = useState(null);
  const [assessmentData, setAssessmentData] = useState(null);
  const [summaryLoading, setSummaryLoading] = useState(false);

  const jwt = require("jsonwebtoken");

  // Get partner from token
  const getPartner = () => {
    try {
      const token = localStorage.getItem("gdfhgfhtkn");
      if (!token) {
        console.error("No token found in localStorage");
        return null;
      }

      const decoded = jwt.decode(token);
      if (!decoded?.Partner) {
        console.error("No Partner found in token:", decoded);
        return null;
      }
      return decoded.Partner;
    } catch (error) {
      console.error("Error decoding token:", error);
      return null;
    }
  };

  const partner = getPartner();

  // Initialize date range on component mount
  useEffect(() => {
    const financialYear = getCurrentFinancialYear();
    const nextYear = financialYear + 1;
    setDateRange({
      start: `${financialYear}-04-01`,
      end: `${nextYear}-03-31`,
    });
  }, []);

  // Update date range when quarter or year changes
  useEffect(() => {
    const financialYear = Number(year);
    const nextYear = financialYear + 1;

    switch (activeQuarter) {
      case "Q1":
        setDateRange({
          start: `${financialYear}-04-01`,
          end: `${financialYear}-06-30`,
        });
        break;
      case "Q2":
        setDateRange({
          start: `${financialYear}-07-01`,
          end: `${financialYear}-09-30`,
        });
        break;
      case "Q3":
        setDateRange({
          start: `${financialYear}-10-01`,
          end: `${financialYear}-12-31`,
        });
        break;
      case "Q4":
        setDateRange({
          start: `${nextYear}-01-01`,
          end: `${nextYear}-03-31`,
        });
        break;
      case year:
        setDateRange({
          start: `${financialYear}-04-01`,
          end: `${nextYear}-03-31`,
        });
        break;
      default:
        break;
    }
  }, [activeQuarter, year]);

  // Reset dates to current quarter
  const resetDates = () => {
    const today = new Date();
    const currentMonth = today.getMonth();

    let currentQuarter;
    if (currentMonth >= 3 && currentMonth <= 5) currentQuarter = "Q1";
    else if (currentMonth >= 6 && currentMonth <= 8) currentQuarter = "Q2";
    else if (currentMonth >= 9 && currentMonth <= 11) currentQuarter = "Q3";
    else currentQuarter = "Q4";

    setYear(getCurrentFinancialYear().toString());
    setActiveQuarter(currentQuarter);
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const getYearOptions = () => {
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let i = currentYear - 3; i <= currentYear + 3; i++) {
      years.push(i);
    }
    return years;
  };

  const onCompetencyDataLoad = (data) => {
    setCompetencyData(data);
    setLoading(false);
  };

  // UI Structure - Same as AMF but with FMF specific labels
  return (
    <Box sx={{ p: 2.5, maxWidth: 1400, margin: "0 auto" }}>
      {/* Header Tabs */}
      <Paper
        elevation={1}
        sx={{
          mb: 3,
          borderRadius: 1.5,
          overflow: "hidden",
          boxShadow: "0 1px 2px rgba(0,0,0,0.05)",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          pr: 1.5,
          background: theme.palette.background.paper,
        }}
      >
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
          sx={{
            flex: 1,
            minHeight: 48,
            "& .MuiTab-root": {
              minHeight: 48,
              minWidth: 140,
              fontWeight: 500,
              fontSize: "0.875rem",
              textTransform: "none",
              py: 1.5,
              transition: "all 0.2s ease",
              opacity: 0.7,
              "&:hover": {
                opacity: 1,
                backgroundColor: alpha(theme.palette.primary.main, 0.02),
              },
              "&.Mui-selected": {
                opacity: 1,
                fontWeight: 600,
              },
              "& .MuiSvgIcon-root": {
                fontSize: "1.1rem",
                mb: "1px !important",
                mr: 0.5,
              },
            },
            "& .MuiTabs-indicator": {
              height: 2,
            },
          }}
        >
          <Tab
            label="Summary"
            icon={<BarChart sx={{ mb: 0.5 }} />}
            iconPosition="start"
          />
          <Tab
            label="Assessments"
            icon={<CalendarToday sx={{ mb: 0.5 }} />}
            iconPosition="start"
          />
          <Tab
            label="Results"
            icon={<Assessment sx={{ mb: 0.5 }} />}
            iconPosition="start"
          />
          <Tab
            label="Competencies"
            icon={<FilterList sx={{ mb: 0.5 }} />}
            iconPosition="start"
          />
        </Tabs>
      </Paper>

      {/* Filters Section */}
      <Paper
        elevation={1}
        sx={{
          mb: 3,
          borderRadius: 1.5,
          background: "#fff",
          boxShadow: "0 1px 2px rgba(0,0,0,0.05)",
          overflow: "hidden",
        }}
      >
        {/* Filter content */}
        <Box sx={{ p: 2.5 }}>
          <Grid container spacing={3}>
            {/* Year and Quarter Selection */}
            <Grid item xs={12} md={6}>
              <Box>
                <Typography
                  variant="caption"
                  sx={{
                    fontWeight: 600,
                    color: theme.palette.text.secondary,
                    display: "block",
                    mb: 1,
                  }}
                >
                  FINANCIAL YEAR
                </Typography>
                <Box sx={{ display: "flex", gap: 1.5, alignItems: "center" }}>
                  <Select
                    value={year}
                    onChange={(e) => {
                      setYear(e.target.value);
                      setActiveQuarter(e.target.value);
                      const nextYear = Number(e.target.value) + 1;
                      setDateRange({
                        start: `${e.target.value}-04-01`,
                        end: `${nextYear}-03-31`,
                      });
                    }}
                    size="small"
                    sx={{
                      minWidth: 130,
                      height: 32,
                      fontSize: "0.875rem",
                      backgroundColor: "#fff",
                      boxShadow: "0 1px 2px rgba(0,0,0,0.05)",
                      ".MuiOutlinedInput-notchedOutline": {
                        borderColor: alpha(theme.palette.divider, 0.2),
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: theme.palette.primary.main,
                      },
                    }}
                  >
                    {getYearOptions().map((yearOption) => (
                      <MenuItem key={yearOption} value={yearOption.toString()}>
                        FY {yearOption}/{yearOption + 1}
                      </MenuItem>
                    ))}
                  </Select>

                  <Box
                    sx={{
                      display: "flex",
                      gap: 0.5,
                      ".MuiButton-root": {
                        height: 32,
                        minWidth: "auto",
                        px: 1.5,
                        fontSize: "0.8rem",
                        fontWeight: 500,
                        borderRadius: "4px",
                        textTransform: "none",
                        borderColor: alpha(theme.palette.divider, 0.15),
                        color: theme.palette.text.secondary,
                        backgroundColor: "#fff",
                        boxShadow: "0 1px 2px rgba(0,0,0,0.05)",
                        "&:hover": {
                          borderColor: theme.palette.primary.main,
                          backgroundColor: "#fff",
                        },
                        "&.MuiButton-contained": {
                          backgroundColor: theme.palette.primary.main,
                          color: "white",
                          borderColor: theme.palette.primary.main,
                          "&:hover": {
                            backgroundColor: theme.palette.primary.dark,
                          },
                        },
                      },
                    }}
                  >
                    <Button
                      variant={
                        activeQuarter === year ? "contained" : "outlined"
                      }
                      onClick={() => {
                        setActiveQuarter(year);
                        const nextYear = Number(year) + 1;
                        setDateRange({
                          start: `${year}-04-01`,
                          end: `${nextYear}-03-31`,
                        });
                      }}
                    >
                      Full Year
                    </Button>
                    {["Q1", "Q2", "Q3", "Q4"].map((quarter) => (
                      <Button
                        key={quarter}
                        variant={
                          activeQuarter === quarter ? "contained" : "outlined"
                        }
                        onClick={() => setActiveQuarter(quarter)}
                      >
                        {quarter}
                      </Button>
                    ))}
                  </Box>
                </Box>
              </Box>
            </Grid>

            {/* Custom Date Range */}
            <Grid item xs={12} md={6}>
              <Box>
                <Typography
                  variant="caption"
                  sx={{
                    fontWeight: 600,
                    color: theme.palette.text.secondary,
                    display: "block",
                    mb: 1,
                  }}
                >
                  CUSTOM RANGE
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    gap: 1.5,
                    alignItems: "center",
                    ".MuiTextField-root": {
                      flex: 1,
                      maxWidth: 160,
                      "& .MuiOutlinedInput-root": {
                        height: 32,
                        fontSize: "0.875rem",
                        backgroundColor: "#fff",
                        boxShadow: "0 1px 2px rgba(0,0,0,0.05)",
                        "& fieldset": {
                          borderColor: alpha(theme.palette.divider, 0.2),
                        },
                        "&:hover fieldset": {
                          borderColor: theme.palette.primary.main,
                        },
                      },
                    },
                  }}
                >
                  <TextField
                    type="date"
                    size="small"
                    value={dateRange.start}
                    onChange={(e) =>
                      setDateRange((prev) => ({
                        ...prev,
                        start: e.target.value,
                      }))
                    }
                    InputLabelProps={{ shrink: true }}
                  />
                  <TextField
                    type="date"
                    size="small"
                    value={dateRange.end}
                    onChange={(e) =>
                      setDateRange((prev) => ({ ...prev, end: e.target.value }))
                    }
                    InputLabelProps={{ shrink: true }}
                  />
                  <Button
                    variant="outlined"
                    onClick={resetDates}
                    startIcon={<Refresh sx={{ fontSize: "1rem" }} />}
                    size="small"
                    sx={{
                      height: 32,
                      px: 1.5,
                      fontSize: "0.8rem",
                      fontWeight: 500,
                      textTransform: "none",
                      borderColor: alpha(theme.palette.divider, 0.15),
                      color: theme.palette.text.secondary,
                      backgroundColor: "#fff",
                      boxShadow: "0 1px 2px rgba(0,0,0,0.05)",
                      "&:hover": {
                        borderColor: theme.palette.primary.main,
                        backgroundColor: "#fff",
                      },
                    }}
                  >
                    Reset
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Paper>

      {/* Content Section */}
      <Box
        sx={{
          mt: 3,
          backgroundColor: "#fff",
          borderRadius: 1.5,
          boxShadow: "0 1px 2px rgba(0,0,0,0.05)",
          p: 2.5,
        }}
      >
        <TabPanel value={selectedTab} index={0}>
          <Top framework="farriers" partner={partner} dateRange={dateRange} />
        </TabPanel>
        <TabPanel value={selectedTab} index={1}>
          <Assessments
            title="FMF"
            partner={partner}
            start={dateRange.start}
            end={dateRange.end}
            setDateRange={setDateRange}
          />
        </TabPanel>
        <TabPanel value={selectedTab} index={2}>
          <ResultsAll
            title="FMF"
            partner={partner}
            start={dateRange.start}
            end={dateRange.end}
          />
        </TabPanel>
        <TabPanel value={selectedTab} index={3}>
          <CompetenciesAll
            title="FMF"
            partner={partner}
            start={dateRange.start}
            end={dateRange.end}
            onDataLoad={(data) => {
              setCompetencyData(data);
            }}
            framework="FMF"
            hideSearch={false}
          />
        </TabPanel>
      </Box>
    </Box>
  );
};

export default FMF;
