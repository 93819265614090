import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Paper,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  IconButton,
  Tooltip,
  Snackbar,
  Alert,
  Card,
  CardContent,
  CardActions,
  Chip,
  Tabs,
  Tab,
  Divider,
  CircularProgress,
  Pagination,
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Skeleton,
  useTheme,
  InputAdornment,
  LinearProgress,
} from "@mui/material";
import { alpha } from "@mui/system";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import FlagIcon from "@mui/icons-material/Flag";
import TimelineIcon from "@mui/icons-material/Timeline";
import AssessmentIcon from "@mui/icons-material/Assessment";
import DescriptionIcon from "@mui/icons-material/Description";
import SearchIcon from "@mui/icons-material/Search";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import ClearIcon from "@mui/icons-material/Clear";
import Collapse from "@mui/material/Collapse";
import Fade from "@mui/material/Fade";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as RechartsTooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const ITEMS_PER_PAGE = 9; // 3x3 grid

const Loading = () => {
  const theme = useTheme();
  const [progress, setProgress] = React.useState(0);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          return 0;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <Box sx={{ p: 3 }}>
      {/* Main Loading Progress */}
      <Box
        sx={{
          position: "relative",
          mb: 6,
          p: 3,
          borderRadius: 2,
          bgcolor: (theme) => alpha(theme.palette.primary.main, 0.03),
          border: 1,
          borderColor: "divider",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
          <CircularProgress
            variant="determinate"
            value={progress}
            size={48}
            thickness={4}
            sx={{
              mr: 2,
              color: "primary.main",
            }}
          />
          <Box sx={{ flex: 1 }}>
            <Typography
              variant="h6"
              color="primary.main"
              gutterBottom
              sx={{
                fontWeight: 600,
                fontSize: "1.25rem",
                letterSpacing: "-0.5px",
              }}
            >
              Loading MEL Hierarchy
            </Typography>
            <LinearProgress
              variant="determinate"
              value={progress}
              sx={{
                height: 6,
                borderRadius: 1,
                bgcolor: (theme) => alpha(theme.palette.primary.main, 0.08),
                "& .MuiLinearProgress-bar": {
                  borderRadius: 1,
                },
              }}
            />
          </Box>
        </Box>
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            ml: 8,
            fontSize: "0.875rem",
            fontWeight: 500,
          }}
        >
          Fetching data from {Math.round(progress)}% of components...
        </Typography>
      </Box>

      {/* Animated Loading Cards */}
      <Grid container spacing={3}>
        {[1, 2, 3, 4].map((index) => (
          <Grid item xs={12} md={3} key={index}>
            <Paper
              elevation={0}
              sx={{
                p: 3,
                height: "100%",
                bgcolor: "background.paper",
                borderRadius: 2,
                border: 1,
                borderColor: "divider",
                position: "relative",
                overflow: "hidden",
                "&::after": {
                  content: '""',
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  background: `linear-gradient(90deg, transparent, ${alpha(
                    theme.palette.primary.main,
                    0.04
                  )}, transparent)`,
                  animation: "shimmer 1.5s infinite",
                },
              }}
            >
              <Stack spacing={2}>
                <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                  <Skeleton
                    variant="circular"
                    width={40}
                    height={40}
                    sx={{ mr: 2 }}
                  />
                  <Box sx={{ flex: 1 }}>
                    <Skeleton variant="text" width="60%" height={24} />
                    <Skeleton variant="text" width="40%" height={20} />
                  </Box>
                </Box>
                <Skeleton
                  variant="rectangular"
                  height={60}
                  sx={{ borderRadius: 1 }}
                />
                <Box sx={{ display: "flex", gap: 1 }}>
                  <Skeleton variant="rounded" width={80} height={32} />
                  <Skeleton variant="rounded" width={80} height={32} />
                </Box>
              </Stack>
            </Paper>
          </Grid>
        ))}
      </Grid>

      {/* Loading Table */}
      <Paper
        elevation={0}
        sx={{
          mt: 3,
          p: 3,
          borderRadius: 2,
          border: 1,
          borderColor: "divider",
        }}
      >
        <Stack spacing={2}>
          <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
            <Skeleton
              variant="circular"
              width={32}
              height={32}
              sx={{ mr: 2 }}
            />
            <Skeleton variant="text" width="30%" height={32} />
          </Box>
          {[1, 2, 3].map((row) => (
            <Box
              key={row}
              sx={{
                display: "flex",
                alignItems: "center",
                p: 2,
                borderRadius: 1,
                bgcolor: (theme) => alpha(theme.palette.primary.main, 0.02),
              }}
            >
              <Skeleton
                variant="circular"
                width={24}
                height={24}
                sx={{ mr: 2 }}
              />
              <Box sx={{ flex: 1 }}>
                <Skeleton variant="text" width="80%" height={24} />
                <Skeleton variant="text" width="40%" height={20} />
              </Box>
              <Skeleton variant="rounded" width={100} height={36} />
            </Box>
          ))}
        </Stack>
      </Paper>

      <style>
        {`
          @keyframes shimmer {
            0% {
              transform: translateX(-100%);
            }
            100% {
              transform: translateX(100%);
            }
          }
        `}
      </style>
    </Box>
  );
};

const StatCard = ({ title, value, icon, color }) => (
  <Paper
    elevation={0}
    sx={{
      p: 2.5,
      height: "100%",
      bgcolor: alpha(color, 0.1),
      border: 1,
      borderColor: alpha(color, 0.2),
      borderRadius: 2,
    }}
  >
    <Stack direction="row" alignItems="center" spacing={2}>
      <Box sx={{ color: color }}>{icon}</Box>
      <Box>
        <Typography
          color="text.secondary"
          variant="body2"
          gutterBottom
          sx={{
            fontSize: "0.75rem", // Reduced font size
            letterSpacing: "0.5px",
            fontWeight: 600,
            textTransform: "uppercase",
            opacity: 0.8,
          }}
        >
          {title}
        </Typography>
        <Typography
          variant="h5"
          component="div"
          sx={{
            fontWeight: 600,
            color: color,
            fontSize: "1.5rem", // Reduced font size
            lineHeight: 1,
          }}
        >
          {value}
        </Typography>
      </Box>
    </Stack>
  </Paper>
);

const MELHierarchy = () => {
  const theme = useTheme();
  // State for data
  const [goals, setGoals] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");
  const [selectedTab, setSelectedTab] = useState(0);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  // State for dialogs
  const [openDialog, setOpenDialog] = useState(false);
  const [editDialog, setEditDialog] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [dialogType, setDialogType] = useState("");

  // State for form data
  const [formData, setFormData] = useState({
    code: "",
    name: "",
    description: "",
    baseline: "",
    targetAnnual: "",
    targetQ1: "",
    targetQ2: "",
    targetQ3: "",
    targetQ4: "",
    goal_id: "",
    so_id: "",
    io_id: "",
    output_id: "",
  });

  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [performanceForm, setPerformanceForm] = useState({
    quarter: "",
    value: "",
    year: new Date().getFullYear(),
  });

  // Fetch all data
  useEffect(() => {
    fetchHierarchyData();
  }, []);

  const fetchHierarchyData = async () => {
    try {
      setLoading(true);
      const storedUser = localStorage.getItem("user");
      let userPartner;

      if (storedUser) {
        try {
          const userData = JSON.parse(storedUser);
          userPartner = userData?.Partner || userData?.partner;
        } catch (error) {
          console.error("Error parsing user data:", error);
        }
      }

      if (!userPartner) {
        console.warn("No partner found in user data");
        return;
      }

      // Fetch goals
      const goalsResponse = await fetch("/api/goals");
      if (!goalsResponse.ok) {
        throw new Error(`HTTP error! status: ${goalsResponse.status}`);
      }
      const goalsData = await goalsResponse.json();

      if (!goalsData.success) {
        throw new Error("Failed to fetch goals data");
      }

      // Fetch strategic outcomes for each goal
      const goalsWithSOs = await Promise.all(
        goalsData.data.map(async (goal) => {
          try {
            const soResponse = await fetch(
              `/api/strategic-outcomes/goal/${goal.ID}`
            );
            if (!soResponse.ok) {
              console.warn(`Failed to fetch SOs for goal ${goal.ID}`);
              return {
                ...goal,
                strategicOutcomes: [],
              };
            }
            const soData = await soResponse.json();

            if (!soData.success || !soData.data) {
              console.warn(`No strategic outcomes data for goal ${goal.ID}`);
              return {
                ...goal,
                strategicOutcomes: [],
              };
            }

            // Fetch intermediate outcomes for each strategic outcome
            const sosWithIOs = await Promise.all(
              soData.data.map(async (so) => {
                try {
                  const ioResponse = await fetch(
                    `/api/intermediate-outcomes/so/${so.ID}`
                  );
                  if (!ioResponse.ok) {
                    console.warn(`Failed to fetch IOs for SO ${so.ID}`);
                    return {
                      ...so,
                      intermediateOutcomes: [],
                    };
                  }
                  const ioData = await ioResponse.json();

                  if (!ioData.success || !ioData.data) {
                    console.warn(
                      `No intermediate outcomes data for SO ${so.ID}`
                    );
                    return {
                      ...so,
                      intermediateOutcomes: [],
                    };
                  }

                  // Fetch outputs for each intermediate outcome
                  const iosWithOutputs = await Promise.all(
                    ioData.data.map(async (io) => {
                      try {
                        const outputsResponse = await fetch(
                          `/api/outputs/io/${userPartner}/${io.ID}`
                        );
                        if (!outputsResponse.ok) {
                          console.warn(
                            `Failed to fetch outputs for IO ${io.ID}`
                          );
                          return {
                            ...io,
                            outputs: [],
                          };
                        }
                        const outputsData = await outputsResponse.json();

                        if (!outputsData.success || !outputsData.data) {
                          console.warn(`No outputs data for IO ${io.ID}`);
                          return {
                            ...io,
                            outputs: [],
                          };
                        }

                        // Fetch indicators for each output
                        const outputsWithIndicators = await Promise.all(
                          outputsData.data.map(async (output) => {
                            try {
                              const indicatorsResponse = await fetch(
                                `/api/indicators/output/${output.ID}/0`
                              );
                              if (!indicatorsResponse.ok) {
                                console.warn(
                                  `Failed to fetch indicators for output ${output.ID}: ${indicatorsResponse.status} ${indicatorsResponse.statusText}`
                                );
                                return {
                                  ...output,
                                  indicators: [],
                                };
                              }
                              const indicatorsData =
                                await indicatorsResponse.json();

                              if (
                                !indicatorsData.success ||
                                !indicatorsData.data
                              ) {
                                console.warn(
                                  `No indicators data for output ${
                                    output.ID
                                  }: ${indicatorsData.error || "Unknown error"}`
                                );
                                return {
                                  ...output,
                                  indicators: [],
                                };
                              }

                              // Filter indicators based on partner
                              const filteredIndicators =
                                indicatorsData.data.filter(
                                  (indicator) =>
                                    output.Partner === userPartner ||
                                    userPartner === "Brooke East Africa"
                                );

                              return {
                                ...output,
                                indicators: filteredIndicators,
                              };
                            } catch (error) {
                              console.error(
                                `Error fetching indicators for output ${output.ID}:`,
                                error
                              );
                              return {
                                ...output,
                                indicators: [],
                              };
                            }
                          })
                        );

                        return {
                          ...io,
                          outputs: outputsWithIndicators,
                        };
                      } catch (error) {
                        console.error(
                          `Error fetching outputs for IO ${io.ID}:`,
                          error
                        );
                        return {
                          ...io,
                          outputs: [],
                        };
                      }
                    })
                  );

                  return {
                    ...so,
                    intermediateOutcomes: iosWithOutputs,
                  };
                } catch (error) {
                  console.error(`Error fetching IOs for SO ${so.ID}:`, error);
                  return {
                    ...so,
                    intermediateOutcomes: [],
                  };
                }
              })
            );

            return {
              ...goal,
              strategicOutcomes: sosWithIOs,
            };
          } catch (error) {
            console.error(`Error fetching SOs for goal ${goal.ID}:`, error);
            return {
              ...goal,
              strategicOutcomes: [],
            };
          }
        })
      );

      setGoals(goalsWithSOs);
      setLoading(false);
    } catch (err) {
      console.error("Error fetching hierarchy data:", err);
      setError(
        "Failed to fetch hierarchy data. Please check the console for details."
      );
      setLoading(false);
    }
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
    setPage(1);
  };

  const getPaginatedData = (data) => {
    const startIndex = (page - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    return data.slice(startIndex, endIndex);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  // Calculate total pages based on selected tab
  useEffect(() => {
    let totalItems = 0;
    switch (selectedTab) {
      case 0: // Goals
        totalItems = goals.length;
        break;
      case 1: // Strategic Outcomes
        totalItems = goals.flatMap((goal) => goal.strategicOutcomes).length;
        break;
      case 2: // Intermediate Outcomes
        totalItems = goals.flatMap((goal) =>
          goal.strategicOutcomes.flatMap((so) => so.intermediateOutcomes)
        ).length;
        break;
      case 3: // Outputs
        totalItems = goals.flatMap((goal) =>
          goal.strategicOutcomes.flatMap((so) =>
            so.intermediateOutcomes.flatMap((io) => io.outputs)
          )
        ).length;
        break;
      case 4: // Indicators
        const allIndicators = goals.flatMap((goal) =>
          goal.strategicOutcomes.flatMap((so) =>
            so.intermediateOutcomes.flatMap((io) =>
              io.outputs.flatMap((output) => output.indicators)
            )
          )
        );
        // Filter indicators based on search query
        const filteredIndicators = allIndicators.filter((indicator) => {
          if (!indicator) return false;

          const searchTerms = searchQuery
            .toLowerCase()
            .split(" ")
            .filter((term) => term.length > 0);
          if (searchTerms.length === 0) return true;

          // Get all searchable text fields
          const searchableFields = [
            indicator.Description || "",
            indicator.Code || "",
            indicator.output?.Description || "",
            indicator.io?.Name || "",
            indicator.so?.Name || "",
            indicator.goal?.Name || "",
          ].map((field) => field.toLowerCase());

          // Check if any search term matches any field
          return searchTerms.some((term) =>
            searchableFields.some((field) => field.includes(term))
          );
        });
        totalItems = filteredIndicators.length;
        break;
      default:
        totalItems = 0;
    }
    setTotalPages(Math.ceil(totalItems / ITEMS_PER_PAGE));
  }, [selectedTab, goals, searchQuery]);

  // Get all indicators with hierarchy info
  const getAllIndicators = () => {
    return goals.flatMap((goal) =>
      goal.strategicOutcomes.flatMap((so) =>
        so.intermediateOutcomes.flatMap((io) =>
          io.outputs.flatMap((output) =>
            output.indicators.map((indicator) => ({
              ...indicator,
              output: output,
              io: io,
              so: so,
              goal: goal,
            }))
          )
        )
      )
    );
  };

  // Filter indicators based on search query
  const getFilteredIndicators = () => {
    const allIndicators = getAllIndicators();

    if (!searchQuery) return allIndicators;

    return allIndicators.filter((indicator) => {
      if (!indicator) return false;

      const searchTerms = searchQuery
        .toLowerCase()
        .split(" ")
        .filter((term) => term.length > 0);
      if (searchTerms.length === 0) return true;

      // Get all searchable text fields
      const searchableFields = [
        indicator.Description || "",
        indicator.Code || "",
        indicator.output?.Description || "",
        indicator.io?.Name || "",
        indicator.so?.Name || "",
        indicator.goal?.Name || "",
      ].map((field) => field.toLowerCase());

      // Check if any search term matches any field
      return searchTerms.some((term) =>
        searchableFields.some((field) => field.includes(term))
      );
    });
  };

  const renderGoals = () => {
    if (!goals || goals.length === 0) {
      return (
        <Box
          sx={{
            textAlign: "center",
            py: 4,
            bgcolor: (theme) => alpha(theme.palette.warning.main, 0.05),
            borderRadius: 2,
            border: 1,
            borderColor: (theme) => alpha(theme.palette.warning.main, 0.1),
          }}
        >
          <Typography
            color="text.secondary"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: 1,
              fontSize: "0.175rem",
              fontWeight: 100,
            }}
          >
            <ErrorIcon color="warning" />
            No goals found. Create a new goal to get started.
          </Typography>
        </Box>
      );
    }

    const paginatedGoals = getPaginatedData(goals);
    return (
      <>
        <Grid container spacing={3}>
          {paginatedGoals.map((goal) => (
            <Grid item xs={12} md={6} lg={4} key={goal.ID}>
              <Card
                sx={{
                  height: "100%",
                  borderRadius: 2,
                  transition: "transform 0.2s, box-shadow 0.2s",
                  "&:hover": {
                    transform: "translateY(-4px)",
                    boxShadow: (theme) => theme.shadows[4],
                  },
                }}
              >
                <CardContent sx={{ p: 2.5 }}>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    mb={2}
                  >
                    <Box sx={{ flex: 1, mr: 2 }}>
                      <Typography
                        variant="subtitle1"
                        component="div"
                        sx={{
                          fontWeight: 600,
                          mb: 0.75,
                          color: "text.primary",
                          fontSize: "0.8rem", // Reduced font size
                          letterSpacing: "-0.2px",
                          lineHeight: 1.3,
                        }}
                      >
                        {goal.Name}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{
                          mb: 2,
                          fontSize: "0.8rem", // Reduced font size
                          lineHeight: 1.5,
                        }}
                      >
                        {goal.Description}
                      </Typography>
                    </Box>
                    <Box>
                      <Tooltip title="Edit Goal">
                        <IconButton
                          size="small"
                          sx={{
                            p: 0.5,
                            mr: 0.5,
                            color: "primary.main",
                            "&:hover": {
                              bgcolor: (theme) =>
                                alpha(theme.palette.primary.main, 0.08),
                            },
                          }}
                        >
                          <EditIcon sx={{ fontSize: "1rem" }} />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Delete Goal">
                        <IconButton
                          size="small"
                          sx={{
                            p: 0.5,
                            color: "error.main",
                            "&:hover": {
                              bgcolor: (theme) =>
                                alpha(theme.palette.error.main, 0.08),
                            },
                          }}
                        >
                          <DeleteIcon sx={{ fontSize: "1rem" }} />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Box>
                  <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap" }}>
                    <Chip
                      icon={<TimelineIcon sx={{ fontSize: "0.9rem" }} />}
                      label={`${
                        goal.strategicOutcomes?.length || 0
                      } Strategic Outcomes`}
                      size="small"
                      color="primary"
                      variant="outlined"
                      sx={{
                        height: "24px",
                        "& .MuiChip-label": {
                          px: 1,
                          fontSize: "0.75rem", // Reduced font size
                          fontWeight: 500,
                        },
                      }}
                    />
                    {goal.Code && (
                      <Chip
                        label={`Code: ${goal.Code}`}
                        size="small"
                        color="secondary"
                        variant="outlined"
                        sx={{
                          height: "24px",
                          "& .MuiChip-label": {
                            px: 1,
                            fontSize: "0.75rem", // Reduced font size
                            fontWeight: 500,
                          },
                        }}
                      />
                    )}
                  </Box>
                </CardContent>
                <CardActions sx={{ px: 2.5, pb: 2.5, pt: 0 }}>
                  <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      setSelectedItem(goal);
                      setOpenDialog(true);
                      setDialogType("goal");
                    }}
                    sx={{
                      borderRadius: 1,
                      textTransform: "none",
                      fontSize: "0.8rem", // Reduced font size
                      fontWeight: 500,
                      px: 2,
                      py: 0.75,
                    }}
                  >
                    View Details
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
        {goals.length > ITEMS_PER_PAGE && (
          <Box sx={{ mt: 4, display: "flex", justifyContent: "center" }}>
            <Pagination
              count={totalPages}
              page={page}
              onChange={handlePageChange}
              color="primary"
              size="large"
              sx={{
                "& .MuiPaginationItem-root": {
                  borderRadius: 1,
                },
              }}
            />
          </Box>
        )}
      </>
    );
  };

  const renderStrategicOutcomes = () => {
    const allSOs = goals.flatMap((goal) =>
      goal.strategicOutcomes.map((so) => ({
        ...so,
        goal: goal, // Preserve the parent goal
      }))
    );
    const paginatedSOs = getPaginatedData(allSOs);
    return (
      <>
        <Grid container spacing={3}>
          {paginatedSOs.map((so) => (
            <Grid item xs={12} md={6} lg={4} key={so.ID}>
              <Card sx={{ height: "100%" }}>
                <CardContent>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    mb={2}
                  >
                    <Typography variant="subtitle1" component="div">
                      {so.Name}
                    </Typography>
                    <Box>
                      <Tooltip title="Edit SO">
                        <IconButton size="small" sx={{ mr: 1 }}>
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Delete SO">
                        <IconButton size="small">
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Box>
                  <Typography variant="body2" color="text.secondary">
                    Goal: {so.goal.Name}
                  </Typography>
                  <Box mt={2}>
                    <Chip
                      icon={<AssessmentIcon />}
                      label={`${so.intermediateOutcomes.length} Intermediate Outcomes`}
                      size="small"
                      sx={{ mr: 1 }}
                    />
                  </Box>
                </CardContent>
                <CardActions>
                  <Button size="small" color="primary">
                    View Details
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
        <Box sx={{ mt: 3, display: "flex", justifyContent: "center" }}>
          <Stack spacing={2}>
            <Pagination
              count={totalPages}
              page={page}
              onChange={handlePageChange}
              color="primary"
              size="large"
            />
          </Stack>
        </Box>
      </>
    );
  };

  const renderIntermediateOutcomes = () => {
    const allIOs = goals.flatMap((goal) =>
      goal.strategicOutcomes.flatMap((so) =>
        so.intermediateOutcomes.map((io) => ({
          ...io,
          so: so, // Preserve the parent SO
          goal: goal, // Preserve the parent goal
        }))
      )
    );
    const paginatedIOs = getPaginatedData(allIOs);
    return (
      <>
        <Grid container spacing={3}>
          {paginatedIOs.map((io) => (
            <Grid item xs={12} md={6} lg={4} key={io.ID}>
              <Card sx={{ height: "100%" }}>
                <CardContent>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    mb={2}
                  >
                    <Typography variant="subtitle1" component="div">
                      {io.Name}
                    </Typography>
                    <Box>
                      <Tooltip title="Edit IO">
                        <IconButton size="small" sx={{ mr: 1 }}>
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Delete IO">
                        <IconButton size="small">
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Box>
                  <Typography variant="body2" color="text.secondary">
                    Strategic Outcome: {io.so.Name}
                  </Typography>
                  <Box mt={2}>
                    <Chip
                      icon={<DescriptionIcon />}
                      label={`${io.outputs.length} Outputs`}
                      size="small"
                      sx={{ mr: 1 }}
                    />
                  </Box>
                </CardContent>
                <CardActions>
                  <Button size="small" color="primary">
                    View Details
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
        <Box sx={{ mt: 3, display: "flex", justifyContent: "center" }}>
          <Stack spacing={2}>
            <Pagination
              count={totalPages}
              page={page}
              onChange={handlePageChange}
              color="primary"
              size="large"
            />
          </Stack>
        </Box>
      </>
    );
  };

  const renderOutputs = () => {
    const allOutputs = goals.flatMap((goal) =>
      goal.strategicOutcomes.flatMap((so) =>
        so.intermediateOutcomes.flatMap((io) =>
          io.outputs.map((output) => ({
            ...output,
            io: io, // Preserve the parent IO
            so: so, // Preserve the parent SO
            goal: goal, // Preserve the parent goal
          }))
        )
      )
    );
    const paginatedOutputs = getPaginatedData(allOutputs);
    return (
      <>
        {/* Add Create Output Button */}
        <Box sx={{ mb: 3, display: "flex", justifyContent: "flex-end" }}>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => {
              setDialogType("output");
              setOpenDialog(true);
              setFormData({
                description: "",
                goal_id: "",
                so_id: "",
                io_id: "",
              });
            }}
            sx={{
              borderRadius: 1,
              textTransform: "none",
              fontWeight: "medium",
            }}
          >
            Create Output
          </Button>
        </Box>

        <Grid container spacing={3}>
          {paginatedOutputs.map((output) => (
            <Grid item xs={12} md={6} lg={4} key={output.ID}>
              <Card sx={{ height: "100%" }}>
                <CardContent>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    mb={2}
                  >
                    <Typography variant="subtitle1" component="div">
                      {output.Description}
                    </Typography>
                    <Box>
                      <Tooltip title="Edit Output">
                        <IconButton
                          size="small"
                          sx={{ p: 0.5, mr: 0.5 }}
                          onClick={() => {
                            setSelectedItem(output); // For outputs
                            setEditDialog(true);
                            setDialogType("output");
                            setFormData({
                              description: output.Description,
                              goal_id: output.goal?.ID,
                              so_id: output.so?.ID,
                              io_id: output.io?.ID,
                            });
                          }}
                        >
                          <EditIcon sx={{ fontSize: "1.1rem" }} />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Delete Output">
                        <IconButton
                          size="small"
                          sx={{ p: 0.5 }}
                          onClick={() => {
                            setSelectedItem(output); // For outputs
                            setDialogType("output");
                            setDeleteDialog(true);
                          }}
                        >
                          <DeleteIcon sx={{ fontSize: "1.1rem" }} />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Box>
                  <Typography variant="body2" color="text.secondary">
                    Intermediate Outcome: {output.io.Name}
                  </Typography>
                  <Box mt={2}>
                    <Chip
                      icon={<FlagIcon />}
                      label={`${output.indicators.length} Indicators`}
                      size="small"
                      sx={{ mr: 1 }}
                    />
                  </Box>
                </CardContent>
                <CardActions>
                  <Button size="small" color="primary">
                    View Details
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
        <Box sx={{ mt: 3, display: "flex", justifyContent: "center" }}>
          <Stack spacing={2}>
            <Pagination
              count={totalPages}
              page={page}
              onChange={handlePageChange}
              color="primary"
              size="large"
            />
          </Stack>
        </Box>
      </>
    );
  };

  const renderIndicators = () => {
    const filteredIndicators = getFilteredIndicators();
    const paginatedIndicators = getPaginatedData(filteredIndicators);

    return (
      <>
        {/* Add Create Indicator Button */}
        <Box
          sx={{ mb: 3, display: "flex", justifyContent: "flex-end", gap: 2 }}
        >
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => {
              setDialogType("indicator");
              setOpenDialog(true);
              setFormData({
                description: "",
                baseline: "",
                targetAnnual: "",
                targetQ1: "",
                targetQ2: "",
                targetQ3: "",
                targetQ4: "",
                output_id: "",
              });
            }}
            sx={{
              borderRadius: 1,
              textTransform: "none",
              fontWeight: "medium",
            }}
          >
            Create Indicator
          </Button>
        </Box>

        {/* Minimalistic Search Toggle */}
        <Box
          sx={{
            position: "relative",
            mb: 4,
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Collapse in={isSearchOpen} orientation="horizontal">
              <Paper
                elevation={0}
                sx={{
                  mr: 1,
                  bgcolor: "background.paper",
                  transition: "all 0.2s ease-in-out",
                  "&:hover": {
                    bgcolor: (theme) => alpha(theme.palette.primary.main, 0.04),
                  },
                }}
              >
                <TextField
                  size="small"
                  placeholder="Search indicators..."
                  value={searchQuery}
                  onChange={(e) => {
                    setSearchQuery(e.target.value);
                    setPage(1);
                  }}
                  variant="standard"
                  InputProps={{
                    disableUnderline: true,
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon
                          sx={{ color: "text.secondary", fontSize: "1.2rem" }}
                        />
                      </InputAdornment>
                    ),
                    endAdornment: searchQuery && (
                      <InputAdornment position="end">
                        <IconButton
                          size="small"
                          onClick={() => {
                            setSearchQuery("");
                            setPage(1);
                          }}
                          sx={{ p: 0.5 }}
                        >
                          <ClearIcon sx={{ fontSize: "1rem" }} />
                        </IconButton>
                      </InputAdornment>
                    ),
                    sx: {
                      px: 1,
                      py: 0.5,
                      "& input": {
                        width: "200px",
                      },
                    },
                  }}
                />
              </Paper>
            </Collapse>
            <Tooltip
              title={isSearchOpen ? "Close search" : "Search indicators"}
            >
              <IconButton
                onClick={() => setIsSearchOpen(!isSearchOpen)}
                sx={{
                  bgcolor: isSearchOpen ? "primary.main" : "transparent",
                  color: isSearchOpen ? "common.white" : "primary.main",
                  "&:hover": {
                    bgcolor: isSearchOpen
                      ? "primary.dark"
                      : (theme) => alpha(theme.palette.primary.main, 0.04),
                  },
                }}
                size="small"
              >
                <SearchIcon />
              </IconButton>
            </Tooltip>
          </Box>
          <Fade in={Boolean(searchQuery)}>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{
                position: "absolute",
                bottom: -24,
                right: 0,
              }}
            >
              {filteredIndicators.length}{" "}
              {filteredIndicators.length === 1 ? "result" : "results"} found
            </Typography>
          </Fade>
        </Box>

        {/* Indicators Grid */}
        <Grid container spacing={3}>
          {paginatedIndicators.map((indicator) => (
            <Grid item xs={12} md={6} lg={4} key={indicator.ID}>
              <Card
                sx={{
                  height: "100%",
                  borderRadius: 2,
                  transition: "transform 0.2s, box-shadow 0.2s",
                  "&:hover": {
                    transform: "translateY(-4px)",
                    boxShadow: (theme) => theme.shadows[4],
                  },
                }}
              >
                <CardContent sx={{ p: 2.5 }}>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    mb={2}
                  >
                    <Box sx={{ flex: 1, mr: 2 }}>
                      <Typography
                        variant="subtitle1"
                        component="div"
                        sx={{
                          fontWeight: 600,
                          mb: 0.75,
                          color: "text.primary",
                          fontSize: "0.8rem",
                          letterSpacing: "-0.2px",
                          lineHeight: 1.3,
                        }}
                      >
                        {indicator.Description}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          mb: 1.5,
                          color: (theme) =>
                            alpha(theme.palette.text.secondary, 0.9),
                          fontSize: "0.8rem",
                          fontWeight: 500,
                          letterSpacing: "0.1px",
                          lineHeight: 1.4,
                        }}
                      >
                        Output: {indicator.output.Description}
                      </Typography>
                    </Box>
                    <Box>
                      <Tooltip title="Edit Indicator">
                        <IconButton
                          size="small"
                          sx={{ p: 0.5, mr: 0.5 }}
                          onClick={() => {
                            setSelectedItem(indicator); // For indicators
                            setEditDialog(true);
                            setDialogType("indicator");
                            setFormData({
                              description: indicator.Description,
                              baseline: indicator.Baseline,
                              targetAnnual: indicator.Target_Annual,
                              targetQ1: indicator.Target_Q1,
                              targetQ2: indicator.Target_Q2,
                              targetQ3: indicator.Target_Q3,
                              targetQ4: indicator.Target_Q4,
                              output_id: indicator.Output_ID,
                            });
                          }}
                        >
                          <EditIcon sx={{ fontSize: "1.1rem" }} />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Delete Indicator">
                        <IconButton
                          size="small"
                          sx={{ p: 0.5 }}
                          onClick={() => {
                            setSelectedItem(indicator); // For indicators
                            setDialogType("indicator");
                            setDeleteDialog(true);
                          }}
                        >
                          <DeleteIcon sx={{ fontSize: "1.1rem" }} />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Box>
                  <Stack direction="row" spacing={1} sx={{ mb: 1.5 }}>
                    <Chip
                      label={`Target: ${indicator.Target_Annual}`}
                      size="small"
                      color="primary"
                      variant="outlined"
                      icon={<TrendingUpIcon sx={{ fontSize: "0.9rem" }} />}
                      sx={{
                        height: "24px",
                        "& .MuiChip-label": {
                          px: 1,
                          fontSize: "0.75rem",
                          fontWeight: 500,
                        },
                      }}
                    />
                    <Chip
                      label={`Baseline: ${indicator.Baseline || "Not set"}`}
                      size="small"
                      color="secondary"
                      variant="outlined"
                      icon={<TimelineIcon sx={{ fontSize: "0.9rem" }} />}
                      sx={{
                        height: "24px",
                        "& .MuiChip-label": {
                          px: 1,
                          fontSize: "0.75rem",
                          fontWeight: 500,
                        },
                      }}
                    />
                  </Stack>
                </CardContent>
                <CardActions sx={{ px: 2.5, pb: 2.5, pt: 0 }}>
                  <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    onClick={async () => {
                      setSelectedItem(indicator);
                      setOpenDialog(true);
                      setDialogType("indicator");
                      const performanceData = await fetchIndicatorPerformance(
                        indicator.ID,
                        selectedYear
                      );
                      setSelectedItem((prev) => ({
                        ...prev,
                        performance: performanceData,
                      }));
                    }}
                    sx={{
                      borderRadius: 1,
                      textTransform: "none",
                      fontSize: "0.8rem",
                      fontWeight: 500,
                      px: 2,
                      py: 0.75,
                    }}
                  >
                    View Performance
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
        <Box sx={{ mt: 4, display: "flex", justifyContent: "center" }}>
          <Pagination
            count={totalPages}
            page={page}
            onChange={handlePageChange}
            color="primary"
            size="large"
            sx={{
              "& .MuiPaginationItem-root": {
                borderRadius: 1,
              },
            }}
          />
        </Box>
      </>
    );
  };

  const fetchIndicatorPerformance = async (indicatorId, year) => {
    try {
      const response = await fetch(
        `/api/indicator-performances/indicator/${indicatorId}`
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();

      // Check if result.data exists and is an array
      if (!result.success || !result.data || !Array.isArray(result.data)) {
        console.warn("No performance data available");
        return [];
      }

      // Filter performance data for the selected year
      const yearData = result.data.filter(
        (performance) => performance.Year === year
      );

      return yearData;
    } catch (error) {
      console.error("Error fetching performance:", error);
      return [];
    }
  };

  const processPerformanceData = (indicator) => {
    if (
      !indicator ||
      !indicator.performance ||
      indicator.performance.length === 0
    ) {
      return {
        quarterlyData: [
          {
            name: "Q1",
            Target: Number(indicator?.Target_Q1) || 0,
            Performance: 0,
          },
          {
            name: "Q2",
            Target: Number(indicator?.Target_Q2) || 0,
            Performance: 0,
          },
          {
            name: "Q3",
            Target: Number(indicator?.Target_Q3) || 0,
            Performance: 0,
          },
          {
            name: "Q4",
            Target: Number(indicator?.Target_Q4) || 0,
            Performance: 0,
          },
        ],
        annualTarget: Number(indicator?.Target_Annual) || 0,
        annualPerformance: 0,
        hasData: false,
      };
    }

    const quarters = ["Q1", "Q2", "Q3", "Q4"];
    const quarterlyData = quarters.map((quarter) => {
      const performance = indicator.performance.find(
        (p) => p.Quarter === quarter
      );
      return {
        name: quarter,
        Target: Number(indicator[`Target_${quarter}`]) || 0,
        Performance: performance ? Number(performance.Performance) : 0,
      };
    });

    // Calculate annual performance total
    const annualPerformance = quarterlyData.reduce(
      (sum, q) => sum + q.Performance,
      0
    );

    return {
      quarterlyData,
      annualTarget: Number(indicator.Target_Annual) || 0,
      annualPerformance,
      hasData: true,
    };
  };

  const handlePerformanceUpdate = async (indicatorId, quarter, value, year) => {
    try {
      const response = await fetch("/api/indicator-performances", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          Indicator_ID: indicatorId,
          Quarter: quarter,
          Performance: value,
          Year: year,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(
          errorData.error || `HTTP error! status: ${response.status}`
        );
      }

      // Refresh the performance data
      const updatedPerformance = await fetchIndicatorPerformance(
        indicatorId,
        year
      );
      setSelectedItem((prev) => ({
        ...prev,
        performance: updatedPerformance,
      }));

      setSuccessMessage("Performance updated successfully");
      setPerformanceForm({
        quarter: "",
        value: "",
        year: selectedYear,
      });
    } catch (error) {
      console.error("Error updating performance:", error);
      setError(error.message || "Failed to update performance");
    }
  };

  const renderPerformanceContent = () => {
    if (!selectedItem || dialogType !== "indicator") return null;

    const performanceData = processPerformanceData(selectedItem);

    return (
      <Grid container spacing={3}>
        {/* Description and Output Section */}
        <Grid item xs={12}>
          <Paper
            sx={{
              p: 3,
              bgcolor: (theme) => alpha(theme.palette.primary.main, 0.03),
              borderRadius: 2,
              border: 1,
              borderColor: "divider",
            }}
          >
            <Typography
              variant="h6"
              color="primary"
              gutterBottom
              sx={{
                fontWeight: 600,
                fontSize: "1.25rem",
                letterSpacing: "-0.5px",
                mb: 3,
              }}
            >
              Indicator Details
            </Typography>
            <Box sx={{ mb: 3 }}>
              <Typography
                variant="subtitle2"
                sx={{
                  color: (theme) => alpha(theme.palette.text.primary, 0.7),
                  fontWeight: 600,
                  fontSize: "0.875rem",
                  letterSpacing: "0.1px",
                  mb: 1,
                }}
              >
                Description
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontSize: "1rem",
                  lineHeight: 1.6,
                  color: "text.primary",
                  fontWeight: 400,
                }}
              >
                {selectedItem.Description}
              </Typography>
            </Box>
            <Box>
              <Typography
                variant="subtitle2"
                sx={{
                  color: (theme) => alpha(theme.palette.text.primary, 0.7),
                  fontWeight: 600,
                  fontSize: "0.875rem",
                  letterSpacing: "0.1px",
                  mb: 1,
                }}
              >
                Output
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontSize: "1rem",
                  lineHeight: 1.6,
                  color: "text.primary",
                  fontWeight: 400,
                }}
              >
                {selectedItem.output.Description}
              </Typography>
            </Box>
          </Paper>
        </Grid>

        {/* Performance Section */}
        <Grid item xs={12}>
          <Paper
            sx={{
              p: 3,
              borderRadius: 2,
              border: 1,
              borderColor: "divider",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: 3,
              }}
            >
              <Typography
                variant="h6"
                color="primary"
                sx={{
                  fontWeight: 600,
                  fontSize: "1.25rem",
                  letterSpacing: "-0.5px",
                }}
              >
                Performance Summary
              </Typography>

              <FormControl sx={{ minWidth: 120 }} size="small">
                <InputLabel>Year</InputLabel>
                <Select
                  value={selectedYear}
                  onChange={async (e) => {
                    const newYear = e.target.value;
                    setSelectedYear(newYear);
                    // Fetch new performance data for the selected year
                    const performanceData = await fetchIndicatorPerformance(
                      selectedItem.ID,
                      newYear
                    );
                    setSelectedItem((prev) => ({
                      ...prev,
                      performance: performanceData,
                    }));
                  }}
                  label="Year"
                >
                  {[...Array(5)].map((_, i) => {
                    const year = new Date().getFullYear() - i;
                    return (
                      <MenuItem key={year} value={year}>
                        {year}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>

            {!performanceData.hasData && (
              <Box
                sx={{
                  textAlign: "center",
                  py: 4,
                  bgcolor: alpha(theme.palette.warning.main, 0.05),
                  borderRadius: 1,
                  border: 1,
                  borderColor: alpha(theme.palette.warning.main, 0.1),
                }}
              >
                <Typography
                  color="text.secondary"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: 1,
                    fontSize: "0.875rem",
                    fontWeight: 500,
                  }}
                >
                  <ErrorIcon color="warning" />
                  No performance data available for {selectedYear}. Use the form
                  below to add performance data.
                </Typography>
              </Box>
            )}

            {/* Chart */}
            <Box sx={{ width: "100%", height: 300, mt: 3 }}>
              <ResponsiveContainer>
                <BarChart
                  data={performanceData.quarterlyData}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <RechartsTooltip
                    contentStyle={{
                      backgroundColor: "white",
                      borderRadius: 8,
                      border: "1px solid #ccc",
                      boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                    }}
                  />
                  <Legend />
                  <Bar
                    dataKey="Target"
                    fill={alpha(theme.palette.primary.main, 0.7)}
                    name="Quarterly Target"
                    radius={[4, 4, 0, 0]}
                  />
                  <Bar
                    dataKey="Performance"
                    fill={alpha(theme.palette.success.main, 0.7)}
                    name="Actual Performance"
                    radius={[4, 4, 0, 0]}
                  />
                </BarChart>
              </ResponsiveContainer>
            </Box>

            {/* Performance Summary */}
            <Box sx={{ mt: 4 }}>
              <Typography
                variant="h6"
                color="primary"
                gutterBottom
                sx={{
                  fontWeight: 600,
                  fontSize: "1.25rem",
                  letterSpacing: "-0.5px",
                  mb: 3,
                }}
              >
                Performance Breakdown
              </Typography>
              <Grid container spacing={2}>
                {/* Annual Performance Card */}
                <Grid item xs={6} sm={2.4}>
                  <Paper
                    elevation={0}
                    sx={{
                      p: 2,
                      bgcolor: (theme) =>
                        alpha(theme.palette.primary.main, 0.08),
                      border: 1,
                      borderColor: "primary.main",
                      borderRadius: 2,
                    }}
                  >
                    <Box sx={{ textAlign: "center" }}>
                      <Typography
                        variant="subtitle2"
                        sx={{
                          color: "primary.main",
                          fontWeight: "medium",
                          mb: 1,
                        }}
                      >
                        Annual
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          color: "primary.main",
                          mb: 0.5,
                        }}
                      >
                        Target: {selectedItem.Target_Annual || "0"}
                      </Typography>
                      <Typography
                        variant="h6"
                        sx={{
                          color:
                            performanceData.annualPerformance >=
                            Number(selectedItem.Target_Annual)
                              ? "success.main"
                              : "error.main",
                          fontWeight: "medium",
                        }}
                      >
                        {performanceData.annualPerformance}
                      </Typography>
                    </Box>
                  </Paper>
                </Grid>

                {/* Quarterly Performance Cards */}
                {["Q1", "Q2", "Q3", "Q4"].map((quarter) => {
                  const performance = selectedItem.performance?.find(
                    (p) => p.Quarter === quarter
                  );
                  const target = Number(selectedItem[`Target_${quarter}`]) || 0;
                  const actual = performance
                    ? Number(performance.Performance)
                    : 0;
                  const isAchieved = actual >= target;

                  return (
                    <Grid item xs={6} sm={2.4} key={quarter}>
                      <Paper
                        elevation={0}
                        sx={{
                          p: 2,
                          bgcolor: alpha(
                            isAchieved
                              ? theme.palette.success.main
                              : theme.palette.error.main,
                            0.05
                          ),
                          border: 1,
                          borderColor: alpha(
                            isAchieved
                              ? theme.palette.success.main
                              : theme.palette.error.main,
                            0.2
                          ),
                          borderRadius: 2,
                        }}
                      >
                        <Box sx={{ textAlign: "center" }}>
                          <Typography
                            variant="subtitle2"
                            sx={{
                              color: isAchieved ? "success.main" : "error.main",
                              fontWeight: "medium",
                              mb: 1,
                            }}
                          >
                            {quarter}
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            Target: {target}
                          </Typography>
                          <Typography
                            variant="h6"
                            sx={{
                              color: isAchieved ? "success.main" : "error.main",
                              fontWeight: "medium",
                            }}
                          >
                            {actual}
                          </Typography>
                        </Box>
                      </Paper>
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
          </Paper>
        </Grid>

        {/* Update Performance Form */}
        <Grid item xs={12}>
          <Paper
            sx={{
              p: 3,
              borderRadius: 2,
              border: 1,
              borderColor: "divider",
              bgcolor: (theme) => alpha(theme.palette.background.default, 0.6),
            }}
          >
            <Typography
              variant="h6"
              color="primary"
              gutterBottom
              sx={{
                fontWeight: 600,
                fontSize: "1.25rem",
                letterSpacing: "-0.5px",
                mb: 3,
              }}
            >
              Update Performance
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth>
                  <InputLabel>Quarter</InputLabel>
                  <Select
                    value={performanceForm.quarter}
                    onChange={(e) =>
                      setPerformanceForm((prev) => ({
                        ...prev,
                        quarter: e.target.value,
                      }))
                    }
                    label="Quarter"
                  >
                    <MenuItem value="Q1">Q1 (Apr-Jun)</MenuItem>
                    <MenuItem value="Q2">Q2 (Jul-Sep)</MenuItem>
                    <MenuItem value="Q3">Q3 (Oct-Dec)</MenuItem>
                    <MenuItem value="Q4">Q4 (Jan-Mar)</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  type="number"
                  label="Value"
                  value={performanceForm.value}
                  onChange={(e) =>
                    setPerformanceForm((prev) => ({
                      ...prev,
                      value: e.target.value,
                    }))
                  }
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  type="number"
                  label="Year"
                  value={performanceForm.year}
                  onChange={(e) =>
                    setPerformanceForm((prev) => ({
                      ...prev,
                      year: e.target.value,
                    }))
                  }
                  defaultValue={selectedYear}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  onClick={() =>
                    handlePerformanceUpdate(
                      selectedItem.ID,
                      performanceForm.quarter,
                      performanceForm.value,
                      performanceForm.year
                    )
                  }
                  disabled={
                    !performanceForm.quarter ||
                    !performanceForm.value ||
                    !performanceForm.year
                  }
                  sx={{
                    borderRadius: 1,
                    textTransform: "none",
                    fontWeight: "medium",
                  }}
                >
                  Update Performance
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    );
  };

  // Calculate statistics
  const getStatistics = () => {
    const allIndicators = goals.flatMap((goal) =>
      goal.strategicOutcomes.flatMap((so) =>
        so.intermediateOutcomes.flatMap((io) =>
          io.outputs.flatMap((output) => output.indicators)
        )
      )
    );

    const totalIndicators = allIndicators.length;
    const totalGoals = goals.length;
    const totalOutputs = goals.flatMap((goal) =>
      goal.strategicOutcomes.flatMap((so) =>
        so.intermediateOutcomes.flatMap((io) => io.outputs)
      )
    ).length;
    const totalOutcomes = goals.flatMap((goal) =>
      goal.strategicOutcomes.flatMap((so) => so.intermediateOutcomes)
    ).length;

    return { totalIndicators, totalGoals, totalOutputs, totalOutcomes };
  };

  const stats = getStatistics();

  // Helper function to get endpoint based on type
  const getEndpointForType = (type) => {
    const storedUser = localStorage.getItem("user");
    let userPartner;

    if (storedUser) {
      try {
        const userData = JSON.parse(storedUser);
        userPartner = userData?.Partner || userData?.partner;
      } catch (error) {
        console.error("Error parsing user data:", error);
      }
    }

    switch (type) {
      case "goal":
        return "/api/goals";
      case "so":
        return "/api/so";
      case "io":
        return "/api/io";
      case "output":
        return "/api/outputs";
      case "indicator":
        return "/api/indicators";
      default:
        return "";
    }
  };

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return (
      <Box p={3}>
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ p: 3 }}>
      {/* Header with Statistics */}
      <Box sx={{ mb: 4 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <StatCard
              title="Total Goals"
              value={stats.totalGoals}
              icon={<FlagIcon fontSize="large" />}
              color={theme.palette.primary.main}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <StatCard
              title="Total Outcomes"
              value={stats.totalOutcomes}
              icon={<TimelineIcon fontSize="large" />}
              color={theme.palette.success.main}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <StatCard
              title="Total Outputs"
              value={stats.totalOutputs}
              icon={<AssessmentIcon fontSize="large" />}
              color={theme.palette.info.main}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <StatCard
              title="Total Indicators"
              value={stats.totalIndicators}
              icon={<TrendingUpIcon fontSize="large" />}
              color={theme.palette.warning.main}
            />
          </Grid>
        </Grid>
      </Box>

      {/* Tabs Navigation */}
      <Paper
        elevation={0}
        sx={{
          mb: 4,
          borderRadius: 2,
          overflow: "hidden",
          border: 1,
          borderColor: "divider",
          bgcolor: (theme) => alpha(theme.palette.background.paper, 0.8),
          backdropFilter: "blur(8px)",
        }}
      >
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
          sx={{
            minHeight: 64,
            "& .MuiTabs-flexContainer": {
              px: 2,
              py: 1,
            },
            "& .MuiTab-root": {
              minWidth: 160,
              height: 48,
              borderRadius: 1,
              textTransform: "none",
              fontWeight: 500,
              fontSize: "0.875rem",
              color: "text.secondary",
              transition: "all 0.2s ease-in-out",
              "&:hover": {
                color: "primary.main",
                bgcolor: (theme) => alpha(theme.palette.primary.main, 0.04),
              },
              "&.Mui-selected": {
                color: "primary.main",
                fontWeight: 600,
                bgcolor: (theme) => alpha(theme.palette.primary.main, 0.08),
                "& .MuiSvgIcon-root": {
                  color: "primary.main",
                },
              },
              "& .MuiSvgIcon-root": {
                fontSize: "1.25rem",
                mr: 1,
                transition: "color 0.2s ease-in-out",
              },
            },
            "& .MuiTabs-indicator": {
              display: "none",
            },
          }}
        >
          <Tab
            label="Goals"
            icon={<FlagIcon />}
            iconPosition="start"
            sx={{
              "&.Mui-selected": {
                boxShadow: (theme) =>
                  `0 2px 4px ${alpha(theme.palette.primary.main, 0.1)}`,
              },
            }}
          />
          <Tab
            label="Strategic Outcomes"
            icon={<TimelineIcon />}
            iconPosition="start"
            sx={{
              "&.Mui-selected": {
                boxShadow: (theme) =>
                  `0 2px 4px ${alpha(theme.palette.primary.main, 0.1)}`,
              },
            }}
          />
          <Tab
            label="Intermediate Outcomes"
            icon={<AssessmentIcon />}
            iconPosition="start"
            sx={{
              "&.Mui-selected": {
                boxShadow: (theme) =>
                  `0 2px 4px ${alpha(theme.palette.primary.main, 0.1)}`,
              },
            }}
          />
          <Tab
            label="Outputs"
            icon={<DescriptionIcon />}
            iconPosition="start"
            sx={{
              "&.Mui-selected": {
                boxShadow: (theme) =>
                  `0 2px 4px ${alpha(theme.palette.primary.main, 0.1)}`,
              },
            }}
          />
          <Tab
            label="Indicators"
            icon={<TrendingUpIcon />}
            iconPosition="start"
            sx={{
              "&.Mui-selected": {
                boxShadow: (theme) =>
                  `0 2px 4px ${alpha(theme.palette.primary.main, 0.1)}`,
              },
            }}
          />
        </Tabs>
      </Paper>

      {/* Main Content */}
      <Box>
        {loading ? (
          <Loading />
        ) : (
          <>
            {selectedTab === 0 && renderGoals()}
            {selectedTab === 1 && renderStrategicOutcomes()}
            {selectedTab === 2 && renderIntermediateOutcomes()}
            {selectedTab === 3 && renderOutputs()}
            {selectedTab === 4 && renderIndicators()}
          </>
        )}
      </Box>

      {/* Create/Edit Dialog */}
      <Dialog
        open={openDialog || editDialog}
        onClose={() => {
          setOpenDialog(false);
          setEditDialog(false);
          setSelectedItem(null);
          setFormData({
            code: "",
            name: "",
            description: "",
            baseline: "",
            targetAnnual: "",
            targetQ1: "",
            targetQ2: "",
            targetQ3: "",
            targetQ4: "",
            goal_id: "",
            so_id: "",
            io_id: "",
            output_id: "",
          });
        }}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          {selectedItem ? "Edit" : "Create"}{" "}
          {dialogType.charAt(0).toUpperCase() + dialogType.slice(1)}
        </DialogTitle>
        <DialogContent>
          {dialogType === "indicator" && selectedItem ? (
            renderPerformanceContent()
          ) : (
            <Box
              sx={{ mt: 2, display: "flex", flexDirection: "column", gap: 2 }}
            >
              {dialogType === "goal" && (
                <>
                  <TextField
                    fullWidth
                    label="Code"
                    value={formData.code}
                    onChange={(e) =>
                      setFormData({ ...formData, code: e.target.value })
                    }
                    InputProps={{
                      sx: { fontSize: "0.175rem" }, // Reduced font size
                    }}
                  />
                  <TextField
                    fullWidth
                    label="Name"
                    value={formData.name}
                    onChange={(e) =>
                      setFormData({ ...formData, name: e.target.value })
                    }
                    InputProps={{
                      sx: { fontSize: "0.175rem" }, // Reduced font size
                    }}
                  />
                </>
              )}

              {(dialogType === "so" || dialogType === "io") && (
                <TextField
                  fullWidth
                  label="Name"
                  value={formData.name}
                  onChange={(e) =>
                    setFormData({ ...formData, name: e.target.value })
                  }
                  InputProps={{
                    sx: { fontSize: "0.875rem" }, // Reduced font size
                  }}
                />
              )}

              {dialogType === "output" && (
                <>
                  <Paper
                    elevation={0}
                    sx={{
                      p: 2,
                      bgcolor: (theme) =>
                        alpha(theme.palette.primary.main, 0.03),
                      borderRadius: 2,
                      border: 1,
                      borderColor: "divider",
                      mb: 2,
                    }}
                  >
                    <Typography
                      variant="subtitle2"
                      sx={{
                        color: "primary.main",
                        fontWeight: 600,
                        mb: 2,
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                        fontSize: "0.875rem", // Reduced font size
                      }}
                    >
                      <TimelineIcon sx={{ fontSize: "1rem" }} />
                      Select Parent Intermediate Outcome
                    </Typography>

                    <FormControl fullWidth>
                      <InputLabel sx={{ fontSize: "0.875rem" }}>
                        Goal
                      </InputLabel>
                      <Select
                        value={formData.goal_id || ""}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            goal_id: e.target.value,
                            so_id: "",
                            io_id: "",
                          });
                        }}
                        label="Goal"
                        sx={{ fontSize: "0.875rem" }} // Reduced font size
                      >
                        {goals.map((goal) => (
                          <MenuItem
                            key={goal.ID}
                            value={goal.ID}
                            sx={{ fontSize: "0.875rem" }} // Reduced font size
                          >
                            {goal.Name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    {formData.goal_id && (
                      <FormControl fullWidth sx={{ mt: 2 }}>
                        <InputLabel sx={{ fontSize: "0.875rem" }}>
                          Strategic Outcome
                        </InputLabel>
                        <Select
                          value={formData.so_id || ""}
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              so_id: e.target.value,
                              io_id: "",
                            });
                          }}
                          label="Strategic Outcome"
                          sx={{ fontSize: "0.875rem" }} // Reduced font size
                        >
                          {goals
                            .find((g) => g.ID === formData.goal_id)
                            ?.strategicOutcomes.map((so) => (
                              <MenuItem
                                key={so.ID}
                                value={so.ID}
                                sx={{ fontSize: "0.875rem" }} // Reduced font size
                              >
                                {so.Name}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    )}

                    {formData.so_id && (
                      <FormControl fullWidth sx={{ mt: 2 }}>
                        <InputLabel sx={{ fontSize: "0.875rem" }}>
                          Intermediate Outcome
                        </InputLabel>
                        <Select
                          value={formData.io_id}
                          onChange={(e) =>
                            setFormData({ ...formData, io_id: e.target.value })
                          }
                          label="Intermediate Outcome"
                          sx={{ fontSize: "0.875rem" }} // Reduced font size
                        >
                          {goals
                            .find((g) => g.ID === formData.goal_id)
                            ?.strategicOutcomes.find(
                              (so) => so.ID === formData.so_id
                            )
                            ?.intermediateOutcomes.map((io) => (
                              <MenuItem
                                key={io.ID}
                                value={io.ID}
                                sx={{ fontSize: "0.875rem" }} // Reduced font size
                              >
                                {io.Name}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    )}

                    {formData.io_id && (
                      <Box
                        sx={{
                          mt: 2,
                          p: 2,
                          bgcolor: "success.light",
                          borderRadius: 1,
                          display: "flex",
                          alignItems: "center",
                          gap: 1,
                        }}
                      >
                        <CheckCircleIcon sx={{ color: "success.main" }} />
                        <Typography
                          variant="body2"
                          sx={{
                            color: "success.dark",
                            fontSize: "0.875rem", // Reduced font size
                          }}
                        >
                          Selected:{" "}
                          {
                            goals
                              .find((g) => g.ID === formData.goal_id)
                              ?.strategicOutcomes.find(
                                (so) => so.ID === formData.so_id
                              )
                              ?.intermediateOutcomes.find(
                                (io) => io.ID === formData.io_id
                              )?.Name
                          }
                        </Typography>
                      </Box>
                    )}
                  </Paper>

                  <TextField
                    fullWidth
                    label="Output Description"
                    multiline
                    rows={3}
                    value={formData.description}
                    onChange={(e) =>
                      setFormData({ ...formData, description: e.target.value })
                    }
                    placeholder="Enter a clear and concise description of the output..."
                    helperText="This description should clearly indicate what will be achieved"
                    InputProps={{
                      sx: { fontSize: "0.875rem" }, // Reduced font size
                    }}
                  />
                </>
              )}

              {dialogType === "indicator" && (
                <>
                  <FormControl fullWidth>
                    <InputLabel sx={{ fontSize: "0.875rem" }}>
                      Output
                    </InputLabel>
                    <Select
                      value={formData.output_id}
                      onChange={(e) =>
                        setFormData({ ...formData, output_id: e.target.value })
                      }
                      label="Output"
                      sx={{ fontSize: "0.875rem" }} // Reduced font size
                    >
                      {goals.flatMap((goal) =>
                        goal.strategicOutcomes.flatMap((so) =>
                          so.intermediateOutcomes.flatMap((io) =>
                            io.outputs.map((output) => (
                              <MenuItem
                                key={output.ID}
                                value={output.ID}
                                sx={{ fontSize: "0.875rem" }} // Reduced font size
                              >
                                {output.Description} ({io.Name} - {so.Name} -{" "}
                                {goal.Name})
                              </MenuItem>
                            ))
                          )
                        )
                      )}
                    </Select>
                  </FormControl>
                  <TextField
                    fullWidth
                    label="Description"
                    multiline
                    rows={3}
                    value={formData.description}
                    onChange={(e) =>
                      setFormData({ ...formData, description: e.target.value })
                    }
                    InputProps={{
                      sx: { fontSize: "0.875rem" }, // Reduced font size
                    }}
                  />
                  <TextField
                    fullWidth
                    label="Baseline"
                    type="number"
                    value={formData.baseline}
                    onChange={(e) =>
                      setFormData({ ...formData, baseline: e.target.value })
                    }
                    InputProps={{
                      sx: { fontSize: "0.875rem" }, // Reduced font size
                    }}
                  />
                  <TextField
                    fullWidth
                    label="Annual Target"
                    type="number"
                    value={formData.targetAnnual}
                    onChange={(e) =>
                      setFormData({ ...formData, targetAnnual: e.target.value })
                    }
                    InputProps={{
                      sx: { fontSize: "0.875rem" }, // Reduced font size
                    }}
                  />
                  <Grid container spacing={2}>
                    {["Q1", "Q2", "Q3", "Q4"].map((quarter) => (
                      <Grid item xs={6} key={quarter}>
                        <TextField
                          fullWidth
                          label={`${quarter} Target`}
                          type="number"
                          value={formData[`target${quarter}`]}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              [`target${quarter}`]: e.target.value,
                            })
                          }
                          InputProps={{
                            sx: { fontSize: "0.875rem" }, // Reduced font size
                          }}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </>
              )}

              {error && (
                <Typography
                  color="error"
                  variant="body2"
                  sx={{ fontSize: "0.875rem" }} // Reduced font size
                >
                  {error}
                </Typography>
              )}
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenDialog(false);
              setEditDialog(false);
              setSelectedItem(null);
              setFormData({
                code: "",
                name: "",
                description: "",
                baseline: "",
                targetAnnual: "",
                targetQ1: "",
                targetQ2: "",
                targetQ3: "",
                targetQ4: "",
                goal_id: "",
                so_id: "",
                io_id: "",
                output_id: "",
              });
            }}
          >
            Close
          </Button>
          <Button
            variant="contained"
            onClick={async () => {
              setLoading(true);
              try {
                let response;
                const endpoint = getEndpointForType(dialogType);
                const method = selectedItem ? "PUT" : "POST";
                const url = selectedItem
                  ? `${endpoint}/${selectedItem.ID}`
                  : `${endpoint}/create`;

                // Get partner name from localStorage
                const storedUser = localStorage.getItem("user");
                let userPartner;
                if (storedUser) {
                  try {
                    const userData = JSON.parse(storedUser);
                    userPartner = userData?.Partner || userData?.partner;
                  } catch (error) {
                    console.error("Error parsing user data:", error);
                  }
                }

                // Prepare request body
                const requestBody =
                  dialogType === "output" && !selectedItem
                    ? {
                        ...formData,
                        Partner: userPartner,
                        Intermediate_ID: formData.io_id,
                        Description: formData.description,
                      }
                    : dialogType === "indicator" && !selectedItem
                    ? {
                        Output_ID: formData.output_id,
                        Description: formData.description,
                        Baseline: formData.baseline || null,
                        Target_Annual: formData.targetAnnual,
                        Target_Q1: formData.targetQ1,
                        Target_Q2: formData.targetQ2,
                        Target_Q3: formData.targetQ3,
                        Target_Q4: formData.targetQ4,
                      }
                    : formData;

                response = await fetch(url, {
                  method,
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify(requestBody),
                });

                if (!response.ok) {
                  throw new Error(`HTTP error! status: ${response.status}`);
                }

                const data = await response.json();
                if (data.success) {
                  setSuccessMessage(
                    `${dialogType} ${
                      selectedItem ? "updated" : "created"
                    } successfully`
                  );
                  setOpenDialog(false);
                  setEditDialog(false);
                  setSelectedItem(null);
                  fetchHierarchyData();
                } else {
                  setError(
                    data.error ||
                      `Failed to ${
                        selectedItem ? "update" : "create"
                      } ${dialogType}`
                  );
                }
              } catch (error) {
                console.error(
                  `Error ${
                    selectedItem ? "updating" : "creating"
                  } ${dialogType}:`,
                  error
                );
                setError(
                  `Failed to ${
                    selectedItem ? "update" : "create"
                  } ${dialogType}`
                );
              } finally {
                setLoading(false);
              }
            }}
            disabled={loading}
          >
            {selectedItem ? "Update" : "Create"}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={deleteDialog}
        onClose={() => setDeleteDialog(false)}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete this {dialogType}? This action
            cannot be undone.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialog(false)}>Cancel</Button>
          <Button
            onClick={async () => {
              setLoading(true);
              try {
                const endpoint = getEndpointForType(dialogType);
                const response = await fetch(`${endpoint}/${selectedItem.ID}`, {
                  method: "DELETE",
                });

                if (!response.ok) {
                  throw new Error(`HTTP error! status: ${response.status}`);
                }

                const data = await response.json();
                if (data.success) {
                  setSuccessMessage(`${dialogType} deleted successfully`);
                  setDeleteDialog(false);
                  fetchHierarchyData();
                } else {
                  setError(data.error || `Failed to delete ${dialogType}`);
                }
              } catch (error) {
                console.error(`Error deleting ${dialogType}:`, error);
                setError(`Failed to delete ${dialogType}`);
              } finally {
                setLoading(false);
              }
            }}
            color="error"
            variant="contained"
            disabled={loading}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={Boolean(successMessage)}
        autoHideDuration={6000}
        onClose={() => setSuccessMessage("")}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={() => setSuccessMessage("")} severity="success">
          {successMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default MELHierarchy;
