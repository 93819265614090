import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  Box,
  CircularProgress,
  Typography,
  IconButton,
  Tooltip,
  TextField,
  InputAdornment,
  Button,
} from "@mui/material";
import { TrendingUp, Search, Download } from "@mui/icons-material";
import AgrovetTrendDialog from "./TrendDialogs/AgrovetTrendDialog";
import PractitionerTrendDialog from "./TrendDialogs/PractitionerTrendDialog";
import FarrierTrendDialog from "./TrendDialogs/FarrierTrendDialog";
import XLSX from "xlsx-js-style";

const CompetenciesAll = ({
  title,
  partner,
  start,
  end,
  hideSearch = false,
  onDataLoad,
  framework,
}) => {
  const [filteredData, setFilteredData] = useState([]);
  const [error, setError] = useState(null);
  const [trendDialogOpen, setTrendDialogOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isExporting, setIsExporting] = useState(false);

  // Helper function to get field names based on framework type
  const getFieldNames = () => {
    switch (title) {
      case "AMF":
        return {
          name: "Agrovet_Name",
          quarter: "Reporting_Quarter",
          date: "Assessment_Date",
        };
      case "AHMF":
        return {
          name: "AHP_Name",
          quarter: "Quarter",
          date: "Date",
        };
      case "FMF":
        return {
          name: "Name",
          quarter: "Quarter",
          date: "Date",
        };
      default:
        return {
          name: "Agrovet_Name",
          quarter: "Reporting_Quarter",
          date: "Assessment_Date",
        };
    }
  };

  // Fetch data from API
  useEffect(() => {
    const fetchCompetencyData = async () => {
      if (!partner || !start || !end) {
        return;
      }

      // Prevent duplicate fetches
      if (isLoading) {
        return;
      }

      setIsLoading(true);
      try {
        const controller = new AbortController();
        const timeoutId = setTimeout(() => controller.abort(), 30000);

        const endpoint =
          title === "AHMF"
            ? `/api/practitioners-monitoring/competency/data/${partner}/${start}/${end}`
            : title === "FMF"
            ? `/api/farriers-monitoring/competency/data/${partner}/${start}/${end}`
            : `/api/agrovets-monitoring/competency/data/${partner}/${start}/${end}`;

        const response = await fetch(endpoint, {
          signal: controller.signal,
        });

        clearTimeout(timeoutId);

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const result = await response.json();

        if (result && result.data) {
          console.log("Raw API Response:", result.data[0]); // Log first item of raw data
          const fieldNames = getFieldNames();
          const transformedData = result.data.map((item) => {
            if (title === "AMF") {
              return {
                id: item.ID,
                ID: item.ID,
                Agrovet_Name: item[fieldNames.name],
                Reporting_Quarter: item.Reporting_Quarter,
                Assessment_Date: item.Assessment_Date,
                "Legal Compliance": item["Legal Compliance"] || "0%",
                Communicator: item.Communicator || "0%",
                "Pharmacological Expert":
                  item["Pharmacological Expert"] || "0%",
                "Content of Agrovet": item["Content of Agrovet"] || "0%",
                "Agrovet Governance": item["Agrovet Governance"] || "0%",
              };
            }

            if (title === "FMF") {
              return {
                id: item.ID,
                ID: item.ID,
                Name: item[fieldNames.name],
                Quarter: item.Quarter,
                ALC_Site: item.ALC_Site,
                Date: item.Date,
                "Legal Compliance": item["Legal Compliance"] || "0%",
                Communicator: item.Communicator || "0%",
                "Farriery Expert": item["Farriery Expert"] || "0%",
                "Farrier Kit": item["Farrier Kit"] || "0%",
                Governance: item.Governance || "0%",
              };
            }

            // AHMF transformation
            return {
              id: item.ID,
              ID: item.ID,
              AHP_Name: item[fieldNames.name],
              Quarter: item.Quarter,
              Date: item.Date,
              "Legal Compliance": item["Legal Compliance"] || "0%",
              "Clinical Expert": item["Clinical Expert"] || "0%",
              "Kit Content": item["Kit Content"] || "0%",
              "Clinical Governance": item["Clinical Governance"] || "0%",
            };
          });

          console.log("Final Transformed Data:", transformedData); // Log final transformed data
          setFilteredData(transformedData);
          if (onDataLoad) {
            onDataLoad(transformedData);
          }
        }
      } catch (error) {
        console.error("Error in fetchCompetencyData:", error);
        if (error.name === "AbortError") {
          setError("Request timed out. Please try again.");
        } else {
          setError(error.message);
        }
        setFilteredData([]);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCompetencyData();
  }, [partner, start, end, title]);

  // Update filtered data when search term changes
  useEffect(() => {
    if (!filteredData) return;

    const filtered = filteredData.filter((item) => {
      const searchLower = searchTerm.toLowerCase();
      const searchableFields = Object.values(item).filter(
        (value) => typeof value === "string"
      );
      return searchableFields.some((field) =>
        field.toLowerCase().includes(searchLower)
      );
    });

    setFilteredData(filtered);
  }, [searchTerm]);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  // Framework-specific columns
  const getColumns = () => {
    const fieldNames = getFieldNames();

    // Common base columns without ALC_Site and Name
    const commonColumns = [
      {
        field:
          title === "AMF"
            ? "Agrovet_Name"
            : title === "AHMF"
            ? "AHP_Name"
            : "Name",
        headerName: "Name",
        width: 200,
        renderCell: (params) => (
          <Typography variant="body2" sx={{ fontWeight: 500 }}>
            {params.value}
          </Typography>
        ),
      },
      {
        field: title === "AMF" ? "Reporting_Quarter" : "Quarter",
        headerName: "Quarter",
        width: 100,
        renderCell: (params) => (
          <Typography variant="body2" sx={{ fontWeight: 500 }}>
            {params.value}
          </Typography>
        ),
      },
      {
        field: title === "AMF" ? "Assessment_Date" : "Date",
        headerName: "Date",
        width: 100,
        renderCell: (params) => (
          <Typography variant="body2">
            {new Date(params.value).toLocaleDateString()}
          </Typography>
        ),
      },
      {
        field: "Legal Compliance",
        headerName: "Legal Compliance",
        width: 120,
      },
    ];

    // Add ALC_Site for FMF only
    const baseColumns =
      title === "FMF"
        ? [
            ...commonColumns.slice(0, 1), // Insert ALC_Site after Quarter
            { field: "ALC_Site", headerName: "ALC Site", width: 120 },
            ...commonColumns.slice(1), // Rest of the columns
          ]
        : commonColumns;

    if (title === "AMF") {
      return [
        ...baseColumns,
        {
          field: "Communicator",
          headerName: "Communicator",
          width: 120,
        },
        {
          field: "Pharmacological Expert",
          headerName: "Pharmacological Expert",
          width: 120,
        },
        {
          field: "Content of Agrovet",
          headerName: "Content of Agrovet",
          width: 120,
        },
        {
          field: "Agrovet Governance",
          headerName: "Agrovet Governance",
          width: 120,
        },
        getTrendColumn(),
      ];
    } else if (title === "FMF") {
      return [
        ...baseColumns,
        {
          field: "Communicator",
          headerName: "Communicator",
          width: 120,
        },
        {
          field: "Farriery Expert",
          headerName: "Farriery Expert",
          width: 120,
        },
        {
          field: "Farrier Kit",
          headerName: "Farrier Kit",
          width: 120,
        },
        {
          field: "Governance",
          headerName: "Governance",
          width: 120,
        },
        getTrendColumn(),
      ];
    } else {
      // AHMF columns
      return [
        ...baseColumns,
        {
          field: "Clinical Expert",
          headerName: "Clinical Expert",
          width: 120,
        },
        {
          field: "Kit Content",
          headerName: "Kit Content",
          width: 120,
        },
        {
          field: "Clinical Governance",
          headerName: "Clinical Governance",
          width: 120,
        },
        getTrendColumn(),
      ];
    }
  };

  const getTrendColumn = () => ({
    field: "actions",
    headerName: "Trend",
    width: 100,
    sortable: false,
    renderCell: (params) => {
      return (
        <Tooltip title="View Trend">
          <IconButton
            onClick={() => {
              console.log("Opening trend dialog for:", params.row);
              setSelectedRecord(params.row);
              setTrendDialogOpen(true);
            }}
            size="small"
          >
            <TrendingUp />
          </IconButton>
        </Tooltip>
      );
    },
  });

  const getTrendDialog = () => {
    switch (title) {
      case "AMF":
        return (
          <AgrovetTrendDialog
            open={trendDialogOpen}
            onClose={() => setTrendDialogOpen(false)}
            record={selectedRecord}
            framework={title}
            year={new Date(start).getFullYear()}
          />
        );
      case "AHMF":
        return (
          <PractitionerTrendDialog
            open={trendDialogOpen}
            onClose={() => setTrendDialogOpen(false)}
            record={selectedRecord}
            framework={title}
            year={new Date(start).getFullYear()}
          />
        );
      case "FMF":
        return (
          <FarrierTrendDialog
            open={trendDialogOpen}
            onClose={() => setTrendDialogOpen(false)}
            record={selectedRecord}
            framework={title}
            year={new Date(start).getFullYear()}
          />
        );
      default:
        return null;
    }
  };

  const exportToExcel = async () => {
    try {
      setIsExporting(true);
      let exportData;

      if (title === "AMF") {
        exportData = filteredData.map((item) => ({
          Name: item.Agrovet_Name,
          Quarter: item.Reporting_Quarter,
          Date: new Date(item.Assessment_Date).toLocaleDateString(),
          "Legal Compliance": item["Legal Compliance"],
          Communicator: item.Communicator,
          "Pharmacological Expert": item["Pharmacological Expert"],
          "Content of Agrovet": item["Content of Agrovet"],
          "Agrovet Governance": item["Agrovet Governance"],
        }));
      } else if (title === "FMF") {
        exportData = filteredData.map((item) => ({
          Name: item.Name,
          Quarter: item.Quarter,
          "ALC Site": item.ALC_Site,
          Date: new Date(item.Date).toLocaleDateString(),
          "Legal Compliance": item["Legal Compliance"],
          Communicator: item.Communicator,
          "Farriery Expert": item["Farriery Expert"],
          "Farrier Kit": item["Farrier Kit"],
          Governance: item.Governance,
        }));
      } else {
        // AHMF
        exportData = filteredData.map((item) => ({
          Name: item.AHP_Name,
          Quarter: item.Quarter,
          Date: new Date(item.Date).toLocaleDateString(),
          "Legal Compliance": item["LegalCompliance"],
          "Clinical Expert": item["ClinicalExpert"],
          "Kit Content": item["KitContent"],
          "Clinical Governance": item["ClinicalGovernance"],
        }));
      }

      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet(exportData);
      XLSX.utils.book_append_sheet(wb, ws, "Competencies");

      const date = new Date().toISOString().split("T")[0];
      const filename = `${title}_Competencies_${date}.xlsx`;
      XLSX.writeFile(wb, filename);
    } catch (error) {
      console.error("Export failed:", error);
      alert("Failed to export data. Please try again.");
    } finally {
      setIsExporting(false);
    }
  };

  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: 400,
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          p: 3,
          color: "error.main",
        }}
      >
        <Typography>Error loading data: {error}</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ height: 600, width: "100%" }}>
      {/* Name Display */}
      {filteredData.length > 0 && (
        <Box sx={{ mb: 2, p: 1, bgcolor: "grey.50", borderRadius: 1 }}>
          <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
            {filteredData[0][getFieldNames().name]}
          </Typography>
        </Box>
      )}

      {/* Search Box and Export Button */}
      {!hideSearch && (
        <Box sx={{ mb: 2, display: "flex", gap: 2, alignItems: "center" }}>
          <TextField
            fullWidth
            size="small"
            placeholder={`Search ${
              title === "AMF" ? "agrovet" : title === "AHMF" ? "AHP" : "farrier"
            } name...`}
            value={searchTerm}
            onChange={handleSearch}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
          <Button
            variant="contained"
            color="primary"
            startIcon={
              isExporting ? (
                <CircularProgress size={16} color="inherit" />
              ) : (
                <Download sx={{ fontSize: "1.1rem" }} />
              )
            }
            onClick={exportToExcel}
            disabled={isExporting || !filteredData.length}
            sx={{
              height: 32,
              px: 2,
              fontSize: "0.825rem",
              fontWeight: 500,
              boxShadow: "none",
              minWidth: 120,
              "&:hover": {
                boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
                transform: "translateY(-1px)",
              },
              transition: "all 0.2s ease",
            }}
          >
            {isExporting ? "Exporting..." : "Export Report"}
          </Button>
        </Box>
      )}

      <DataGrid
        rows={filteredData}
        columns={getColumns()}
        pageSize={10}
        rowsPerPageOptions={[10]}
        disableSelectionOnClick
        density="compact"
        loading={isLoading}
        getRowId={(row) => row.id || row.ID || Math.random().toString()}
        sx={{
          "& .MuiDataGrid-cell": {
            fontSize: "0.875rem",
            py: 0.5,
          },
          "& .MuiDataGrid-columnHeader": {
            backgroundColor: "rgba(0, 0, 0, 0.02)",
            fontWeight: 600,
            py: 0.5,
          },
          border: 1,
          borderColor: "divider",
          "& .MuiDataGrid-cell:focus": {
            outline: "none",
          },
        }}
      />
      {getTrendDialog()}
    </Box>
  );
};

export default CompetenciesAll;
