import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Paper,
  Grid,
  Card,
  CardContent,
  CardActions,
  Button,
  IconButton,
  Tooltip,
  Chip,
  CircularProgress,
  Stack,
  useTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tabs,
  Tab,
  Divider,
  LinearProgress,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  AppBar,
  Toolbar,
} from "@mui/material";
import { alpha } from "@mui/system";
import TimelineIcon from "@mui/icons-material/Timeline";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import AssessmentIcon from "@mui/icons-material/Assessment";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import WarningIcon from "@mui/icons-material/Warning";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as RechartsTooltip,
  Legend,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
} from "recharts";

const PartnerCard = ({ partner, onViewDetails, quarter, year }) => {
  const theme = useTheme();
  const [stats, setStats] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchPartnerStats();
  }, [quarter, year]);

  const fetchPartnerStats = async () => {
    try {
      // Fetch partner statistics with quarter and year
      const response = await fetch(
        `/api/partners/${encodeURIComponent(
          partner?.Name
        )}/stats?quarter=${quarter}&year=${year}`
      );
      const result = await response.json();
      if (result.success) {
        setStats(result.data);
      } else {
        console.error("Error fetching partner stats:", result.error);
      }
    } catch (error) {
      console.error("Error fetching partner stats:", error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <Card sx={{ height: "100%" }}>
        <CardContent
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: 200,
          }}
        >
          <CircularProgress />
        </CardContent>
      </Card>
    );
  }

  return (
    <Card
      sx={{
        height: "100%",
        transition: "transform 0.2s, box-shadow 0.2s",
        "&:hover": {
          transform: "translateY(-4px)",
          boxShadow: (theme) => theme.shadows[4],
        },
      }}
    >
      <CardContent>
        <Typography
          variant="h6"
          sx={{
            mb: 2,
            fontWeight: 600,
            fontSize: "1.1rem",
            color: "primary.main",
          }}
        >
          {partner.Name}
        </Typography>

        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid item xs={6}>
            <Paper
              sx={{
                p: 1.5,
                bgcolor: alpha(theme.palette.primary.light, 0.2), // Softer tone
                borderRadius: 1,
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  color: "text.secondary",
                  fontSize: "0.75rem",
                  fontWeight: 600,
                  textTransform: "uppercase",
                  mb: 0.5,
                }}
              >
                Outputs
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  color: "primary.dark", // Softer text color
                  fontWeight: 600,
                  fontSize: "1.25rem",
                }}
              >
                {stats?.totalOutputs || 0}
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper
              sx={{
                p: 1.5,
                bgcolor: alpha(theme.palette.success.light, 0.2), // Softer tone
                borderRadius: 1,
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  color: "text.secondary",
                  fontSize: "0.75rem",
                  fontWeight: 600,
                  textTransform: "uppercase",
                  mb: 0.5,
                }}
              >
                Indicators
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  color: "success.dark", // Softer text color
                  fontWeight: 600,
                  fontSize: "1.25rem",
                }}
              >
                {stats?.totalIndicators || 0}
              </Typography>
            </Paper>
          </Grid>
        </Grid>

        <Stack direction="row" spacing={1} sx={{ mb: 2 }}>
          <Chip
            size="small"
            icon={<CheckCircleIcon sx={{ fontSize: "1rem" }} />}
            label={`${stats?.achievedTargets || 0} Achieved`}
            sx={{
              bgcolor: alpha(theme.palette.success.light, 0.2), // Softer tone
              color: "success.dark",
              "& .MuiChip-icon": { color: "success.dark" },
            }}
          />
          <Chip
            size="small"
            icon={<ErrorIcon sx={{ fontSize: "1rem" }} />}
            label={`${stats?.missedTargets || 0} Below Target`}
            sx={{
              bgcolor: alpha(theme.palette.error.light, 0.2), // Softer tone
              color: "error.dark",
              "& .MuiChip-icon": { color: "error.dark" },
            }}
          />
        </Stack>

        <Box sx={{ mt: 2 }}>
          <Typography
            variant="body2"
            sx={{
              color: "text.secondary",
              fontSize: "0.75rem",
              fontWeight: 600,
              mb: 1,
            }}
          >
            Overall Performance
          </Typography>
          <LinearProgress
            variant="determinate"
            value={stats?.performancePercentage || 0}
            sx={{
              height: 6,
              borderRadius: 1,
              bgcolor: alpha(theme.palette.primary.light, 0.2), // Softer tone
              "& .MuiLinearProgress-bar": {
                borderRadius: 1,
                bgcolor: (theme) =>
                  stats?.performancePercentage >= 75
                    ? theme.palette.success.dark
                    : stats?.performancePercentage >= 50
                    ? theme.palette.warning.dark
                    : theme.palette.error.dark,
              },
            }}
          />
          <Typography
            variant="body2"
            sx={{
              color: "text.secondary",
              fontSize: "0.75rem",
              mt: 0.5,
              textAlign: "right",
            }}
          >
            {stats?.performancePercentage || 0}%
          </Typography>
        </Box>
      </CardContent>
      <CardActions sx={{ p: 2, pt: 0 }}>
        <Button
          startIcon={<VisibilityIcon />}
          onClick={() => onViewDetails(partner)}
          sx={{
            textTransform: "none",
            fontWeight: 500,
            fontSize: "0.875rem",
          }}
        >
          View Details
        </Button>
      </CardActions>
    </Card>
  );
};

const PartnerDetails = ({ partner, onClose }) => {
  const theme = useTheme();
  const [selectedTab, setSelectedTab] = useState(0);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchPartnerDetails();
  }, [partner]);

  const fetchPartnerDetails = async () => {
    try {
      // Fetch detailed partner data including outputs and indicators
      const response = await fetch(`/api/partners/details/${partner.ID}`);
      const result = await response.json();
      if (result.success) {
        setData(result.data);
      } else {
        console.error("Error fetching partner details:", result.error);
      }
    } catch (error) {
      console.error("Error fetching partner details:", error);
    } finally {
      setLoading(false);
    }
  };

  const renderPerformanceChart = () => {
    const chartData = [
      { name: "Achieved", value: data?.achievedTargets || 0 },
      { name: "Below Target", value: data?.missedTargets || 0 },
    ];

    const COLORS = [theme.palette.success.main, theme.palette.error.main];

    return (
      <Box sx={{ height: 300 }}>
        <ResponsiveContainer>
          <PieChart>
            <Pie
              data={chartData}
              cx="50%"
              cy="50%"
              innerRadius={60}
              outerRadius={80}
              fill="#8884d8"
              paddingAngle={5}
              dataKey="value"
            >
              {chartData.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
            <RechartsTooltip />
            <Legend />
          </PieChart>
        </ResponsiveContainer>
      </Box>
    );
  };

  const renderOutputsList = () => {
    if (!data?.outputs) return null;

    return (
      <Grid container spacing={2}>
        {data.outputs.map((output) => (
          <Grid item xs={12} key={output.ID}>
            <Paper
              sx={{
                p: 2,
                bgcolor: "background.paper",
                borderRadius: 1,
                border: 1,
                borderColor: "divider",
              }}
            >
              <Typography
                variant="subtitle1"
                sx={{
                  fontWeight: 600,
                  mb: 1,
                  fontSize: "0.9rem",
                }}
              >
                {output.Description}
              </Typography>
              <Stack direction="row" spacing={1}>
                <Chip
                  size="small"
                  label={`${output.indicators.length} Indicators`}
                  icon={<AssessmentIcon sx={{ fontSize: "0.9rem" }} />}
                />
                <Chip
                  size="small"
                  label={`${output.achievedTargets} Achieved`}
                  icon={<CheckCircleIcon sx={{ fontSize: "0.9rem" }} />}
                  sx={{
                    bgcolor: alpha(theme.palette.success.main, 0.1),
                    color: "success.main",
                  }}
                />
              </Stack>
            </Paper>
          </Grid>
        ))}
      </Grid>
    );
  };

  if (loading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", p: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <DialogTitle>
        <Typography variant="h6" sx={{ fontWeight: 600 }}>
          {partner.Name} - Performance Overview
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Tabs
          value={selectedTab}
          onChange={(e, newValue) => setSelectedTab(newValue)}
          sx={{ mb: 3 }}
        >
          <Tab label="Summary" />
          <Tab label="Outputs" />
          <Tab label="Indicators" />
        </Tabs>

        {selectedTab === 0 && (
          <Box>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <Paper
                  sx={{
                    p: 2,
                    bgcolor: alpha(theme.palette.primary.main, 0.05),
                    borderRadius: 1,
                  }}
                >
                  <Typography variant="h6" sx={{ mb: 2, fontWeight: 600 }}>
                    Performance Statistics
                  </Typography>
                  {renderPerformanceChart()}
                </Paper>
              </Grid>
              <Grid item xs={12} md={8}>
                <Paper
                  sx={{
                    p: 2,
                    bgcolor: alpha(theme.palette.primary.main, 0.05),
                    borderRadius: 1,
                  }}
                >
                  <Typography variant="h6" sx={{ mb: 2, fontWeight: 600 }}>
                    Quarterly Performance
                  </Typography>
                  <Box sx={{ height: 300 }}>
                    <ResponsiveContainer>
                      <BarChart data={data?.quarterlyPerformance || []}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="quarter" />
                        <YAxis />
                        <RechartsTooltip />
                        <Legend />
                        <Bar
                          dataKey="achieved"
                          name="Achieved"
                          fill={theme.palette.success.main}
                        />
                        <Bar
                          dataKey="missed"
                          name="Below Target"
                          fill={theme.palette.error.main}
                        />
                      </BarChart>
                    </ResponsiveContainer>
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          </Box>
        )}

        {selectedTab === 1 && renderOutputsList()}

        {selectedTab === 2 && (
          <Box>{/* Indicators list with performance details */}</Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </>
  );
};

const PartnerOverview = () => {
  const theme = useTheme();
  const [partners, setPartners] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedPartner, setSelectedPartner] = useState(null);
  const [detailsDialog, setDetailsDialog] = useState(false);
  const [quarter, setQuarter] = useState("Q1");
  const [year, setYear] = useState(new Date().getFullYear());

  const handleQuarterChange = (event) => setQuarter(event.target.value);
  const handleYearChange = (event) => setYear(event.target.value);

  useEffect(() => {
    // Check if user is Brooke East Africa
    const user = JSON.parse(localStorage.getItem("user"));
    if (user?.Partner !== "Brooke East Africa") {
      // Redirect to home or show error
      window.location.href = "/";
      return;
    }

    fetchPartners();
  }, []);

  const fetchPartners = async () => {
    try {
      const response = await fetch("/api/partners");
      const result = await response.json();
      if (result.success) {
        setPartners(
          result.data.filter((partner) => partner.Name !== "Brooke East Africa")
        );
      } else {
        console.error("Error fetching partners:", result.error);
      }
    } catch (error) {
      console.error("Error fetching partners:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleViewDetails = (partner) => {
    setSelectedPartner(partner);
    setDetailsDialog(true);
  };

  if (loading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", p: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <AppBar position="static" sx={{ mb: 3 }}>
        <Toolbar>
          <Typography variant="h6" sx={{ flexGrow: 1, fontWeight: 600 }}>
            Partner Performance Overview
          </Typography>
        </Toolbar>
      </AppBar>

      <Box sx={{ p: 3 }}>
        <Stack direction="row" spacing={2} sx={{ mb: 3, alignItems: "center" }}>
          <FormControl size="small" sx={{ minWidth: 120 }}>
            <InputLabel>Quarter</InputLabel>
            <Select
              value={quarter}
              onChange={handleQuarterChange}
              label="Quarter"
            >
              <MenuItem value="Q1">Q1</MenuItem>
              <MenuItem value="Q2">Q2</MenuItem>
              <MenuItem value="Q3">Q3</MenuItem>
              <MenuItem value="Q4">Q4</MenuItem>
            </Select>
          </FormControl>
          <FormControl size="small" sx={{ minWidth: 120 }}>
            <InputLabel>Year</InputLabel>
            <Select value={year} onChange={handleYearChange} label="Year">
              {Array.from({ length: 5 }, (_, i) => (
                <MenuItem key={i} value={new Date().getFullYear() - i}>
                  {new Date().getFullYear() - i}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>

        <Grid container spacing={3}>
          {partners.map((partner) => (
            <Grid item xs={12} sm={6} md={4} key={partner.ID}>
              <PartnerCard
                partner={partner}
                onViewDetails={handleViewDetails}
                quarter={quarter}
                year={year}
              />
            </Grid>
          ))}
        </Grid>

        <Dialog
          open={detailsDialog}
          onClose={() => setDetailsDialog(false)}
          maxWidth="lg"
          fullWidth
        >
          <PartnerDetails
            partner={selectedPartner}
            onClose={() => setDetailsDialog(false)}
          />
        </Dialog>
      </Box>
    </>
  );
};

export default PartnerOverview;
